import React, { useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getTransactionHistory } from "services/issuanceHistory";
import { CSVLink, CSVDownload } from "react-csv";
import getMerchantHistory from "services/reports";
import { getSupermarketReport } from "services/reports";

export default function SupermarketReport() {
  const [merchantHistory, setMerchantHistory] = React.useState([{}]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  // var merchantHistory = []
  const [date, setDate] = React.useState(null);
  const handleChange = (e) => {
    e.preventDefault();
    setDate(e.target.value);
    setIsLoading(true);

    getSupermarketReport(e.target.value)
      .then((response) => {
        setMerchantHistory(response.data);
        // merchantHistory = response.data;
      })
      .catch((error) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <div>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title
                    as="h4"
                    className="text-center m-3 heading"
                  ></Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                  <Row className="align-items-center">
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Date</label>
                          <Form.Control
                            required
                            value={date}
                            placeholder="abcd"
                            type="month"
                            name="Title"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <div className="button-wrapper mt-1">
                          {isLoading ? (
                            <Spinner animation="border" role="status"></Spinner>
                          ) : (
                            date &&
                            !isLoading && (
                              <Button
                                className="btn-fill res-size"
                                style={{
                                  backgroundColor: "#3AAB7B",
                                  border: "none",
                                }}
                                disabled={isDownloading ? true : false}
                              >
                                <CSVLink
                                  data={merchantHistory}
                                  filename={"SuperMarket_Monthly_Report.csv"}
                                  onClick={() => {
                                    setIsDownloading(true);
                                    setTimeout(
                                      () => setIsDownloading(false),
                                      1000
                                    );
                                  }}
                                >
                                  {isDownloading
                                    ? "Downloading..."
                                    : "Download"}
                                </CSVLink>
                              </Button>
                            )
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* {
                !form &&
                <div>

                </div>
            } */}
    </div>
  );
}
