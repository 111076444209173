import React, { useEffect } from "react";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import getAllTasks from "services/task";
import { addTask } from "services/task";
import { deleteTask } from "services/task";
import { updateTask } from "services/task";
export default function Task() {
  const [task, setTask] = React.useState();
  const [edit, setEdit] = React.useState();
  const [add, setAdd] = React.useState(false);
  const [newTask, setNewTask] = React.useState({
    status: 0,
    task: "",
  });
  useEffect(() => {
    getAllTasks()
      .then((res) => {
        setTask(res.data);
        setEdit([...Array(res.data.length).fill(false)]);
      })
      .catch((err) => {});
  }, []);
  const handleTaskSubmit = (editedTask) => {
    updateTask(editedTask)
      .then((res) => {})
      .catch((err) => {});
  };
  const handleTaskRemove = (removeTask) => {
    deleteTask(removeTask.id)
      .then((res) => {})
      .catch((err) => {});
  };
  const handleAddTask = (task) => {
    addTask(task)
      .then((res) => {
        getAllTasks()
          .then((res) => {
            setTask(res.data);
            setEdit([...Array(res.data.length).fill(false)]);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };
  return (
    <>
      <Card className="card-tasks">
        <Card.Header>
          <Card.Title as="h4">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-488980961">
                  {add ? "Cancel .." : "Add Task.."}
                </Tooltip>
              }
            >
              {!add ? (
                <Button
                  className="btn-simple btn-link p-1"
                  type="button"
                  variant="info"
                  onClick={() => {
                    setAdd(true);
                  }}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              ) : (
                <Button
                  className="btn-simple btn-link p-1"
                  type="button"
                  variant="info"
                  onClick={() => {
                    setAdd(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </Button>
              )}
            </OverlayTrigger>
            Tasks
          </Card.Title>
          <p className="card-category">Pending Tasks</p>
        </Card.Header>
        <Card.Body>
          <div className="table-full-width">
            <Table>
              <tbody>
                {add && (
                  <tr>
                    <td>
                      <Form.Check className="mb-1 pl-0">
                        <Form.Check.Label>
                          <Form.Check.Input
                            checked={newTask.status}
                            type="checkbox"
                            onChange={() => {
                              setNewTask({
                                ...newTask,
                                status: !newTask.status,
                              });
                            }}
                          ></Form.Check.Input>
                          <span className="form-check-sign"></span>
                        </Form.Check.Label>
                      </Form.Check>
                    </td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <Form.Group
                          className="mb-1 pl-0 "
                          style={{ width: "100%" }}
                        >
                          <Form.Control
                            required
                            placeholder="abcd"
                            value={newTask.task}
                            type="text"
                            name="Title"
                            onChange={(e) => {
                              setNewTask({ ...newTask, task: e.target.value });
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-488980961">
                              Submit Task..
                            </Tooltip>
                          }
                        >
                          <Button
                            className="btn-simple btn-link p-1"
                            type="button"
                            variant="info"
                            onClick={() => {
                              handleAddTask(newTask);
                              setAdd(false);
                            }}
                          >
                            <i className="fas fa-paper-plane"></i>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                )}
                {!add &&
                  task &&
                  task.map((itm, index) => {
                    return (
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                checked={itm.status}
                                type="checkbox"
                                onChange={() => {
                                  if (edit[index]) {
                                    let tempTasks = [...task];
                                    tempTasks[index].status =
                                      !tempTasks[index].status;
                                    setTask(tempTasks);
                                  }
                                }}
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          {edit && edit[index] ? (
                            <div style={{ display: "flex" }}>
                              <Form.Group
                                className="mb-1 pl-0 "
                                style={{ width: "100%" }}
                              >
                                <Form.Control
                                  required
                                  placeholder="abcd"
                                  value={itm.task}
                                  type="text"
                                  name="Title"
                                  onChange={(e) => {
                                    let tempTask = [...task];
                                    tempTask[index].task = e.target.value;

                                    setTask(tempTask);
                                  }}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  Please provide a value.
                                </Form.Control.Feedback>
                              </Form.Group>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-488980961">
                                    Submit Task..
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-simple btn-link p-1"
                                  type="button"
                                  variant="info"
                                  onClick={() => {
                                    let tempEdit = [...edit];
                                    tempEdit[index] = !tempEdit[index];
                                    handleTaskSubmit(itm);
                                    setEdit(tempEdit);
                                  }}
                                >
                                  <i className="fas fa-paper-plane"></i>
                                </Button>
                              </OverlayTrigger>
                            </div>
                          ) : (
                            itm.task
                          )}
                        </td>
                        {edit && !edit[index] && (
                          <td className="td-actions text-right">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-488980961">
                                  Edit Task..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="info"
                                onClick={() => {
                                  let e = [...edit];
                                  e[index] = !e[index];
                                  setEdit(e);
                                }}
                              >
                                <i className="fas fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-506045838">
                                  Remove..
                                </Tooltip>
                              }
                            >
                              <Button
                                className="btn-simple btn-link p-1"
                                type="button"
                                variant="danger"
                                onClick={() => {
                                  setTask(
                                    task.filter((item) => itm.id != item.id)
                                  );
                                  handleTaskRemove(itm);
                                }}
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Card.Body>
        <Card.Footer>
          <hr></hr>
          <div className="stats">
            <i className="now-ui-icons loader_refresh spin"></i>
            Updated 3 minutes ago
          </div>
        </Card.Footer>
      </Card>
    </>
  );
}
