import React, { useEffect } from 'react';
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";

import Multiselect from 'multiselect-react-dropdown';
import { getAllMerchants } from 'services/user';
import { getTransactionReport } from 'services/reports';
import CustomSelect from 'utils/CustomSelect';
import { components } from "react-select";
import makeAnimated from "react-select/animated";

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const animatedComponents = makeAnimated();
export default function DailyTransactionReport() {
    const [merchants, setMerchants] = React.useState([{}]);
    const [selectedMerchants, setSelectedMerchants] = React.useState([])
    const [interestOn, setInterestOn] = React.useState()

    const [report, setReport] = React.useState(null)
    const [filtered, setFiltered] = React.useState(false)
    const [dateFrom, setDateFrom] = React.useState(null)
    const [dateTo, setDateTo] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);

 

    useEffect(() => {
        getAllMerchants().then((response) => {
            const { data } = response
            const formattedData = data.map(item => {
                return {
                    value: item.id,
                    label: item.Name
                }
            })
            setMerchants(formattedData);
        }).catch((error) => {
        });
    }, []);

    useEffect(() => {
        if (!report) return;
        setFiltered(true);
    }, [report]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedMerchants.length < 1) {
            alert("List of Users is empty")
        }
        else {
            setIsLoading(true);
            const selectedMerchantsIds = selectedMerchants.map(merchant => merchant.value)
            const interestOnValue = interestOn.value
            getTransactionReport(selectedMerchantsIds, dateFrom, dateTo, interestOnValue)
                .then(res => {
                    setReport(res.data);
                })
                .catch(err => {
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }
    const handleChange = (selected) => {
        setSelectedMerchants(selected)
    }

    return (
        <div>
            <div>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <Card className="form-wrapper mt-4">
                                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                                    <Card.Title as="h4" className="text-center m-3 heading">
                                        Daily Transaction Report
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className="requiredelement">Merchants</label>
                                                    <CustomSelect
                                                        options={merchants} // Options to display in the dropdown
                                                        // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue, animatedComponents }}
                                                        onChange={handleChange}
                                                        allowSelectAll={true}
                                                        value={selectedMerchants}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className="requiredelement">Interest On</label>
                                                    <CustomSelect
                                                        options={[
                                                            { value: "Gas", label: "Client(Gas)" },
                                                            { value: "Pharmacy", label: "Client(Pharmacy)" },
                                                            { value: "Client", label: "Client" },
                                                            { value: "Merchant", label: "Merchant" },
                                                            { value: "Prepaid", label: "Client(Prepaid)" },
                                                        ]} // Options to display in the dropdown
                                                        // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        isMulti={false}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue, animatedComponents }}
                                                        onChange={(selected) => setInterestOn(selected)}
                                                        allowSelectAll={false}
                                                        value={interestOn}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className="requiredelement">Date From</label>
                                                    <Form.Control
                                                        required
                                                        placeholder="abcd"
                                                        type="date"
                                                        name="Title"
                                                        onChange={(e) => {
                                                            setDateFrom(e.target.value)
                                                        }}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label className="requiredelement">Date To</label>
                                                    <Form.Control
                                                        required
                                                        placeholder="abcd"
                                                        type="date"
                                                        name="Title"
                                                        onChange={(e) => {
                                                            setDateTo(e.target.value)
                                                        }}

                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a value.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="text-center justify-content-center mt-2">
                                        <Col md="12">
                                                <div className="button-wrapper">
                                                    <Button className="btn-fill res-size" type="submit" style={{ backgroundColor: "#3AAB7B", border: "none" }} disabled={isLoading}>
                                                        {isLoading ? "Filtering..." : "Filter"}
                                                    </Button>
                                                    {!isLoading && filtered && (
                                                        <Button className="btn-fill res-size" style={{ backgroundColor: "#3AAB7B", border: "none" }} disabled={isDownloading}>
                                                            <CSVLink
                                                                data={report}
                                                                filename={"daily_transaction_report.csv"}
                                                                onClick={() => {
                                                                    setIsDownloading(true);
                                                                    setTimeout(() => setIsDownloading(false), 1000);
                                                                }}
                                                            >
                                                                {isDownloading ? "Downloading..." : "Download"}
                                                            </CSVLink>
                                                        </Button>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    )
}
