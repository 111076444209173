import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import {
  getMerchantTypeData,
  getMerchantTypeDiscountByMerchantType_id,
  updateMerchantType,
  updateMerchantTypeDiscount,
  addMerchantType,
  addMerchantTypeDiscount,
} from "../services/merchantType";
import _uniqueId from "lodash/uniqueId";

function MerchantTypeForm() {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [ClientID, setClientID] = React.useState();
  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const [uniqueIDFormData] = React.useState(_uniqueId("prefix-"));
  const [formData, setFormData] = React.useState({
    id: "",
    Title: "",
    interestOn: "Client",
  });
  const [discountFormData, setDiscountFormData] = React.useState([]);

  useEffect(() => {
    const params = queryParams.get("id");
    if (params != null) {
      setClientID(params);
    } else {
      setClientID(null);
      setFormData({ id: uniqueIDFormData, Title: "", interestOn: "Client" });
    }
  }, []);
  useEffect(() => {
    if (ClientID == null) return;
    getMerchantTypeData(ClientID)
      .then((MTRes) => {
        setFormData(MTRes.data);
        getMerchantTypeDiscountByMerchantType_id(MTRes.data.id)
          .then((MTDRes) => {
            setDiscountFormData(MTDRes.data);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, [ClientID]);
  const { id, Title, interestOn } = formData;
  const validateInput = (name, value) => {
    if (name === "Title") {
      let pattern = new RegExp("^[a-zA-Z0-9 ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only alphabets and spaces";
    }
    if (name === "Interest") {
      if (value > 100 || value < 0)
        return "cannot be greater than 100% or less than 0%";
    }
    if (name == "NumberOfMonths") {
      if (value < 0) {
        return "Months must be greater than 1";
      }
    }
    return true;
  };

  const handleInputChange = (e) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      alert(valid);
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleNumberOfMonthsAndInterest = (e, index) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      alert(valid);
      return;
    }
    let temp = [...discountFormData];
    if (e.target.name === "NumberOfMonths") {
      temp[index] = {
        id: temp[index].id,
        Interest: temp[index].Interest,
        [e.target.name]: parseInt(e.target.value),
        MerchantType_id: temp[index].MerchantType_id,
      };
      setDiscountFormData(temp);
    } else {
      temp[index] = {
        id: temp[index].id,
        NumberOfMonths: temp[index].NumberOfMonths,
        [e.target.name]: e.target.value,
        MerchantType_id: temp[index].MerchantType_id,
      };
      setDiscountFormData(temp);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (ClientID) {
      updateMerchantType(formData)
        .then((response) => {
          discountFormData.map((item) => {
            updateMerchantTypeDiscount(item)
              .then((response) => {})
              .catch((error) => {});
          });
        })
        .catch((error) => {});
    } else {
      addMerchantType(formData)
        .then((response) => {
          discountFormData.map((item) => {
            addMerchantTypeDiscount(item)
              .then((response) => {})
              .catch((error) => {});
          });
        })
        .catch((error) => {});
    }
    history.push("/admin/MerchantTypeList");
  };
  const handleRow = () => {
    setDiscountFormData([
      ...discountFormData,
      {
        id: _uniqueId("prefix-"),
        NumberOfMonths: 0,
        Interest: 0,
        MerchantType_id: formData.id,
      },
    ]);
  };
  const handleRemoveRow = (RID) => {
    let myDiscountFormData = [...discountFormData];
    myDiscountFormData = myDiscountFormData.filter((item) => item.id !== RID);
    setDiscountFormData([...myDiscountFormData]);
  };
  const ifchecked = (target) => {
    if (interestOn == target) return true;
    return false;
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-5">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h4" className="text-center m-3 heading">
                  Merchant Type
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm="12" md="12">
                      <Form.Group>
                        <label className="requiredelement">Title</label>
                        <Form.Control
                          required
                          placeholder="Title"
                          type="text"
                          value={Title}
                          name="Title"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="4">
                      <label className="requiredelement">Interest on?</label>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="8">
                      <Form.Check
                        inline
                        label="Client"
                        type="Radio"
                        className="mr-5"
                        name="interestOn"
                        value="Client"
                        checked={ifchecked("Client")}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        label="Merchant"
                        type="Radio"
                        className="mr-5"
                        name="interestOn"
                        value="Merchant"
                        checked={ifchecked("Merchant")}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        label="Client(Gas)"
                        type="Radio"
                        className="mr-6"
                        name="interestOn"
                        value="Gas"
                        checked={ifchecked("Gas")}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        label="Client(Pharmacy)"
                        type="Radio"
                        className="ml-2 mr-6"
                        name="interestOn"
                        value="Pharmacy"
                        checked={ifchecked("Pharmacy")}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        label="Client(Prepaid)"
                        type="Radio"
                        className="mr-5"
                        name="interestOn"
                        value="Prepaid"
                        checked={ifchecked("Prepaid")}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  {discountFormData.map((item, index) => {
                    return (
                      <Row className="align-items-center">
                        <Col xs="12" sm="12" md="12" lg="12" xl="5">
                          <Form.Group>
                            <label className="requiredelement">
                              Number Of Months
                            </label>
                            <Form.Control
                              required
                              placeholder="Month"
                              type="number"
                              value={item.NumberOfMonths}
                              name="NumberOfMonths"
                              key={index}
                              onChange={(e) =>
                                handleNumberOfMonthsAndInterest(e, index)
                              }
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="12" xl="5">
                          <Form.Group>
                            <label className="requiredelement">
                              Interest in %
                            </label>
                            <Form.Control
                              required
                              placeholder="Interest"
                              type="number"
                              step="0.01"
                              value={item.Interest}
                              name="Interest"
                              key={index}
                              onChange={(e) =>
                                handleNumberOfMonthsAndInterest(e, index)
                              }
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="12" xl="2">
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{
                              width: "100%",
                              border: "none",
                              marginBottom: "-9px",
                            }}
                            onClick={() => {
                              handleRemoveRow(item.id);
                            }}
                          >
                            <i class="fas fa-trash"></i>
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="text-center  justify-content-center mt-4">
                    <Col>
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          variant="info"
                          onClick={handleRow}
                        >
                          Add Row
                        </Button>
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                        >
                          Save
                        </Button>
                        <Link to="/admin/MerchantTypeList">
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{
                              border: "none",
                            }}
                          >
                            Back
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MerchantTypeForm;
