import React from "react";
import ReactDOM from "react-dom";
//          Importing Routes
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
//          Importing CSS Files
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import 'react-toastify/dist/ReactToastify.css';
//          Importing Components
import AdminLayout from "./layouts/Admin";
import LoginForm from "./views/login";
import Otp from "views/Otp";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/login" render={(props) => <LoginForm {...props} />} />
      <Route path="/Otp" render={(props) => <Otp {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
