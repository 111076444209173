import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function getAllUsers() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/user/getAllUsers", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getAllNotMerchants() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/user/getAllNotMerchants", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getAllMerchants() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/user/getAllMerchants", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getAllMerchantWithPagafasilType() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/user/getAllMerchantsWithPagafasil", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllMerchantsForGroups() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/user/getAllMerchantsForGroups", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
