import React, { useEffect } from "react";
//          Importing Components
import ChartistGraph from "react-chartist";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import getTop3MerchantsByTransaction from "services/dashboard";
import { getMonthlyTotalProfit } from "services/dashboard";
import { getCurrentDateTransaction } from "services/dashboard";
import { getPaidByClientToday } from "services/dashboard";
import { getTodaysTotalProfit } from "services/dashboard";
import { getTodaysTransaction } from "services/dashboard";
import getAllTasks from "services/task";
import Task from "./Task";
import { getTotalKassaFloatYesterday } from "services/dashboard";
import { getTotalMembership } from "services/dashboard";
import MerchantCreditBarGraph from "./MerchantCreditBarGraph";
import EachMerchantSale from "./EachMerchantSale";
import { CgTrashEmpty } from "react-icons/cg";
function Dashboard() {
  const [merchantsTransactions, setMerchantsTransactions] = React.useState([]);
  const [monthlyTotalProfit, setMonthlyTotalProfit] = React.useState();
  const [membershipDate, setMembershipDate] = React.useState({
    show: false,
    value: null,
  });
  const [totalMembershipAmount, setTotalMembershipAmount] = React.useState();
  const [currentDateTransaction, setCurentDateTransaction] = React.useState();
  const [paidByClientToday, setPaidByClientToday] = React.useState();
  const [todaysTotalProfit, setTodaysTotalProfit] = React.useState();
  const [kassaYesterdayProfit, setKassaYesterdayProfit] = React.useState(0);
  const [todaysTransaction, setTodaysTransaction] = React.useState();
  const [highestAmountMerchantSet, setHighestAmountMerchantSet] =
    React.useState(false);
  const [amountSet, setAmountSet] = React.useState(false);
  const [timeArrayTemp, setTimeArray] = React.useState([]);
  const [merchant1AmountsTemp, setMerchant1Amount] = React.useState([]);
  const [merchant2AmountsTemp, setMerchant2Amount] = React.useState([]);
  const [merchant3AmountsTemp, setMerchant3Amount] = React.useState([]);

  var merchant1Amounts = [];
  var merchant2Amounts = [];
  var merchant3Amounts = [];
  var timeArray = [];
  var i, j;
  var highestAmountMerchant;
  useEffect(() => {
    getTotalMembership(membershipDate.value)
      .then((res) => {
        setTotalMembershipAmount(res.data.total);
      })
      .catch((err) => {});
  }, [membershipDate.value]);

  function getHighestAmountMerchant(data) {
    highestAmountMerchant = 0;
    for (i = 0; i < data.length; i++) {
      for (j = 0; j < data[i].data.length; j++) {
        if (
          parseInt(data[i].data[j].Amount) > parseInt(highestAmountMerchant)
        ) {
          highestAmountMerchant = data[i].data[j].Amount;
        }
      }
    }
    setHighestAmountMerchantSet(true);
  }

  function getTimeSlotsArray(data) {
    for (i = 0; i < data.length; i++) {
      for (j = 0; j < data[i].data.length; j++) {
        timeArray.push(data[i].data[j].Time);
      }
    }

    timeArray.sort(function (a, b) {
      if (parseInt(a.split(":")[0]) - parseInt(b.split(":")[0]) === 0) {
        return parseInt(a.split(":")[1]) - parseInt(b.split(":")[1]);
      } else {
        return parseInt(a.split(":")[0]) - parseInt(b.split(":")[0]);
      }
    });
    for (i = 0; i < timeArray.length; i++) {
      if (
        !(
          i == 0 ||
          i == 5 ||
          i == 10 ||
          i == 15 ||
          i == 20 ||
          i == 25 ||
          i == 30 ||
          i == 35 ||
          i == 40 ||
          i == timeArray.length - 1
        )
      ) {
        timeArray[i] = "";
      }
    }

    for (i = 0; i < timeArray.length; i++) {
      if (timeArray[i] !== "") {
        if (timeArray[i].split(":")[0].length == 1) {
          timeArray[i] =
            // "0" + timeArray[i].split(":")[0] + ":" + timeArray[i].split(":")[1];
            "0" + timeArray[i].split(":")[0] + ":00";
        }
        if (timeArray[i].split(":")[0].length == 2) {
          timeArray[i] = timeArray[i].split(":")[0] + ":00";
        }
        // if (timeArray[i].split(":")[1].length == 1) {
        //   timeArray[i] =
        //     timeArray[i].split(":")[0] + ":" + "0" + timeArray[i].split(":")[1];
        // }
      }
    }
    setTimeArray(timeArray);
  }

  function getIndividualAmountsMerchant(data) {
    for (i = 0; i < data.length; i++) {
      for (j = 0; j < data[i].data.length; j++) {
        if (i === 0) {
          merchant1Amounts.push(data[i].data[j].Amount);
        } else if (i === 1) {
          merchant2Amounts.push(data[i].data[j].Amount);
        } else if (i === 2) {
          merchant3Amounts.push(data[i].data[j].Amount);
        }
      }
    }

    let merch1AmountLen = merchant1Amounts.length;
    let merch2AmountLen = merchant2Amounts.length;

    for (i = merch1AmountLen; i < timeArray.length; i++) {
      merchant1Amounts.push("0");
    }
    for (i = 0; i < merch1AmountLen; i++) {
      merchant2Amounts.unshift("0");
    }
    for (i = merch1AmountLen + merch2AmountLen; i < timeArray.length; i++) {
      merchant2Amounts.push("0");
    }
    for (i = 0; i < merch1AmountLen + merch2AmountLen; i++) {
      merchant3Amounts.unshift("0");
    }

    setMerchant1Amount(merchant1Amounts);
    setMerchant2Amount(merchant2Amounts);
    setMerchant3Amount(merchant3Amounts);
    setAmountSet(true);
    merchant1Amounts = [];
    merchant2Amounts = [];
    merchant3Amounts = [];
    timeArray = [];
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch new data
        const res = await getTop3MerchantsByTransaction();

        // Extract data from the response
        const newData = res.data;

        // Reset state variables and local arrays

        setMerchant1Amount([]);
        setMerchant2Amount([]);
        setMerchant3Amount([]);
        setTimeArray([]);
        setHighestAmountMerchantSet(false);
        setAmountSet(false);

        // Process newData and update state variables
        setMerchantsTransactions(newData);
        getHighestAmountMerchant(newData);
        getTimeSlotsArray(newData);
        getIndividualAmountsMerchant(newData);
      } catch (err) {
        // Handle error
      }
    };

    fetchData();
    // Set up interval for fetching data every 10 seconds
    const interval = setInterval(fetchData, 5000);

    // Clear interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    getTotalMembership()
      .then((res) => {
        setTotalMembershipAmount(res.data.total);
      })
      .catch((err) => {});
    // getMonthlyTotalProfit()
    //   .then((res) => {

    //     setMonthlyTotalProfit(res.data[0]);

    //   })
    //   .catch((err) => {});
    getCurrentDateTransaction()
      .then((res) => {
        setCurentDateTransaction(res.data[0]);
      })
      .catch((err) => {});
    getPaidByClientToday()
      .then((res) => {
        setPaidByClientToday(res.data[0]);
      })
      .catch((err) => {});
    // getTodaysTotalProfit()
    //   .then(res => {
    //     setTodaysTotalProfit(res.data[0])
    //   })
    //   .catch(err => {
    //   })
    getTotalKassaFloatYesterday()
      .then((res) => {
        setKassaYesterdayProfit(res.data.kassaYesterdayProfit);
      })
      .catch((err) => {});
    getTodaysTransaction()
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          if (a.Time > b.Time) return 1;
          return -1;
        });
        setTodaysTransaction(sortedData);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats ">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        Total Membership
                        {membershipDate.value
                          ? " of " + membershipDate.value
                          : ""}
                      </p>
                      <Card.Title as="h4">
                        {totalMembershipAmount && (
                          <>
                            {totalMembershipAmount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "NAF",
                            })}
                          </>
                        )}
                      </Card.Title>
                    </div>
                  </Col>
                  {/* <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        Total Profit of{" "}
                        {monthlyTotalProfit && monthlyTotalProfit.Month}
                      </p>
                      <Card.Title as="h4">
                        NAF{" "}
                        {monthlyTotalProfit
                          ? parseFloat(monthlyTotalProfit.Profit).toFixed(2)
                          : 0}
                      </Card.Title>
                    </div>
                  </Col> */}
                </Row>
              </Card.Body>
              {!membershipDate.show ? (
                <Card.Footer>
                  <hr></hr>
                  <div
                    className="stats"
                    onClick={() =>
                      setMembershipDate({ ...membershipDate, show: true })
                    }
                  >
                    <i className="fa fa-calendar  mr-1"></i>
                    Choose a date
                  </div>
                </Card.Footer>
              ) : (
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="fa fa-calendar  mr-1"></i>
                    <input
                      type="date"
                      onChange={(e) =>
                        setMembershipDate({
                          ...membershipDate,
                          value: e.target.value,
                        })
                      }
                    />
                    <i
                      className="fa fa-times  ml-2 mr-1"
                      onClick={() =>
                        setMembershipDate({ value: null, show: false })
                      }
                    >
                      {" "}
                    </i>
                  </div>
                </Card.Footer>
              )}
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats ">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Today's Transaction</p>
                      <Card.Title as="h4">
                        NAF{" "}
                        {currentDateTransaction
                          ? currentDateTransaction.TodayTransaction
                            ? parseFloat(
                                currentDateTransaction.TodayTransaction
                              ).toFixed(2)
                            : 0
                          : 0}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Today
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Kassa Float Today</p>
                      <Card.Title as="h4">
                        NAF{" "}
                        {paidByClientToday
                          ? paidByClientToday.AmountPaidByClient
                            ? parseFloat(
                                paidByClientToday.AmountPaidByClient
                              ).toFixed(2)
                            : 0
                          : 0}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Today
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        Total Of Kassa Floats Yesterday
                      </p>
                      <Card.Title as="h4">
                        NAF {kassaYesterdayProfit}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Yesterday
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Top 3 Merchants</Card.Title>
                {/* <p className="card-category">24 Hours performance</p> */}
              </Card.Header>
              {merchantsTransactions && merchantsTransactions.length > 0 ? (
                <>
                  {merchantsTransactions[0] &&
                    highestAmountMerchantSet &&
                    timeArrayTemp &&
                    amountSet && (
                      <Card.Body>
                        <div className="ct-chart" id="chartHours">
                          <ChartistGraph
                            data={{
                              labels: timeArrayTemp.map((time) => time),
                              series: [
                                // [287, 80, 98, 898, 87],
                                merchant1AmountsTemp.map((mer1) => mer1),
                                merchant2AmountsTemp.map((mer2) => mer2),
                                merchant3AmountsTemp.map((mer3) => mer3),
                              ],
                            }}
                            type="Line"
                            options={{
                              low: 0,
                              high: highestAmountMerchant,
                              showArea: false,
                              height: "245px",

                              axisX: {
                                showGrid: false,
                              },
                              axisY: {
                                labelOffset: {
                                  x: 5,
                                  y: 11,
                                },
                              },
                              lineSmooth: true,
                              showLine: true,
                              showPoint: true,
                              fullWidth: true,
                              chartPadding: {
                                right: 50,
                              },
                            }}
                            responsiveOptions={[
                              [
                                "screen and (max-width: 640px)",
                                {
                                  axisX: {
                                    labelInterpolationFnc: function (value) {
                                      return value[0];
                                    },
                                  },
                                },
                              ],
                            ]}
                          />
                        </div>
                      </Card.Body>
                    )}
                </>
              ) : (
                <>
                  <div style={{ textAlign: "center", padding: "50px" }}>
                    <CgTrashEmpty size={64} color="#24444a" />
                    <p>Data not found.</p>
                  </div>
                </>
              )}

              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  {merchantsTransactions[0] &&
                    merchantsTransactions[0].data[0].Name}
                  <i
                    className="fas fa-circle text-danger"
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {merchantsTransactions[1] &&
                    merchantsTransactions[1].data[0].Name}
                  <i
                    className="fas fa-circle text-warning"
                    style={{ marginLeft: "10px" }}
                  ></i>
                  {merchantsTransactions[2] &&
                    merchantsTransactions[2].data[0].Name}
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-history"></i>
                  Updated 1 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          {/* <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Email Statistics</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "40%"],
                      series: [40, 20, 40],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Bounce <i className="fas fa-circle text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col md="6">
            <EachMerchantSale />
          </Col>
          <Col md="6">
            <div
              style={{
                maxHeight: "240px",
                overflowY: "auto",
              }}
            >
              <Task />
            </div>
            <div>
              <MerchantCreditBarGraph />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Today's Transactions</Card.Title>
                <p className="card-category">List of Transactions Today</p>
              </Card.Header>
              <Card.Body>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0">Kliente Code</th>
                      <th className="border-0">Amount</th>
                      <th className="border-0">Merchant Name</th>
                      <th className="border-0">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todaysTransaction?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.Code}</td>
                          <td>{item.Amount}</td>
                          <td>{item.Merchant_Name}</td>
                          <td>{item.Time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
