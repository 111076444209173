import React, { useEffect } from "react";
//          Importing CSS Files
import "../../components/Dashboard.css";
//          Importing Routing Components
import { useHistory } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { MerchantRow } from "../../components/IssuanceCardForm/marchantRow";
//          Importing Services
import getClientList, { getClientListForIssuance } from "../../services/client";
import addIssuanceHistory, {
  getMaxRemainingCreditClient,
} from "../../services/issuanceHistory";
// import { getClientMaxBorrowAmountById } from "../services/client"
import { getNfcList } from "../../services/nfc";
import {
  addIssuanceHistoryRow,
  addIssuanceHistoryPaybackPeriod,
  getissuancehistoryByID,
  getIssuanceHistoryRow,
  getIssuanceHistoryPaybackPeriod,
  getissuancehistoryByClientId,
} from "../../services/issuanceHistory";
import _uniqueId from "lodash/uniqueId";
import { getMerchantTypeDiscountById } from "services/merchantType";
import { addInsurance } from "services/insurance";
import { getUniqueString } from "utils/getUniqueString";

import { getUserId } from "services/auth";
import CustomSelect from "utils/CustomSelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { getMerchantData } from "services/merchant";
import { getMerchantTypeDiscountByMerchantType_id } from "services/merchantType";
import { getMerchantTypeData } from "services/merchantType";
import { updateClientRequestStatus } from "services/clientRequest";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

function AddIssuance({ payload }) {
  const history = useHistory();
  const [uniqueID] = React.useState(Math.random() * 100000 * Math.random());

  // Form Data
  const [formData, setFormData] = React.useState({
    DateTime: new Date().toISOString().split("T")[0],
    Amount: "",
    Client_id: "",
    NfcCard_id: "",
    Pincode: "",
    Balance: "",
    id: uniqueID,
  });
  const { DateTime, Amount, Client_id, NfcCard_id, Pincode, id, paymentType } =
    formData;
  // Merchant Row Form Data
  const [merchantFormData, setMerchantFormData] = React.useState([]);
  const [clientData, setClientData] = React.useState({
    bonusLimit: 0,
    clientBorrowLimit: 0,
    id: null,
  });
  const [requestData, setRequestData] = React.useState(
    JSON.parse(payload.requestData)
  );

  const getAllBusinessInfo = async (merchantID) => {
    let merchantData = (await getMerchantData(merchantID)).data;
    let merchantTypeData = (
      await getMerchantTypeData(merchantData.MerchantType_id)
    ).data;
    let merchantTypeDiscount = (
      await getMerchantTypeDiscountByMerchantType_id(
        merchantData.MerchantType_id
      )
    ).data;
    let info = {};

    info.merchant = merchantData;
    info.merchantNameAndCode = `${merchantData.Name}: ${merchantData.Code}`;
    info.merchantTypeDiscount = merchantTypeDiscount.map((item) => {
      return {
        interest: item.Interest,
        numOfMonths: item.NumberOfMonths,
        id: item.id,
      };
    });
    info.merchantType = merchantTypeData;
    return info;
  };
  const generatePaymentPeriods = (merchantForm, issuanceForm) => {
    const {
      selectedPeriod: numberOfMonths,
      merchantType: { interestOn },
      merchantTypeDiscount,
    } = merchantForm;
    const { DateTime: dateTime, Amount: amount } = issuanceForm;
    try {
      let { interest } = merchantTypeDiscount.find((item) => {
        if (item.numOfMonths == numberOfMonths) {
          return true;
        }
      });
      const myPaymentPeriods = [];
      let issuedDate = new Date(dateTime);
      const issuedAmount = amount;
      const perMonthAmount =
        (issuedAmount +
          (interestOn === "Client" ? (issuedAmount * interest) / 100 : 0)) /
        numberOfMonths;
      for (let index = 0; index < numberOfMonths; index++) {
        let nextMonthDate = new Date(
          issuedDate.getFullYear(),
          issuedDate.getMonth() + index + 2,
          1
        )
          .toISOString()
          .split("T")[0];
        myPaymentPeriods.push({
          DateTime: nextMonthDate,
          Amount: Math.round((perMonthAmount + Number.EPSILON) * 100) / 100,
        });
      }
      return myPaymentPeriods;
    } catch (error) {
      alert("period doesnt exist is merchant type");
    }
  };
  useEffect(async () => {
    let {
      amount = 0,
      clientID,
      pinCode,
      date,
      merchants,
      period,
    } = requestData;
    let nfcCardId = "";
    let result = await getissuancehistoryByClientId(clientID);
    if (result && result.data.length > 0) {
      nfcCardId = result.data[0].NfcCard_id;
    }
    let newFormData = {
      DateTime: date.split("T")[0],
      Amount: amount,
      Client_id: clientID,
      Pincode: pinCode,
      NfcCard_id: nfcCardId,
    };

    setFormData({
      ...formData,
      ...newFormData,
    });
    let merchantsData = await Promise.all(
      merchants.map(async (id) => {
        let info = await getAllBusinessInfo(id);
        info.selectedPeriod = period;
        info.selectedMerchantTypeDiscount = info.merchantTypeDiscount.find(
          (item) => {
            if (item.numOfMonths == period) {
              return true;
            }
          }
        );

        info.paybackPeriods = generatePaymentPeriods(info, newFormData);
        return {
          value: info.merchant.id,
          info,
          label: info.merchantNameAndCode,
        };
      })
    );
    setMerchantFormData(merchantsData);

    const { data: clientData } = await getMaxRemainingCreditClient(clientID);
    setClientData({
      clientBorrowLimit: clientData.amount,
      bonusLimit: clientData.bonus,
      id: clientID,
    });
  }, [requestData]);

  const handleReject = async () => {
    await updateClientRequestStatus(0, payload.id);
    history.goBack();
    return;
  };

  const handleAccept = async (e) => {
    e.preventDefault();
    try {
      const createIssuanceData = { ...formData, handledByUserId: getUserId() };

      const newIssuance = await addIssuanceHistory(createIssuanceData);
      if (merchantFormData.length > 0) {
        await Promise.all(
          merchantFormData.map(async (item, index) => {
            let { info } = item;
            const rowData = {
              id: JSON.stringify(Math.random() * 100000 * Math.random()),
              issuancehistoryId: newIssuance.data.id,
              merchantId: info.merchant.id,
              numberOfMonthsId: info.selectedMerchantTypeDiscount.id,
            };
            await addIssuanceHistoryRow(rowData);
          })
        );
      }
      if (merchantFormData[0].info.paybackPeriods.length > 0) {
        let paybackPeriods = merchantFormData[0].info.paybackPeriods;
        await Promise.all(
          paybackPeriods.map(async (PP) => {
            const myPaybackPeriod = {
              date: PP.DateTime,
              amount: PP.Amount,
              status: "NotPaid",
              issuanceHistory_Id: newIssuance.data.id,
              type: 2,
            };
            await addIssuanceHistoryPaybackPeriod(myPaybackPeriod);
          })
        );
      } else {
        const issuedDate = new Date(formData.DateTime);
        var nextMonth = new Date(
          issuedDate.getFullYear(),
          issuedDate.getMonth() + 1,
          2
        );
        var nextMonth1 = new Date(
          nextMonth.getFullYear(),
          nextMonth.getMonth() + 1,
          2
        );
        nextMonth = nextMonth.toISOString();
        nextMonth1 = nextMonth1.toISOString();
        const myPaybackPeriod1 = {
          invoiceNumber: getUniqueString(),
          date: nextMonth,
          amount: 0,
          type: 1,
          TypeOfReturnPayment: "Cash",
          status: "NotPaid",
          issuanceHistory_Id: newIssuance.data.id,
        };
        await addIssuanceHistoryPaybackPeriod(myPaybackPeriod1);

        const myPaybackPeriod2 = {
          invoiceNumber: getUniqueString(),
          date: nextMonth1,
          amount: 0,
          type: 2,
          TypeOfReturnPayment: "Cash",
          status: "NotPaid",
          issuanceHistory_Id: newIssuance.data.id,
        };
        await addIssuanceHistoryPaybackPeriod(myPaybackPeriod2);
      }
      await updateClientRequestStatus(1, payload.id);

      alert("Accepted");
      history.push("/admin/requestList");
    } catch (err) {
      alert("Failed");
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h4" className="text-center m-3 heading">
                Card Issuance
              </Card.Title>
            </Card.Header>
            <Card.Body> 
              <Form onSubmit={handleAccept}>
                <Row>
                  {/* Date Field */}
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Form.Group>
                      <label className="requiredelement">Date</label>
                      <Form.Control
                        required
                        placeholder="123"
                        type="date"
                        name="DateTime"
                        disabled={true}
                        value={formData.DateTime}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Client ID Field */}
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Form.Group>
                      <label
                        htmlFor="exampleLastName"
                        className="requiredelement"
                      >
                        Kliente Code
                      </label>

                      <Form.Control
                        required
                        placeholder="123"
                        type="text"
                        disabled={true}
                        value={formData.Client_id}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  {/* NFC Card Field */}
                </Row>
                <Row>
                  {/* Amount Field */}
                  <Col xs="12" sm="12" md="12" lg={"6"} xl={"6"}>
                    <Form.Group>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <label className="requiredelement">Montante</label>
                        <div
                          style={{
                            marginLeft: "20px",
                            color: "#9A9A9A",
                            fontSize: "15px",
                          }}
                        >
                          Bonus:&nbsp;{clientData.bonusLimit} &nbsp;&nbsp;
                          Max:&nbsp;{clientData.clientBorrowLimit}
                        </div>
                      </div>
                      <Form.Control
                        required
                        placeholder="100"
                        type="number"
                        name="Amount"
                        disabled={true}
                        value={formData.Amount}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">NFC Card</label>
                      <Form.Control
                        required
                        placeholder="123"
                        type="text"
                        disabled={true}
                        value={formData.NfcCard_id}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  {/* Code Pin Field */}

                  {/* <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                        <Form.Group>
                                            <label className="requiredelement">Pin Code</label>
                                            <Form.Control
                                                required placeholder="123" type="text" name="Pincode" disabled={true}
                                                value={formData.Pincode}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> */}
                </Row>
                {merchantFormData.map((item, index) => {
                  return (
                    <>
                      <Row>
                        {/* Amount Field */}
                        <Col xs="12" sm="12" md="12" lg={"6"} xl={"6"}>
                          <Form.Group>
                            <label className="requiredelement">Merchant</label>
                            <Form.Control
                              type="text"
                              disabled={true}
                              value={item.label}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        {/* Code Pin Field */}

                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <Form.Group>
                            <label className="requiredelement">Period</label>
                            <Form.Control
                              type="text"
                              disabled={true}
                              value={item.info.selectedPeriod}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  );
                })}

                {/* {merchantFormData.length > 0 ?
                                    merchantFormData.map((merchantItem, index) =>
                                        <MerchantRow
                                            RowIndex={index}
                                            ClientID={ClientID}
                                            merchantsId={merchantItem.Merchants_id}
                                            getMerchantId={(e) => { getMerchantId(merchantItem.id, e) }}
                                            paybackPeriod={merchantItem.PaybackPeriod}
                                            getPaybackPeriod={(period) => { getPaybackPeriod(merchantItem.id, period) }}
                                            onRemove={() => { handleRemoveMerchant(merchantItem.id) }}
                                        />) : ""} */}
                {merchantFormData.length > 0
                  ? merchantFormData[0].info.paybackPeriods.map(
                      (periodItem, periodIndex) => (
                        <Row style={{ alignItems: "center" }}>
                          <Col xs="12" sm="12" md="12" lg="12" xl="4">
                            <Form.Group>
                              <label>
                                Payback Period&nbsp;{periodIndex + 1}
                              </label>
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="12" xl="4">
                            <Form.Group>
                              <label>Date</label>
                              <Form.Control
                                type="date"
                                value={periodItem.DateTime}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="12" xl="4">
                            <Form.Group>
                              <label>Amount</label>
                              <Form.Control
                                type="number"
                                value={parseFloat(periodItem.Amount).toFixed(2)}
                                disabled
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      )
                    )
                  : ""}

                {/* Form Buttons Row */}
                <Row className="text-center justify-content-center mt-2">
                  <Col md="12">
                    <div className="button-wrapper">
                      <Button
                        className="btn-fill res-size"
                        type="submit"
                        style={{ backgroundColor: "#3AAB7B", border: "none" }}
                      >
                        <div>Accept</div>
                      </Button>
                      <Button
                        className="btn-fill res-size"
                        variant="danger"
                        style={{ border: "none" }}
                        onClick={() => handleReject()}
                      >
                        Reject
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default AddIssuance;
