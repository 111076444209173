import React, { useEffect } from 'react';
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { getAllMerchants } from 'services/user';
import CustomSelect from 'utils/CustomSelect';
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import getMerchantsByUser from 'services/merchantGroup';
import { getAvailaibleMerchants } from 'services/merchantGroup';
import { addMerchantsToGroup } from 'services/merchantGroup';
import { forfeitMerchantByMID } from 'services/merchantGroup';
import { getAllMerchantsForGroups } from 'services/user';

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
);

const animatedComponents = makeAnimated();
export default function MerchantGroupForm() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    const [merchants, setMerchants] = React.useState([{}]);
    const [selectedMerchants, setSelectedMerchants] = React.useState([])
    const [assignedMerchants, setAssignedMerchants] = React.useState([])

    const [userList, setUserList] = React.useState([])
    const [user, setUser] = React.useState(null)
    useEffect(() => {
        const uid = queryParams.get("uid");
        const email = queryParams.get("email")
        if (uid != null && email != null) {
            setUser({
                value: uid,
                label: email
            });
            return
        }
        getAllMerchantsForGroups().then((response) => {

            const { data } = response
            const formattedData = data.map(item => {
                return {
                    value: item.id,
                    label: item.email
                }
            })
            setUserList(formattedData);
        }).catch((error) => {
        });
    }, []);

    useEffect(() => {
        if (!user) return
        getMerchantsByUser(user.value)
            .then(res => {
                const { data } = res
                const formattedData = data.map(item => {
                    return {
                        value: item.id,
                        label: item.Name
                    }
                })
                setAssignedMerchants(formattedData);
            })
            .catch(err => {

            })
        getAvailaibleMerchants(user.value).then((response) => {
            const { data } = response
            const formattedData = data.map(item => {
                return {
                    value: item.id,
                    label: item.Name
                }
            })
            setMerchants(formattedData);
        }).catch((error) => {
        });
    }, [user])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedMerchants.length < 1) {
            alert("Lista di Merchant is empty")
        }
        else if (user == null) {
            alert("No User Selected")
        }
        else {
            const merchants = selectedMerchants.map(item => item.value)
            addMerchantsToGroup(merchants, user.value)
                .then(() => {
                    history.push("/admin/MerchantGroupList")
                })
                .catch(() => {

                })
        }
    }
    const handleMerchantChange = (selected) => {
        setSelectedMerchants(selected)
    }
    const handleAssignedMerchantChange = (data) => {
        const dataSet = new Set(data)
        const merchant = (assignedMerchants.filter(item => {
            if (dataSet.has(item)) {
                return false
            }
            return true
        }))[0]
        const mid = merchant.value
        if (!confirm(`Delete ${merchant.value} : ${merchant.label} ?`)) return
        forfeitMerchantByMID(mid)
            .then(res => {
                setAssignedMerchants(data)
            })
            .catch(err => {

            })
        setMerchants([...merchants, merchant])

    }
    const handleUserChange = (selected) => {
        setUser(selected)
    }

    return (
        <div>
            <div>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <Card className="form-wrapper mt-4">
                                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                                    <Card.Title as="h4" className="text-center m-3 heading">
                                        Add Merchant Group
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleSubmit}>

                                        <Row>
                                            <Col md="12">
                                                <Form.Group>
                                                    <label className="requiredelement">Users</label>
                                                    <CustomSelect
                                                        options={userList}
                                                        isMulti={false}
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue, animatedComponents }}
                                                        onChange={handleUserChange}
                                                        allowSelectAll={false}
                                                        value={user}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {queryParams.get("uid") && <Row>
                                            <Col md="12">
                                                <Form.Group>
                                                    <label className="requiredelement">Assigned Merchants</label>
                                                    <CustomSelect
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue, animatedComponents }}
                                                        onChange={handleAssignedMerchantChange}
                                                        allowSelectAll={false}
                                                        value={assignedMerchants}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col md="12">
                                                <Form.Group>
                                                    <label className="requiredelement">Merchants</label>
                                                    <CustomSelect
                                                        options={merchants} // Options to display in the dropdown
                                                        // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{ Option, MultiValue, animatedComponents }}
                                                        onChange={handleMerchantChange}
                                                        allowSelectAll={true}
                                                        value={selectedMerchants}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="text-center justify-content-center mt-2">
                                            <Col md="12">
                                                <div className="button-wrapper">
                                                    <Button className="btn-fill res-size" type="submit" style={{ backgroundColor: "#3AAB7B", border: "none" }}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i> ADD
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    )
}
