import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";

import checkUser, { login } from "../services/auth";

const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    position: "relative", // Add this to help position the "Next" button
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  nextButton: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const LoginForm = () => {
  const history = useHistory();
  const [loginResponse, setLoginResponse] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null); // State to store QR code data
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const checkRoleAndRedirect = async () => {
      const role = window.localStorage.getItem("role");
      if (checkUser()) {
        if (parseInt(role) === 4) {
          history.push("/admin/currentYearDebt");
        } else {
          history.push("/admin/dashboard");
        }
      }
    };

    checkRoleAndRedirect();
  }, []);

  const activate = async (event) => {
    event.preventDefault();
    setSubmitting(true); // Start the submitting state (show loader)

    try {
      const response = await login(formData);
      console.log("===data2", response);

      if (response?.data?.accessToken) {
        const { id, accessToken, refreshToken, role, expiryDate } =
          response.data;

        if (accessToken) {
          const storage = window.localStorage;
          storage.setItem("id", id);
          storage.setItem("token", accessToken);
          storage.setItem("refreshToken", refreshToken);
          storage.setItem("role", role);
          storage.setItem("expiryDate", expiryDate);

          history.push("/admin/currentYearDebt");
        }
      } else {
        if (response.result && response.result.toLowerCase() === "ok") {
          setLoginResponse({ color: "green", message: "Success" });

          if (response.qrCodeDataUrl) {
            const storage = window.localStorage;
            setQrCodeData(response.qrCodeDataUrl);
            storage.setItem("id", response?.id);
            storage.setItem("email", response?.email);
            setSubmitting(false); // Stop loader
          } else {
            history.push("/Otp");
          }
        } else if (
          response.result &&
          response.result.toLowerCase() === "error"
        ) {
          setLoginResponse({ color: "red", message: response.message });
          setQrCodeData(null); // Clear QR code on error
          setSubmitting(false); // Stop loader
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoginResponse({
        color: "red",
        message: "An error occurred during login.",
      });
      setSubmitting(false); // Stop loader in case of error
    }
  };

  const classes = useStyles();

  return (
    <main className={classes.layout + " loginform"}>
      <Paper className={classes.paper} elevation={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {/* Hide the title and description if the QR code is shown */}
          {!qrCodeData ? (
            <>
              <Typography component="h1" variant="h4" gutterBottom>
                Login
              </Typography>
              <Typography component="p" gutterBottom>
                Log in to your account dashboard
              </Typography>
            </>
          ) : (
            <>
              <Typography component="h1" variant="h4" gutterBottom>
                Login
              </Typography>
              <Typography component="p" gutterBottom>
                Scan this QR code with Google Authenticator to set up 2FA
              </Typography>
            </>
          )}

          {qrCodeData && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="400px"
              height="300px"
            >
              <img src={qrCodeData} alt="QR code for Google Authenticator" />
            </Box>
          )}
        </Box>

        {/* Show form only if QR code is not shown */}
        {!qrCodeData && (
          <form method="post" className={classes.form} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            {loginResponse && (
              <Typography
                variant="h6"
                style={{ color: loginResponse.color, marginTop: "10px" }}
              >
                {loginResponse.message}
              </Typography>
            )}
            <Box mt={3} mb={-3}>
              <Button
                onClick={activate}
                disabled={submitting}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {submitting ? <CircularProgress size={24} /> : "Sign In"}
              </Button>
            </Box>
          </form>
        )}

        {/* Show "Next" button when QR code is shown */}
        {qrCodeData && (
          <Button
            variant="contained"
            color="primary"
            className={classes.nextButton}
            onClick={() => history.push("/Otp")}
          >
            Next
          </Button>
        )}
      </Paper>
    </main>
  );
};

export default LoginForm;
