import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap'
import { getTotalSalesOfEachMerchant } from 'services/dashboard'

export default function EachMerchantSale() {
    const [paginatedList, setPaginatedList] = useState({
        hasNextPage: false,
        hasPreviousPage: false,
        total: 0,
        totalPages: 0,
        docs: []
    })
    const { docs, hasNextPage, hasPreviousPage, total, totalPages } = paginatedList
    const [pagination, setPagination] = useState({
        limit: 8,
        page: 1
    })

    useEffect(async () => {
        const { limit, page } = pagination
        setPaginatedList((await getTotalSalesOfEachMerchant(limit, page)).data)
    }, [pagination])

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h4">Merchant Monthly Total Sales</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table className="table-hover" responsive>
                    <thead>
                        <tr>
                            <th>Merchant</th>
                            <th >Total Sale</th>
                        </tr>
                    </thead>
                    <tbody>
                        {docs.map((item) => (
                            <tr key={item.name}>
                                <td>{item.name}</td>
                                <td style={{ color: 'green', fontWeight: "bold" }}>{item.sales}</td>
                            </tr>
                        ))}
                    </tbody>

                </Table >
                <div className="pagination" style={{alignItems : 'center', justifyContent: 'center'}}>
                    <Button
                        onClick={() => setPagination({ limit: pagination.limit, page: pagination.page - 1 })}
                        disabled={pagination.page === 1}
                    >
                        Previous
                    </Button>
                    &nbsp;<span>Page {pagination.page} of {totalPages}</span>&nbsp;
                    <Button
                        onClick={() => setPagination({ limit: pagination.limit, page: pagination.page + 1 })}
                        disabled={pagination.page === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </Card.Body >
        </Card >
    )
}
