import React from 'react';
//          Importing CSS Files
import styles from "./style.module.css";

// position: top, bottom, left, right
export const Tooltip = ( { children, message, position } ) => {
    const [ show, setShow ] = React.useState( false );
    const Style={
        tooltip: {
            position: "relative",
        },
        tooltipTrigger: {
            display: "inline-block",
        },
        tooltipMessage: {
            backgroundColor: "#0A0B0D", borderRadius: "7px", color: "#FFFFFF", fontSize: "16px", fontWeight: 500, lineHeight: "28px", 
            textAlign: "center", padding: "7px 20px",
        }
    };
    const setPosition = ( position ) => {
        if ( position === "top" ) {
            return styles.tooltipTop;
        } else if ( position === "bottom" ) {
            return styles.tooltipBottom;
        } else if ( position === "left" ) {
            return styles.tooltipLeft;
        } else if ( position === "right" ) {
            return styles.tooltipRight;
        } else {
            return {};
        }
    };
    return (
        <span style={ { ...Style.tooltip } } onMouseLeave={ () => setShow( false ) } >
            { show &&
                <div className={ `${ styles.tooltipBubble } ${ setPosition( position ) }` }>
                    <div style={ { ...Style.tooltipMessage } }>
                        { message }
                    </div>
                </div>
            }
            <span style={ { ...Style.tooltipTrigger } } onMouseOver={ () => setShow( true ) } >
                { children }
            </span>
        </span>
    )
};
