import { getToken } from "./auth"
import address from "./address"
import axios from "axios";

export default function getAllKassa() {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/kassa/getAllKassa', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}
export function getAllKassaUsers() {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/kassa/getAllKassaUsers', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}
export function getKassa(id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/kassa/getKassa/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function createKassa(data) {
    const token = getToken()
    if (!token) return "Authentication Fail SignIn again"
    return axios.post(address + '/api/kassa/createKassa', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function addKassa(data) {
    const token = getToken()
    if (!token) return "Authentication Fail SignIn again"
    return axios.post(address + '/api/kassa/addKassa', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function subtractKassa(data) {
    const token = getToken()
    if (!token) return "Authentication Fail SignIn again"
    return axios.post(address + '/api/kassa/subKassa', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}