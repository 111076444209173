import { getToken } from "./auth"
import address from "./address"
import axios from "axios"

export default function getAllTasks() {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/task/getAll', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getTaskById(id) {
    const token = getToken();
    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/task/getById/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function updateTask(formData) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/task/update', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function addTask(formData) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/task/create', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function deleteTask(id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.delete(address + '/api/task/delete/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}
