import React, { useEffect } from 'react'
import { useHistory, Link } from "react-router-dom";
import { Button, Form, Card, Table, Container, Row, Col } from "react-bootstrap";
import { getBalanceHistory } from 'services/balanceHistory';

export default function BalanceHistoryList() {
    const [tableData, setTableData] = React.useState([]);
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const issuanceHistoryID = queryParams.get("id");
    const [toSearch, setToSearch] = React.useState("");
    const [filterTableData, setFilterTableData] = React.useState([]);
    // Load Clients From DB
    useEffect(() => {
        setFilterTableData([]);
        getBalanceHistory(issuanceHistoryID).then((response) => {
            setTableData(response.data.data);
        }).catch((error) => {
        });
    }, []);
    // Search Clients
    useEffect(() => {
        let tempTable = [];
        tableData.map((item) => {
            if (
                item.amount.includes(toSearch.toLowerCase()) ||
                item.issuanceHistoryID.includes(toSearch.toLowerCase()) ||
                item.dateDeposit.includes(toSearch.toLowerCase())
            ) {
            } else {
                tempTable.push(item);
            }
        });
        setFilterTableData(tempTable);
    }, [toSearch]);
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Card.Title as="h3" className="heading">Balance History </Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Col md="4">
                                    <Form.Group>
                                        <Form.Control type="text" className="mt-4" placeholder="Search" onChange={(e) => setToSearch(e.target.value)}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Table className="table-hover" responsive>
                                    <thead>
                                        <tr>
                                            <th className="border-0">Amount</th>
                                            <th className="border-0">Client Code</th>
                                            <th className="border-0">Client Name</th>
                                            <th className="border-0">Handled By</th>
                                            <th className="border-0">Date Deposit</th>
                                            <th className="border-0">Time Deposit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item, index) => {
                                            if (filterTableData.includes(item)) {
                                                return;
                                            }
                                            return (
                                                <tr
                                                    key={index}
                                                >
                                                    <td>{item.amount}</td>
                                                    <td>{item.clientID}</td>
                                                    <td>{item.clientName}</td>
                                                    <td>{item.handledByUser}</td>
                                                    <td>{item.dateDeposit} </td>
                                                    <td>{item.timeDeposit}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
