import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Image,
  Note,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LogoImage from "../assets/img/logo/merpol_logo.png";
import { getCurrentDate } from "utils/dateHandler";

class PdfGen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: ["Initial Item"],
      inputValue: "",
    };
  }

  handelChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    });
  };
  addItem = (event) => {
    event.preventDefault();
    const newItems = this.state.items;
    newItems.push(this.state.inputValue);
    this.setState({
      items: newItems,
      inputValue: "",
    });
  };
  render() {
    const itemsArray = [];
    const styles = StyleSheet.create({
      parent: {
        display: "flex",
        flexDirection: "column",
        padding: "50px",
        width: "100%",
      },
      table: {
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: "30px",
        paddingBottom: "10px",
        borderBottom: "1px solid #264147",
      },
      // tableValue: { width: '50%', height: "100vh", textAlign: "center", color: "#000" },
      textField: { paddingBottom: "15px", fontSize: "20px" },
      img: { width: "60%", margin: "0 auto" },
      mainHeading: {
        paddingBottom: "30px",
        fontStyle: "bold",
        fontSize: "25px",
        fontWeight: "bold",
        borderBottom: "1px solid #264147",
      },
      bottomText: { paddingTop: "50px", fontSize: "18px", textAlign: "center" },
      signatureField: {
        paddingTop: "50px",
        paddingRight: "30px",
        textAlign: "right",
      },
      signatureText: {
        paddingTop: "30px",
        fontStyle: "bold",
        fontSize: "20px",
      },
    });
    const { staticArray } = this.props;
    var i = 0;
    staticArray.forEach((element) => {
      itemsArray[i] = element.key + ": " + element.value;
      i++;
    });
    const arraylen = itemsArray.length;
    const pdf = (
      <Document>
        <Page wrap>
          <View>
            <Image style={styles.img} src={LogoImage} />

            <View style={styles.parent}>
              <Text style={styles.mainHeading}>
                Dealer Bulk Payment Form Details:
              </Text>
              <View style={styles.table}>
                {itemsArray.map((item) => (
                  <Text style={styles.textField}>{item}</Text>
                ))}
              </View>
              <View style={styles.bottomText}>
                <Text>**Thankyou for your purchase. Ending note**</Text>
              </View>
            </View>
            <View style={styles.signatureField}>
              <Text>__________________</Text>
              <Text style={styles.signatureText}>Signature</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
    return (
      <div>
        <PDFDownloadLink
          document={pdf}
          fileName={"Quote" + getCurrentDate().getTime() + ".pdf"}
        >
          {({ blob, url, loading, error }) => (
            // loading ? "Loading . . ." :
            <i
              class="fa fa-download"
              aria-hidden="true"
              style={{ color: "#3aab7b" }}
            ></i>
          )}
        </PDFDownloadLink>
      </div>
    );
  }
}

export default PdfGen;
