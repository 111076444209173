import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { upsertIssuanceHistory } from "services/issuanceHistory";
import { getissuancehistoryByID } from "services/issuanceHistory";
import { getCurrentDate } from "utils/dateHandler";
import addBalanceHistory from "services/balanceHistory";
import { toast, ToastContainer } from "react-toastify";
import "./view.css";

export default function AddBalanceIssuanceHistory() {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const param_id = queryParams.get("id");
  const param_cid = queryParams.get("cid");
  const [data, setData] = React.useState({});
  const [amount, setAmount] = React.useState(null);
  useEffect(() => {
    getissuancehistoryByID(param_id)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {}, [data]);

  const validate = (amount) => {
    // let pattern = new RegExp("^[+-]?([0-9]*[.])?[0-9]+$");
    let pattern = new RegExp("^-?[0-9]d*(.d+)?$");

    if (pattern.test(amount)) {
      return true;
    }
    return true;
    // return "only numbers or spaces";
  };
  var validateInput = function (e) {
    var t = e.value;
    e.value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;
    return e.value;
  };
  const handleInputChange = (e) => {
    const newAmount = e.target.value;
    const result = validate(newAmount);
    if (result === true) {
      if (parseFloat(newAmount) + parseFloat(data.Amount || 0) >= 0) {
        // setAmount(e.target.value)
      } else {
        // setAmount(0)
        // alert("Total balance cannot go below than 0")
      }
      setAmount(e.target.value);
    } else {
      setAmount(0);
      toast.info(JSON.stringify(result), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(amount) === true) {
    } else {
      setAmount(0);
      toast.warning("Only Numbers", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    const currentDate = getCurrentDate();
    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();

    const date =
      currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
    const time =
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds();
    const amountToAdd = parseFloat(data.Amount) + parseFloat(amount);
    const balanceToAdd = parseFloat(data.Balance) + parseFloat(amount);
    const formData = {
      ...data,
      Amount: amountToAdd,
      Balance: balanceToAdd,
      DateDeposit: date + " " + time,
    };
    const userId = window.localStorage.getItem("id") || null;
    const balanceHistory = {
      amount: amount,
      dateDeposit: date + " " + time,
      issuanceHistoryID: data.id || null,
      handledByUserId: userId,
    };

    upsertIssuanceHistory(formData)
      .then((resp) => {
        addBalanceHistory(balanceHistory);
        history.push(`/admin/IssuanceHistory/?id=${param_cid}`);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h3" className="text-center m-3 heading">
                  Add Balance
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">Amount</label>
                        <Form.Control
                          required
                          placeholder="Amount"
                          type="number"
                          value={amount}
                          name="amount"
                          step={0.01}
                          //onInput={(e)=>validateInput(e)}
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">
                          CurrentBalance
                        </label>
                        <Form.Control
                          required
                          placeholder="Amount"
                          type="text"
                          value={data.Amount}
                          disabled
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                          disabled={amount ? false : true}
                        >
                          Save
                        </Button>
                        <Link to={`/admin/IssuanceHistory/?id=${param_cid}`}>
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{
                              border: "none",
                            }}
                          >
                            Back
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}
