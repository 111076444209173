import React, { useEffect } from "react";
//          Importing CSS Files
import "../../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import getPendingList from "services/clientRequest";
import { getAcceptedList } from "services/clientRequest";
import { getRejectedList } from "services/clientRequest";
import Alert from "react-bootstrap/Alert";
import { formatDate } from "utils/dateHandler";

function RequestList({ socket }) {
  const history = useHistory();
  const [tableData, setTableData] = React.useState([]);
  const [buttons, setButtons] = React.useState({
    PENDING: true,
    ACCEPTED: false,
    REJECTED: false,
  });
  useEffect(async () => {
    socket.on("newClientRequest", (response) => {
      const data = response.data;
      setTableData((prevData) => [{ ...data, color: "#FFBF00" }, ...prevData]);
    });
    await pendingList();
    return () => {
      socket.off("newClientRequest");
      socket.off("disconnect");
    };
  }, []);

  const sortData = (data) => {
    return data.sort((a, b) => {
      const timeA = new Date(a.createdAt).getTime();
      const timeB = new Date(b.createdAt).getTime();
      return timeB - timeA;
    });
  };
  const pendingList = async () => {
    const response = await getPendingList();
    setTableData(sortData(response.data.data));
  };
  const acceptedList = async () => {
    const response = await getAcceptedList();
    setTableData(sortData(response.data.data));
  };
  const rejectedList = async () => {
    const response = await getRejectedList();
    setTableData(sortData(response.data.data));
  };
  const handleViewClick = (row) => {
    if (buttons.PENDING)
      history.push("/admin/requestForm", {
        data: row,
      });
  };
  const dateParser = (date) => {
    const splitted = date.split("T");
    return formatDate(splitted[0]) + "  " + splitted[1].split(".")[0];
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  {" "}
                  {buttons.ACCEPTED
                    ? "Accepted"
                    : buttons.PENDING
                    ? "Pending"
                    : "Rejected"}{" "}
                  Requests{" "}
                </Card.Title>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{ backgroundColor: "#FFA500", border: "none" }}
                    disabled={buttons.PENDING}
                    onClick={() => {
                      pendingList();
                      setButtons({
                        PENDING: true,
                        ACCEPTED: false,
                        REJECTED: false,
                      });
                    }}
                  >
                    Pending
                  </Button>

                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{ backgroundColor: "#3AAB7B", border: "none" }}
                    disabled={buttons.ACCEPTED}
                    onClick={() => {
                      acceptedList();
                      setButtons({
                        PENDING: false,
                        ACCEPTED: true,
                        REJECTED: false,
                      });
                    }}
                  >
                    Accepted
                  </Button>

                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{ backgroundColor: "#FF0000", border: "none" }}
                    disabled={buttons.REJECTED}
                    onClick={() => {
                      rejectedList();
                      setButtons({
                        PENDING: false,
                        ACCEPTED: false,
                        REJECTED: true,
                      });
                    }}
                  >
                    Rejected
                  </Button>
                </div>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> Client Code </th>
                      <th className="border-0"> Request</th>
                      {/* <th className="border-0"> Status</th> */}
                      {buttons.PENDING ? (
                        <th className="border-0">Made At</th>
                      ) : (
                        <th className="border-0">
                          {buttons.ACCEPTED ? "Accepted At" : "Rejected At"}
                        </th>
                      )}
                      <th className="border-0"> View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          // style={{ backgroundColor: item.color || "" }}
                        >
                          <td>{JSON.parse(item.requestData).clientID}</td>
                          <td>{item.msg}</td>
                          {/* <td>
                                                        <i
                                                            className="fa fa-hourglass"
                                                            style={{
                                                                color: "grey",
                                                                textAlign: "center",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </td> */}
                          {buttons.PENDING ? (
                            <td>{dateParser(item.createdAt)}</td>
                          ) : (
                            <td>{dateParser(item.updatedAt)}</td>
                          )}
                          <td className="actions-styling">
                            <i
                              class="fa fa-eye red"
                              aria-hidden="true"
                              style={{ cursor: "pointer", color: "green" }}
                              onClick={() => handleViewClick(item)}
                            ></i>{" "}
                            &nbsp;
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default RequestList;
