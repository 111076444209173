import * as React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import "../components/Register.css";
import MerchantReport from "./merchantReport";
import IssuanceHistoryReport from "./issuanceHistoryReport";
import TotalSalesReport from "./TotalSalesReport";
import TotalDailySalesReport from "./TotalDailySalesReport";
import { getRole } from "../services/auth";
import DealerReport from "./DealerReport";
import DailyTransactionReport from "./DailyTransactionReport";
import InsuranceReport from "./InsuranceReport";
import MembershipFeeReport from "./MembershipFeeReport";
import SupermarketReport from "./SupermarketReport";
import MembershipReportNew from "./MembershipReportNew";
import ClientMonthlyReport from "./clientMonthlyReport";
import SpecialDealerReport from "./specialDealerReport";
import RemainingPayBacks from "./RemainingPayBackReport";
import CreditIssued from "./creditIssued";
import CreditUsed from "./creditUsed"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Reports() {
  const [value, setValue] = React.useState(0);

  const theme = useTheme();
  const matchesSM = null;
  // useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (parseInt(getRole()) == 1 || parseInt(getRole()) === 4) {
      setValue(3);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="tab-main-flex">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          orientation={matchesSM ? "vertical" : undefined}
          // className="tabs-flex"
          // variant="fullWidth"
          variant="scrollable"
        >
          <Tab
            label="Merchant Monthly Report"
            {...a11yProps(0)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Issuance Report"
            {...a11yProps(1)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Total Sales"
            {...a11yProps(2)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Total Daily Sale Of Current User"
            {...a11yProps(3)}
            className="tab-styling"
          />
          <Tab
            label="Dealer Report"
            {...a11yProps(4)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Special Dealer Report"
            {...a11yProps(5)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Membership Report"
            {...a11yProps(6)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Daily Transaction report"
            {...a11yProps(7)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Insurance report"
            {...a11yProps(8)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Membership Fee report"
            {...a11yProps(9)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Supermarket Montly Report"
            {...a11yProps(10)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
          <Tab
            label="Remaining PayBack Report"
            {...a11yProps(11)}
            className="tab-styling"
            style={{
              display: parseInt(getRole()) == 0 ? "" : "none",
            }}
          />
          <Tab
            label="Client Monthly Report"
            {...a11yProps(12)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />

          <Tab
            label="Credit Issued"
            {...a11yProps(13)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
       
        <Tab
            label="Credit Used"
            {...a11yProps(14)}
            className="tab-styling"
            style={{
              display:
                parseInt(getRole()) == 1 || parseInt(getRole()) == 4
                  ? "none"
                  : "",
            }}
          />
        </Tabs>
      </div>
      {value == 0 ? (
        <MerchantReport />
      ) : value == 1 ? (
        <IssuanceHistoryReport />
      ) : value == 2 ? (
        <TotalSalesReport />
      ) : value == 3 ? (
        <TotalDailySalesReport />
      ) : value == 4 ? (
        <DealerReport />
      ) : value == 5 ? (
        <SpecialDealerReport />
      ) : value == 6 ? (
        <MembershipReportNew />
      ) : value == 7 ? (
        <DailyTransactionReport />
      ) : value == 8 ? (
        <InsuranceReport />
      ) : value == 9 ? (
        <MembershipFeeReport />
      ) : value == 10 ? (
        <SupermarketReport />
      ) : value == 11 ? (
        <RemainingPayBacks />
      ) : value == 12 ? (
        <ClientMonthlyReport />
      ) : value == 13 ? (
        <CreditIssued />
      ):(
        <CreditUsed />
      )}
    </div>
  );
}

// import Header from "../components/Header";
// import About from "../components/About";
// import Services from "../components/Services";
// import Testimonials from "../components/Testimonials";
// import Team from "../components/Team";

// const Home = () => {
//   return (
//     <div>
//       <Header />
//       <About />
//       <Services />
//       <Testimonials />
//       <Team />
//     </div>
//   );
// };

// export default Home;
