import { getToken } from "./auth"
import address from "./address"
import axios from "axios"

export default function getAllDealerBulkPayment() {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/dealerBulkPayment/getAll', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getDealerBulkPaymentById(id) {
    const token = getToken();
    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/dealerBulkPayment/getById/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function updateDealerBulkPayement(formData) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/dealerBulkPayment/update', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function addDealerBulkPayment(formData) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/dealerBulkPayment/create', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function deleteDealerBulkPayment(id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.delete(address + '/api/dealerBulkPayment/delete/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}
