import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
//          Importing CSS Files
import "./clientForm.css";
import "../components/Dashboard.css";
//          Importing Routing Components
import { Link, useHistory } from "react-router-dom";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import _uniqueId from "lodash/uniqueId";
import { toast, ToastContainer } from "react-toastify";
//          Importing Services
import {
  addClient,
  getActiveClientList,
  getNextNM_Id,
  addClientImage,
  addBankStatement,
  addSalarySlips,
  getImageByClientId,
  getSalarySlipByClientId,
  getBankStatementByClientId,
} from "../services/noboClient";
import { getClientData, updateClient } from "../services/client";
import useOutsideAlerter from "utils/outsideClick";

function ClientNoboForm(props) {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [ClientID, setClientID] = React.useState(null);
  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const [dealers, setDealers] = React.useState([]);
  const [imageId, setImageId] = React.useState(null);
  const [imageChanged, setImageChanged] = React.useState(false);
  const [image, setImage] = React.useState();
  const [fuentediEntrada, setFuentediEntrada] = React.useState(null);
  // SS (SalarySlip) OSS (OldSalarySlip)
  const [salarySlips, setSalarySlips] = React.useState(null);
  const [SSChanged, setSSChanged] = React.useState(false);
  const [OSS, setOSS] = React.useState(null);
  // BS (BankStatement) OBS (OldBankStatement)
  const [bankStatements, setBankStatements] = React.useState(null);
  const [BSChanged, setBSChanged] = React.useState(false);
  const [OBS, setOBS] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // Options State Management
  const [workNoOption, setWorkNoOption] = React.useState("+5999");
  const [contactNoOption, setContactNoOption] = React.useState("+5999");
  const [phoneNumberOption, setPhoneNumberOption] = React.useState("+5999");
  // Form State Management

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };

  const [formData, setFormData] = React.useState({
    id: null,
    Date: "2021-01-01",
    Code: "",
    FirstName: "",
    LastName: "",
    address: "",
    idCard: "",
    WorkNo: "",
    ContactNo: "",
    WorksAt: "",
    Email: "",
    FaxNumber: "",
    Status: 2,
    MaxBorrowAmount: "",
    Dealer_id: "",
    SourceOfIncome: "",
    ExpiryDate: "",
    RegistrationDate: getCurrentDate(),
    RecievedCreditInPast: false,
    Partner: false,
    Children: false,
    ChildrenCount: "",
    phoneNumber: "",
    NameOfPartner: "",
    Housing: "0",
  });
  const {
    id,
    Code,
    FirstName,
    LastName,
    idCard,
    WorkNo,
    ContactNo,
    WorksAt,
    Email,
    address,
    FaxNumber,
    Status,
    MaxBorrowAmount,
    Dealer_id,
    SourceOfIncome,
    RecievedCreditInPast,
    Partner,
    Children,
    ChildrenCount,
    phoneNumber,
    Date,
    ExpiryDate,
    RegistrationDate,
    NameOfPartner,
    Housing,
  } = formData;
  const { children, value, index, ...other } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    const params = queryParams.get("id");
    if (params != null) {
      setClientID(params);
      if (formData.id === null) {
        setFormData({ ...formData, ["id"]: ClientID, ["Code"]: ClientID });
      } else return;
    } else {
      if (formData.id === null) {
        getNextNM_Id()
          .then((response) => {
            setFormData({
              ...formData,
              ["id"]: response.data.id,
              ["Code"]: response.data.id,
            });
          })
          .catch((error) => {});
        setImageId(uniqueID);
      } else return;
    }
  }, [queryParams]);
  useEffect(() => {
    // Get Active Client Api Calling
    getActiveClientList()
      .then((response) => {
        response.data.unshift({});
        setDealers(response.data);
      })
      .catch((error) => {});
  }, []);
  // Validate Form Input Data
  const validateInput = (name, value) => {
    if (name === "Code" || name === "WorksAt") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    if (name === "FirstName" || name === "LastName") {
      let pattern = new RegExp("^[a-zA-Z ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only alphabets and spaces";
    }
    if (
      name === "WorkNo" ||
      name === "FaxNumber" ||
      name === "ContactNo" ||
      name === "ChildrenCount" ||
      name === "phoneNumber" ||
      name == "idCard"
    ) {
      let pattern = new RegExp("^[0-9 +]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    return true;
  };
  // Handle Form Inputs Change
  const handleInputChange = (e) => {
    if (e.target.name == "Status") {
      setFormData({ ...formData, [e.target.name]: !Status });
      return;
    }
    if (e.target.name == "RecievedCreditInPast") {
      setFormData({ ...formData, [e.target.name]: !RecievedCreditInPast });
      return;
    }
    if (e.target.name == "Partner") {
      setFormData({ ...formData, [e.target.name]: !Partner });
      return;
    }
    if (e.target.name == "NameOfPartner") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      return;
    }
    if (e.target.name == "Children") {
      setFormData({ ...formData, [e.target.name]: !Children });
      return;
    }
    if (e.target.name == "Housing") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      return;
    }
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // Validate Email Method
  const validateEmail = (email) => {
    if (email.length < 1) return true;
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(email)) {
      return true;
    }
    return "not a valid email";
  };
  // Image Submit Method
  const handleImageSubmit = () => {
    const data = new FormData();
    data.append("id", imageId);
    data.append("Client_id", formData.id);
    data.append("file", image);
    return addClientImage(data);
  };
  // Salary Slip Submit Method
  const handleSalarySlipSubmit = (e) => {
    return addSalarySlips(salarySlips);
  };
  // Bank Statement Submit Method
  const handleBankStatementSubmit = () => {
    return addBankStatement(bankStatements);
  };
  // Form Submit Method
  const handleSubmit = (event) => {
    event.preventDefault();
    const valid = validateEmail(Email);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (imageChanged == false && image == null) {
      toast.warning("Select Image", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    if (SSChanged === false && salarySlips === null) {
      toast.warning("Select Salary Slip PDFs", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    if (BSChanged === false && bankStatements === null) {
      toast.warning("Select Bank Statement PDFs", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    setIsLoading(true);
    let fData = { ...formData };
    fData.WorkNo = workNoOption.trim() + " " + fData.WorkNo.trim();
    fData.ContactNo = contactNoOption.trim() + " " + fData.ContactNo.trim();
    if (ClientID) {
      updateClient(fData)
        .then((formResp) => {})
        .catch((formError) => {});
      handleImageSubmit()
        .then((imageResp) => {})
        .catch((imageError) => {});
      handleSalarySlipSubmit()
        .then((salarySlipResp) => {})
        .catch((salarySlipError) => {});
      handleBankStatementSubmit()
        .then((bankStatementResp) => {})
        .catch((bankStatementError) => {});
      setIsLoading(false);
      history.push("/admin/ClientList");
    } else {
      addClient(fData)
        .then((formResp) => {
          handleImageSubmit()
            .then((imageResp) => {
              handleSalarySlipSubmit()
                .then((salarySlipResp) => {
                  handleBankStatementSubmit()
                    .then((bankStatementResp) => {})
                    .catch((bankStatementError) => {});
                })
                .catch((salarySlipError) => {});
            })
            .catch((imageError) => {});
        })
        .catch((formError) => {});
      setIsLoading(false);
      history.push("/admin/ClientList");
    }
  };
  // Profile Pic onChange
  const handleProfilePic = (e) => {
    e.preventDefault();
    if (e.target.files.length == 0) return;
    setImage(e.target.files[0]);
    setImageChanged(true);
  };
  // Bank Statement onChange
  const handleBankStatement = (e) => {
    e.preventDefault();
    if (e.target.files.length == 0) return;
    if (e.target.files.length != 2) {
      toast.warning("Select 2 files only", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    const data = new FormData();
    data.append("id", uniqueID);
    data.append("file1", e.target.files[0]);
    data.append("file2", e.target.files[1]);
    data.append("Client_id", id);
    setBankStatements(data);
    setBSChanged(true);
  };
  // Salary Slip onChange
  const handleSalarySlip = (e) => {
    e.preventDefault();
    if (e.target.files.length == 0) return;
    if (e.target.files.length != 2) {
      toast.warning("Select 2 files only", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    const data = new FormData();
    data.append("id", uniqueID);
    data.append("file1", e.target.files[0]);
    data.append("file2", e.target.files[1]);
    data.append("Client_id", id);
    setSalarySlips(data);
    setSSChanged(true);
  };
  // Get Nobo Client Data
  useEffect(() => {
    if (ClientID == null) return;
    getClientData(ClientID)
      .then((response) => {
        let workNoArray = response.data.WorkNo.split(" ");
        let contactNoArray = response.data.ContactNo.split(" ");
        response.data.WorkNo = workNoArray[1];
        setWorkNoOption(workNoArray[0]);
        response.data.ContactNo = contactNoArray[1];
        setContactNoOption(contactNoArray[0]);
        setFormData({
          ...response.data,
          ["Housing"]: JSON.stringify(response.data.Housing),
        });
        if (response.data.WorksAt) {
          setFuentediEntrada(1);
        }
        if (response.data.SourceOfIncome) {
          setFuentediEntrada(2);
        }
      })
      .catch((error) => {});
    getImageByClientId(ClientID)
      .then((res) => {
        setImageId(res.data.id);
        setImage(res.data.avatar);
        setImageChanged(false);
      })
      .catch((error) => {});
    getSalarySlipByClientId(ClientID)
      .then((res) => {
        setOSS(res.data);
        const data = new FormData();
        data.append("id", res.data.id);
        data.append("file1", res.data.file1Path);
        data.append("file2", res.data.file2Path);
        data.append("Client_id", res.data.Client_id);
        setSalarySlips(data);
        setSSChanged(false);
      })
      .catch((err) => {});
    getBankStatementByClientId(ClientID)
      .then((res) => {
        setOBS(res.data);
        const data = new FormData();
        data.append("id", res.data.id);
        data.append("file1", res.data.file1Path);
        data.append("file2", res.data.file2Path);
        data.append("Client_id", res.data.Client_id);
        setBankStatements(data);
        setBSChanged(false);
      })
      .catch((err) => {});
  }, [ClientID]);
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" className="main-container">
            <Card className="form-wrapper mt-4" style={{ border: "none" }}>
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}></Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit} style={{ border: "none" }}>
                  <Row className="padding-class">
                    {/* Code Field */}
                    <Col sm="12" lg="2">
                      <Form.Group>
                        <label className="requiredelement">Code</label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="text"
                          size="lg"
                          value={Code}
                          disabled
                          name="Code"
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* First Name Field */}
                    <Col sm="12" md="6" lg="5">
                      <Form.Group>
                        <label className="requiredelement">Nomber</label>
                        <Form.Control
                          required
                          placeholder="Frank"
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                          type="text"
                          value={FirstName}
                          name="FirstName"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Last Name Field */}
                    <Col sm="12" md="6" lg="5">
                      <Form.Group>
                        <label
                          htmlFor="exampleLastName"
                          className="requiredelement"
                        >
                          Fam
                        </label>
                        <Form.Control
                          required
                          placeholder="Semper"
                          type="text"
                          value={LastName}
                          name="LastName"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Address Field */}
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">Adres</label>
                        <Form.Control
                          required
                          placeholder="Adres"
                          type="text"
                          value={address}
                          name="address"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* ID Card Field */}
                    <Col sm="12" md="6">
                      <Form.Group>
                        <label className="requiredelement">Sedula</label>
                        <Form.Control
                          ref={wrapperRef}
                          required
                          placeholder=""
                          type="text"
                          value={idCard}
                          name="idCard"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                          maxLength={"10"}
                          minLength={"10"}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Expiry Date Field */}
                    <Col sm="12" md="6">
                      <Form.Group>
                        <label className="requiredelement">
                          Fecha di Vensementu
                        </label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="date"
                          value={ExpiryDate}
                          name="ExpiryDate"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", padding: "20px 10px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* Contact Number Option Field */}
                    <Col sm="12" md="6" lg="4">
                      <Form.Group>
                        <label className="requiredelement"> Celullar</label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          required
                          value={contactNoOption}
                          name="contactNoOption"
                          onChange={(e) => {
                            setContactNoOption(e.target.value);
                          }}
                          style={{ fontSize: "14px", height: "41px" }}
                        >
                          <option value={"+5999"}> +5999</option>
                          <option value={"+599"}> +599</option>
                          <option value={"+297"}> +297</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Contact Number Field */}
                    <Col sm="12" md="6" lg="8">
                      <Form.Group>
                        <label> &nbsp;</label>
                        <Form.Control
                          required
                          placeholder="042"
                          type="text"
                          value={ContactNo}
                          name="ContactNo"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", height: "41px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* Work Number Option Field */}
                    <Col sm="12" md="6" lg="4">
                      <Form.Group>
                        <label>Telefon</label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          required
                          value={workNoOption}
                          name="workNoOption"
                          onChange={(e) => {
                            setWorkNoOption(e.target.value);
                          }}
                          style={{ fontSize: "14px", height: "41px" }}
                        >
                          <option value={"+5999"}> +5999</option>
                          <option value={"+599"}> +599</option>
                          <option value={"+297"}> +297</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Work Number Field */}
                    <Col sm="12" md="6" lg="8">
                      <Form.Group>
                        <label>&nbsp;</label>
                        <Form.Control
                          placeholder="00-0000-00"
                          type="text"
                          value={WorkNo}
                          name="WorkNo"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", height: "41px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Email Field */}
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          placeholder="Email"
                          type="text"
                          value={Email}
                          name="Email"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="12">
                      <Form.Group>
                        <label>fecha di inskripshon</label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="date"
                          value={RegistrationDate}
                          name="RegistrationDate"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Fuentedi Entrada Field */}
                  <Row className="padding-class-new">
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">
                          Fuente di Entrada
                        </label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          value={fuentediEntrada}
                          name="fuentediEntrada"
                          onChange={(e) => {
                            setFuentediEntrada(e.target.value);
                          }}
                          style={{ fontSize: "14px", height: "41px" }}
                        >
                          <option></option>
                          <option value={1}> Trabou </option>
                          <option value={2}> Penshonado </option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Works At Field */}
                  {fuentediEntrada == 1 ? (
                    <Row className="padding-class">
                      <Col sm="12">
                        <Form.Group>
                          <label className="requiredelement">
                            Ta Emplea Na
                          </label>
                          <Form.Control
                            required
                            placeholder="Ta traha na"
                            type="text"
                            value={WorksAt}
                            name="WorksAt"
                            className="placeholder-Class"
                            onChange={(e) => handleInputChange(e)}
                            style={{ padding: "20px 10px", fontSize: "14px" }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null}
                  {/* Source Of Income Field */}
                  {fuentediEntrada == 2 ? (
                    <Row className="padding-class-new">
                      <Col sm="12">
                        <Form.Group>
                          <label className="requiredelement">
                            Si bo no ta emplea, kiko ta bo medio di entrada ?
                          </label>
                          <Form.Control
                            as="textarea"
                            required
                            placeholder=""
                            value={SourceOfIncome}
                            name="SourceOfIncome"
                            onChange={(e) => handleInputChange(e)}
                            style={{ padding: "20px 10px", fontSize: "14px" }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null}
                  {/* Partner Field */}
                  <Row>
                    <Col xs="6" sm="6" lg="6">
                      <label className="mr-5 requiredelement">
                        Kasa/Partner
                      </label>
                    </Col>
                    <Col xs="6" sm="6" lg="6">
                      <Form.Check
                        inline
                        label="Si"
                        type="Radio"
                        className="radio-btn mt-1 "
                        name="Partner"
                        checked={Partner}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                      &nbsp; &nbsp;
                      <Form.Check
                        inline
                        label="No"
                        type="Radio"
                        className="radio-btn mt-1"
                        name="Partner"
                        checked={!Partner}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </Row>
                  {/* Number Of Partners Field */}
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">
                          Nomber di Kasa/Prtn
                        </label>
                        <Form.Control
                          required
                          placeholder="Nomber di Kasa/Ptrn"
                          type="text"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                          value={NameOfPartner}
                          name="NameOfPartner"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    {/* Children Field */}
                    <Col xs="6" sm="6" lg="2">
                      <label className="mr-5 requiredelement">Yu</label>
                    </Col>
                    <Col xs="6" sm="6" lg="4">
                      <Form.Check
                        inline
                        label="Si"
                        type="Radio"
                        className="radio-btn mt-1 "
                        name="Children"
                        checked={Children}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                      &nbsp; &nbsp;
                      <Form.Check
                        inline
                        label="No"
                        type="Radio"
                        className="radio-btn mt-1"
                        name="Children"
                        checked={!Children}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                    {/* Number Of Children Field */}
                    {Children ? (
                      <>
                        <Col sm="12" lg="2">
                          <label className="requiredelement">Kantidat</label>
                        </Col>
                        <Col lg="4">
                          <Form.Group>
                            <Form.Control
                              required
                              placeholder="Kantidat"
                              type="text"
                              value={ChildrenCount}
                              name="ChildrenCount"
                              className="placeholder-Class"
                              onChange={(e) => handleInputChange(e)}
                              style={{ fontSize: "14px", height: "41px" }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  {/* Housing Field */}
                  <Row className="padding-class" style={{ marginTop: "5px" }}>
                    <Col sm="12" lg="12">
                      <label className="mr-5 requiredelement">Bibienda</label>
                      <br />
                      <Form.Check
                        inline
                        label="Kas propio"
                        type="Radio"
                        className="radio-btn mt-1 "
                        value="0"
                        name="Housing"
                        checked={Housing === "0" ? true : false}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                      &nbsp; &nbsp;
                      <Form.Check
                        inline
                        label="Hur"
                        type="Radio"
                        className="radio-btn mt-1"
                        value="1"
                        name="Housing"
                        checked={Housing === "1" ? true : false}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                      &nbsp; &nbsp;
                      <Form.Check
                        inline
                        label="Serka Mayor"
                        type="Radio"
                        className="radio-btn mt-1"
                        value="2"
                        name="Housing"
                        checked={Housing === "2" ? true : false}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* Phone Number Code Field */}
                    <Col sm="12" md="6" lg="4">
                      <Form.Group>
                        <label>Tel Trabou</label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          required
                          value={phoneNumberOption}
                          name="phoneNumberCode"
                          onChange={(e) => {
                            setPhoneNumberOption(e.target.value);
                          }}
                          style={{ fontSize: "14px", height: "41px" }}
                        >
                          <option value={"+5999"}> +5999 </option>
                          <option value={"+599"}> +599 </option>
                          <option value={"+297"}> +297 </option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Phone Number Field */}
                    <Col sm="12" md="6" lg="8">
                      <Form.Group>
                        <label>&nbsp;</label>
                        <Form.Control
                          placeholder="00-0000-00"
                          type="text"
                          value={phoneNumber}
                          name="phoneNumber"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", height: "41px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Recieved Credit In Past Field */}
                  <Row className="padding-class">
                    <Col sm="12" lg="12">
                      <label className="mr-5 requiredelement">
                        A yega di tuma bon den pasado kaba?
                      </label>
                      <br />
                      <Form.Check
                        inline
                        label="Si"
                        type="Radio"
                        className="radio-btn mt-1 "
                        name="RecievedCreditInPast"
                        checked={RecievedCreditInPast}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                      &nbsp; &nbsp;
                      <Form.Check
                        inline
                        label="No"
                        type="Radio"
                        className="radio-btn mt-1"
                        name="RecievedCreditInPast"
                        checked={!RecievedCreditInPast}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </Row>
                  {/* Dealer Id Field */}
                  {RecievedCreditInPast ? (
                    <Row className="padding-class-new">
                      <Col sm="12">
                        <Form.Group>
                          <label className="requiredelement">
                            Si e Kontesta ta si, serka ken?
                          </label>
                          <Form.Control
                            as="select"
                            defaultValue=""
                            placeholder="select dealer"
                            name="Dealer_id"
                            value={Dealer_id}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                            style={{ fontSize: "14px", height: "41px" }}
                          >
                            {dealers?.map((item, index) => {
                              if (index == 0) {
                                return (
                                  <option value={item.id}>
                                    Dealers : {item.Code}
                                  </option>
                                );
                              }
                              return (
                                <option value={item.id}> {item.Code}</option>
                              );
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {/* Upload Image Field */}
                  <Row>
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">
                          Porfabor agrega un potret di bo Sedula
                        </label>
                        <Form.Control
                          required={image === null ? true : false}
                          type="file"
                          name="profilePicture"
                          accept="image/*"
                          onChange={(e) => {
                            handleProfilePic(e);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {image ? (
                    <Row className="padding-class">
                      <Col sm="12">
                        <img
                          src={
                            !imageChanged ? image : URL.createObjectURL(image)
                          }
                          className="profilePic"
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {/* Salary Slips */}
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">
                          Ultimo 2 slep nan di salario di trabou
                        </label>
                        <Form.Control
                          required={salarySlips === null ? true : false}
                          type="file"
                          multiple
                          name="salarySlips"
                          accept="application/pdf"
                          onChange={(e) => {
                            handleSalarySlip(e);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Old Salary Slips */}
                  {OSS === null || SSChanged ? null : (
                    <>
                      <Row className="padding-class">
                        <Col sm="12">
                          <a
                            href={OSS.file1Path ? OSS.file1Path : ""}
                            target="_blank"
                          >
                            {OSS.file1Path
                              ? OSS.file1Path.split("/").pop()
                              : ""}
                          </a>
                        </Col>
                      </Row>
                      <Row className="padding-class">
                        <Col sm="12">
                          <a
                            href={OSS.file2Path ? OSS.file2Path : ""}
                            target="_blank"
                          >
                            {OSS.file2Path
                              ? OSS.file2Path.split("/").pop()
                              : ""}
                          </a>
                        </Col>
                      </Row>
                    </>
                  )}
                  {/* Bank Statement */}
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">
                          Ultimo 2 statement di kuenta bankario
                        </label>
                        <Form.Control
                          required={bankStatements === null ? true : false}
                          type="file"
                          multiple
                          name="bankStatements"
                          accept="application/pdf"
                          onChange={(e) => {
                            handleBankStatement(e);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Old Bank Statements */}
                  {OBS === null || BSChanged ? null : (
                    <>
                      <Row className="padding-class">
                        <Col sm="12">
                          <a
                            href={OBS.file1Path ? OBS.file1Path : ""}
                            target="_blank"
                          >
                            {OBS.file1Path
                              ? OBS.file1Path.split("/").pop()
                              : ""}
                          </a>
                        </Col>
                      </Row>
                      <Row className="padding-class">
                        <Col sm="12">
                          <a
                            href={OBS.file2Path ? OBS.file2Path : ""}
                            target="_blank"
                          >
                            {OBS.file2Path
                              ? OBS.file2Path.split("/").pop()
                              : ""}
                          </a>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Notes</label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder=""
                          name="Notes"
                          // onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        {/* <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Buttons Footer */}
                  <Row className="text-center mt-2">
                    <Col sm="12">
                      <div className="button-wrapper">
                        <Button
                          className="add-btn res-size"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <div>
                              {" "}
                              <i class="fa fa-spinner" aria-hidden="true">
                                &nbsp;
                              </i>{" "}
                              Saving...
                            </div>
                          ) : (
                            <div>Save</div>
                          )}
                        </Button>
                        <Link to="/admin/ClientList">
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{ border: "none" }}
                          >
                            Back
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default ClientNoboForm;

ClientNoboForm.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
