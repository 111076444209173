import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { getPaybackPeriodHistory } from "services/paybackPeriodHistory";
import Grid from "@mui/material/Grid";
export default function PaybackPeriodHistoryList() {
  const [tableData, setTableData] = React.useState([]);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("cid");

  const issuanceHistoryID = queryParams.get("id");
  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);
  // Load Clients From DB

  useEffect(() => {
    setFilterTableData([]);
    getPaybackPeriodHistory(issuanceHistoryID)
      .then((response) => {
        setTableData(response.data.data);
      })
      .catch((error) => {});
  }, []);
  // Search Clients
  useEffect(() => {
    let tempTable = [];
    tableData.map((item) => {
      if (
        item.amount.includes(toSearch.toLowerCase()) ||
        item.issuanceHistoryID.includes(toSearch.toLowerCase()) ||
        item.dateDeposit.includes(toSearch.toLowerCase())
      ) {
      } else {
        tempTable.push(item);
      }
    });
    setFilterTableData(tempTable);
  }, [toSearch]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card.Title as="h3" className="heading">
                      Paybackperiod History{" "}
                    </Card.Title>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className="btn-fill res-size"
                      variant="danger"
                      style={{ border: "none", float: "right" }}
                      onClick={() => {
                        history.push(`IssuanceHistory?id=${params}`);
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0">Handled By</th>
                      <th className="border-0">Amount</th>
                      <th className="border-0">Amount Paid By Client</th>
                      <th className="border-0">Date</th>
                      <th className="border-0">Date Deposit</th>
                      <th className="border-0" style={{ textAlign: "center" }}>
                        TypeOfReturnPayment
                      </th>
                      <th className="border-0">Remarks</th>
                      <th className="border-0">Paid By Dealer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => {
                      if (filterTableData.includes(item)) {
                        return;
                      }
                      return (
                        <tr key={index}>
                          <td>{item.user}</td>
                          <td>{item.amount}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.amountPaidByClient}
                          </td>
                          <td>{item.date}</td>
                          <td>{item.dateDeposit} </td>
                          <td style={{ textAlign: "center" }}>
                            {item.TypeOfReturnPayment}{" "}
                          </td>
                          <td>{item.remarks}</td>
                          <td>{item.paidByDealer}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
