import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export function getTransactionHistory() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/transactionHistory/searchTransactions", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getSumOfTransactionsByIssuanceHistoryId(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address + "/api/transactionHistory/sumByIssuanceHistoryId/" + id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function CreateTransactionHistory(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/transactionHistory/createTransactionHistory",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
