import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routing Components
import { useHistory } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import getClientList from "../services/client";

function BonPaymentForm() {
  const history = useHistory();
  const [allClients, setAllClients] = React.useState([]);
  const [formData, setFormData] = React.useState({ clientId: "", contactNumber: "", address: "", idCard: "", telefonNumber: "" });
  const { clientId, contactNumber, address, idCard, telefonNumber, } = { ...formData };
  useEffect(() => {
    getClientList().then((response) => {
      const myClientsList = [];
      for (let index = 0; index < response.data.length; index++) {
        const client = response.data[index];
        if (client.Status === 1) {
          myClientsList.push(client);
        }
      }
      setAllClients(myClientsList);
    }).catch((error) => {
    });
  }, []);
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h4" className="text-center m-3 heading">Bon Payment</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12" xl="4">
                    <Form.Group>
                      <label htmlFor="exampleLastName" className="requiredelement">Kliente Code</label>
                      <Form.Control
                        as="select" required value={clientId}
                        onChange={(e) => {
                          let myIndex = -1;
                          myIndex = allClients.findIndex(item => item.id === e.target.value);
                          if (myIndex >= 0) {
                            const client = allClients[myIndex];
                            setFormData({ ...formData, clientId: e.target.value, contactNumber: client.ContactNo, address: client.address, idCard: client.idCard, telefonNumber: client.WorkNo })
                          }
                        }}
                      >
                        <option></option>
                        {allClients.map((item) => {
                          return <option value={item.id}>{`${item.Code}: ${item.FirstName} ${item.LastName}`}</option>;
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="4">
                    <Form.Group>
                      <label>Nomber</label>
                      <Form.Control placeholder="123" type="text" disabled value={contactNumber} ></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="4">
                    <Form.Group>
                      <label>Telefon #</label>
                      <Form.Control placeholder="123" type="text" disabled value={telefonNumber} ></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label>Sedula</label>
                      <Form.Control placeholder="123" type="text" disabled value={idCard} ></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label>Adres</label>
                      <Form.Control type="text" disabled value={address} ></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Invoice No.</label>
                      <Form.Control required type="text"></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Bon #</label>
                      <Form.Control required type="text"></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Montante Totaal</label>
                      <Form.Control required type="number"></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Pago</label>
                      <Form.Control required type="number"></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Di mas riba  Bon</label>
                      <Form.Control required type="number"></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Montante Final</label>
                      <Form.Control required type="number"></Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Form Buttons Row */}
                <Row className="text-center justify-content-center mt-2">
                  <Col md="12">
                    <div className="button-wrapper">
                      <Button className="btn-fill res-size" type="submit" style={{ backgroundColor: "#3AAB7B", border: "none" }}>
                        Save
                      </Button>
                      <Button className="btn-fill res-size" variant="info" style={{ border: "none" }}>
                        Print
                      </Button>
                      <Button className="btn-fill res-size" variant="danger" style={{ border: "none" }} onClick={() => { history.goBack() }}>
                        Back
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default BonPaymentForm;