import React, { useEffect } from "react";
//  Importing CSS Files
import "../components/Dashboard.css";
//  Importing Routes
import { useHistory } from "react-router-dom";
//  Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
//  Importing Services
import { getMerchantGroupList } from "services/merchantGroup";
import { forfeitMerchant } from "services/merchantGroup";

function MerchantGroupList() {
  const [tableData, setTableData] = React.useState([{ Checked: false }]);
  const history = useHistory();
  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);
  useEffect(() => {
    setFilterTableData([]);
    getMerchantGroupList()
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {});
  }, []);
  function sortHandler(a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }
  const sortedArrayData = tableData;
  sortedArrayData.sort(sortHandler);
  useEffect(() => {
    if (toSearch.length == 0) return;
    let tempTable = [];
    tableData.map((item) => {
      const mCode = item?.mCode?.toLowerCase();
      const mName = item?.mName?.toLowerCase();
      const uEmail = item?.uEmail?.toLowerCase();
      if (
        mCode.includes(toSearch.toLowerCase()) ||
        mName.includes(toSearch.toLowerCase()) ||
        uEmail.includes(toSearch.toLowerCase())
      ) {
      } else {
        tempTable.push(item);
      }
    });
    setFilterTableData(tempTable);
  }, [toSearch]);
  const deleteRow = async (itemToDelete) => {
    await forfeitMerchant(tableData[itemToDelete].id);
    setTableData(tableData.filter((item, index) => index !== itemToDelete));
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  Merchant Group
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-1">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={() => history.push("/admin/MerchantGroupForm")}
                  >
                    ADD
                  </Button>
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0">User Email</th>
                      <th className="border-0">Merchant Code</th>
                      <th className="border-0">Merchant Name</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => {
                      if (filterTableData.includes(item)) {
                        return;
                      }
                      return (
                        <tr key={index}>
                          <td> {item.uEmail} </td>
                          <td> {item.mCode} </td>
                          <td> {item.mName} </td>
                          <td>
                            <i
                              className="fa fa-edit"
                              style={{ color: "green", cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  "/admin/MerchantGroupForm/?uid=" +
                                    item.uid +
                                    "&email=" +
                                    item.uEmail
                                )
                              }
                            />
                            &nbsp; &nbsp;
                            <i
                              className="fa fa-trash red"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete?"
                                  )
                                )
                                  deleteRow(index);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MerchantGroupList;
