import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import getDeviceData from "../services/device";
import { toast, ToastContainer } from 'react-toastify'; 
import { getDeviceSingleData, updateDevice, addDeviceData } from "../services/device";
import _uniqueId from "lodash/uniqueId";

function addDevice() {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [ClientID, setClientID] = React.useState(null);
  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const [dealers, setDealers] = React.useState([]);

  const [formData, setFormData] = React.useState({
    id: "",
    nameNumber: "",
    macAddress: "",
    status: 0,
  });
  useEffect(() => {
    const params = queryParams.get("id");
    if (params != null) {
      setClientID(params);
    } else {
      setFormData({ ...formData, ["id"]: uniqueID });
    }
    getDeviceData().then( ( response ) => {
      setDealers( response.data );
    } ).catch( ( error ) => {
    } );
  }, [] );

  useEffect(() => {
    if (ClientID == null) return;
    getDeviceSingleData(ClientID).then( ( response ) => {
      setFormData(response.data);
    } ).catch( ( error ) => {
    } );
  }, [ ClientID ] );
  const { id, nameNumber, macAddress ,  status } = formData;

  const validateInput = (name, value) => {
    if (name === "nameNumber" || name === "macAddress") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    return true;
  };

  const handleInputChange = (e) => {
    if (e.target.name == "status") {
      setFormData({ ...formData, [e.target.name]: !status });
      return;
    }

    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
     
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (ClientID) {
      updateDevice( formData ).then( ( response ) => {} ).catch( ( error ) => {} );
    } else {
      addDeviceData( formData ).then( ( response ) => {} ).catch( ( error ) => {} );
    }
    history.push("/admin/device");
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h3" className="text-center m-3 heading">
                  Device
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">Device Name</label>
                        <Form.Control
                          required
                          placeholder="Device Name"
                          type="text"
                          value={nameNumber}
                          name="nameNumber"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">
                          Mac Address
                        </label>
                        <Form.Control
                          required
                          placeholder="Mac Address"
                          type="text"
                          value={macAddress}
                          name="macAddress"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="radio-wrapper">
                        <label className="mt-1 requiredelement">Status</label>
                        <Form.Check
                          inline
                          label="Active"
                          type="Radio"
                          name="status"
                          checked={status}
                          onClick={(e) => {
                            handleInputChange(e);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                        >
                          Save
                        </Button>
                        <Link to="/admin/device">
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{
                              border: "none",
                            }}
                          >
                            Back
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default addDevice;
