import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
//          Importing Services

import checkUser from "../services/auth";
import { getUserData, deleteUser } from "../services/register";
import getAllKassa from "services/kassa";
import { formatDate } from "utils/dateHandler";

//          Importing Services
////-----------------------------///
function kassaFloats() {
  const [toSearch, setToSearch] = React.useState("");
  const [tableData, setTableData] = React.useState([
    { Checked: false, id: "", Email: "", Amount: "", DateTime: "" },
  ]);
  const history = useHistory();
  const [filterTableData, setFilterTableData] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  useEffect(() => {
    setFilterTableData([]);
    getAllKassa()
      .then(function (response) {
        setTableData(response.data.data);
      })
      .catch(function (error) {
        
      });
  }, [refresh]);
  useEffect(() => {
    let tempTable = [];
    tableData.map((item, index) => {
      const email = item?.Email?.toLowerCase();
      if (email.includes(toSearch.toLowerCase())) {
      } else {
        tempTable.push(item);
      }
    });
    setFilterTableData(tempTable);
  }, [toSearch, refresh]);

 

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  Kassa Floats
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{ backgroundColor: "#3AAB7B", border: "none" }}
                    onClick={() => history.push("/admin/addKassaForm")}
                  >
                    ADD
                  </Button>
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Balance</th>
                      <th className="border-0">- Balance Sum</th>
                      <th className="border-0">+ Balance Sum</th>
                      <th className="border-0">Date </th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => {
                      if (filterTableData?.includes(item)) {
                        {
                          setRefresh(!refresh);
                        }
                        return;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              placeholder="Fax"
                              type="checkbox"
                              checked={item.Checked}
                              onChange={() => {
                                let temp = [...tableData];
                                temp[index].Checked = !temp[index].Checked;
                                setTableData(temp);
                              }}
                              style={{ width: "16px" }}
                            ></Form.Control>
                          </td>
                          <td>{item.Email}</td>
                          <td>{item.Amount}</td>
                          <td>{item.NegSum}</td>
                          <td>{item.PosSum}</td>
                          <td>{formatDate(item.DateTime.split("T")[0])}</td>
                          <td>
                            <i
                              className="fa fa-plus"
                              style={{ color: "green", cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  `/admin/addKassaform/?id=${item.id}&op=Add`
                                )
                              }
                            />
                            &nbsp; &nbsp;
                            <i
                              className="fa fa-minus red ml-3"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  `/admin/addKassaform/?id=${item.id}&op=Subtract`
                                )
                              }
                            />
                            &nbsp; &nbsp;&nbsp;
                            <i
                              className="fas fa-history action-childs"
                              style={{ color: "grey", cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  "/admin/kassaHistory/?id=" + item.id
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default kassaFloats;
