import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Multiselect from "multiselect-react-dropdown";
import getAllUsers from "services/user";
import { getTotalSales } from "services/reports";
import { getAllNotMerchants } from "services/user";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import CustomSelect from "utils/CustomSelect";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

export default function TotalSalesReport() {
  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("id");
  const [form, setForm] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [totalSales, setTotalSales] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [filtered, setFiltered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  useEffect(() => {
    getAllNotMerchants()
      .then((response) => {
        const { data } = response;
        const formattedData = data.map((item) => {
          return {
            value: item.id,
            label: item.email,
          };
        });
        setUsers(formattedData);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (!totalSales) return;
    setFiltered(true);
  }, [totalSales]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedUsers.length < 1) {
      alert("List of users is empty");
    } else {
      setIsLoading(true);
      const selectedUsersIds = selectedUsers.map((user) => user.value);
      getTotalSales(selectedUsersIds, date)
        .then((res) => {
          setTotalSales(res.data);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleChange = (users) => {
    setSelectedUsers(users);
    setForm(true);
    setFiltered(false); // Reset the filtered state when users change
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h4" className="text-center m-3 heading">
                  Total Sales of Users
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">Users</label>
                        <CustomSelect
                          options={users}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                            MultiValue,
                            animatedComponents,
                          }}
                          onChange={handleChange}
                          allowSelectAll={true}
                          value={selectedUsers}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">Date</label>
                        <Form.Control
                          required
                          placeholder="abcd"
                          type="date"
                          name="Title"
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="text-center justify-content-center mt-2">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? "Filtering..." : "Filter"}
                        </Button>
                        {filtered && !isLoading && (
                          <Button
                            className="btn-fill res-size"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                          >
                            <CSVLink
                              data={totalSales}
                              filename={"Total_Sales_report.csv"}
                              onClick={() => {
                                setIsDownloading(true);
                                setTimeout(() => setIsDownloading(false), 1000);
                              }}
                            >
                              {isDownloading ? "Downloading..." : "Download"}
                            </CSVLink>
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
