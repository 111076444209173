import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import { Button, Form, Card, Table, Container, Row, Col } from "react-bootstrap";
import { Tooltip } from "../components/Tooltip";
//          Importing Services
import checkUser from "../services/auth";
import getClientList from "../services/client";
import { deleteClient, updateClient, deleteClientImage } from "../services/client";

function BlockedClientList() {
  const [tableData, setTableData] = React.useState([
    {
      Checked: false,
      id: "",
      Code: "",
      FirstName: "",
      LastName: "",
      WorkNo: "",
      ContactNo: "",
      WorksAt: "",
      Email: "",
      FaxNumber: "",
      Status: 0,
      MaxBorrowAmount: "",
      Dealer_id: "",
    },
  ]);
  const history = useHistory();
  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);
  const [newTableData, setNewTableData] = React.useState([]); //filterd data on basis of status

  useEffect(() => {
    // if (!checkUser()) {
    //   history.push("/login");
    //   return;
    // }
    setFilterTableData([]);
    getClientList()
      .then(function (response) {
        setTableData(response.data);
      
      })
      .catch(function (error) {
      });
  }, []);
  useEffect(() => {
    let tempTable = [];
    tableData.map((item) => {
      const Dealer_id = item?.Dealer_id?.toLowerCase() || "";
      const Code = item?.Code?.toLowerCase() || "";
      const LastName = item?.LastName?.toLowerCase() || "";
      const FirstName = item?.FirstName?.toLowerCase() || "";
      const Email = item?.Email?.toLowerCase() || "";
      if ( 
        Dealer_id.includes( toSearch.toLowerCase() ) ||
        Code.includes( toSearch.toLowerCase() ) || 
        LastName.includes( toSearch.toLowerCase() ) || 
        FirstName.includes( toSearch.toLowerCase() ) || 
        Email.includes( toSearch.toLowerCase() ) ) {
      } else {
        tempTable.push( item );
      }
    });
    setFilterTableData(tempTable);
  }, [toSearch]);
  const toggleStatus = (index) => {
    let tempTable = [...tableData];
    tempTable[index].Status = tempTable[index].Status == 2 ? 1 : !tempTable[index].Status;
    updateClient(tempTable[index]).then((response) => { }).catch((error) => { });
    setTableData(tempTable);
  };
  function sortHandler(a, b) {
    if (a.Status > b.Status) {
      return -1;
    }
    if (a.Status < b.Status) {
      return 1;
    }
    return 0;
  }

  const sortedArrayData = tableData;
  sortedArrayData.sort(sortHandler);

  // filtering data on basis of status

  useEffect(() => {
    var newData = tableData?.filter((pendingData) => {
      if (pendingData.Status !== 1) {
        return pendingData;
      }
    })
    setNewTableData(newData);
  }, [tableData]);
  const deleteRow = (itemToDelete) => {
    deleteClientImage(tableData[itemToDelete].id).then((response) => {
      deleteClient(tableData[itemToDelete].id).then((response) => { }).catch((error) => { });
    }).catch((error) => {
    });
    setTableData(tableData.filter((item, index) => index !== itemToDelete));
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  LISTA di Kliente (Blocked)
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={() => history.push("/admin/ClientForm")}
                  >
                    ADD
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    variant="info"
                    style={{
                      border: "none",
                    }}
                    onClick={() => {
                      setTableData(
                        tableData.map((item) => {
                          if (item.Checked === true) {
                            item.Status = 1;
                            updateClient(item).then((response) => { }).catch((error) => { });
                            item.Checked = false;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    Active
                  </Button>
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0">Code</th>
                      <th className="border-0">Nomber</th>
                      <th className="border-0">Fam</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Celullar</th>
                      <th className="border-0">Ta traha na</th>
                      <th className="border-0">Fax</th>
                      <th className="border-0">Kredito Maksimo</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => {
                      if (filterTableData.includes(item)) {
                        return;
                      }
                      if (item.Status == 1) return
                      if (item.Status != 2) {
                      return (
                        <tr
                          key={index}
                        >
                          <td>
                            {" "}
                            <Form.Control
                              placeholder="Fax"
                              type="checkbox"
                              checked={item.Checked}
                              onChange={() => {
                                let temp = [...tableData];
                                temp[index].Checked = !temp[index].Checked;
                                setTableData(temp);
                              }}
                              style={{ width: "16px" }}
                            ></Form.Control>
                          </td>
                          <td> {item.Code} </td>
                          <td> {item.FirstName} </td>
                          <td> {item.LastName} </td>
                          <td> {item.Email} </td>
                          <td> {item.ContactNo} </td>
                          <td> {item.WorksAt} </td>
                          <td> {item.FaxNumber} </td>
                          <td> {item.MaxBorrowAmount} </td>
                          <td>
                            {" "}
                            {item.Status == 1 ? (
                              <Button
                                onClick={() => toggleStatus(index)}
                                style={{ border: "none" }}
                              >
                                <i
                                  className="fa fa-toggle-on"
                                  style={{
                                    color: "green",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                />
                              </Button>
                            ) : item.Status == 2 ? (
                              <Button
                                onClick={() => toggleStatus(index)}
                                style={{ border: "none" }}
                              >
                                <i
                                  className="fa fa-hourglass"
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => toggleStatus(index)}
                                style={{ border: "none" }}
                              >
                                <i
                                  className="fa fa-ban"
                                  style={{
                                    color: "red",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                />
                              </Button>
                            )}
                          </td>
                          <td className="actions-styling">
                            <Tooltip position="bottom" message="Issuance History">
                              <i
                                className="fas fa-history action-childs" style={ { color: "black", cursor: "pointer", color: "gray" } }
                                onClick={ () => history.push( "/admin/IssuanceHistory/?id=" + item.id ) }
                              />
                            </Tooltip>
                            <Tooltip position="bottom" message="Transaction History">
                              <i 
                                className="fas fa-money-bill-alt action-childs" style={ { color: "black", cursor: "pointer", color: "gray" } }
                                onClick={ () => history.push( "/admin/TransactionHistory/?id=" + item.id ) }
                              />
                            </Tooltip>
                            <Tooltip position="bottom" message="Memberships">
                              <i 
                                className="fas fa-address-card action-childs" style={ { color: "black", cursor: "pointer", color: "gray" } }
                                onClick={ () => history.push( "/admin/MembershipHistory/?id=" + item.id ) }
                              />
                            </Tooltip>
                            <i
                              onClick={() =>
                                history.push("/admin/ClientForm/?id=" + item.id)
                              }
                              className="fa fa-edit action-childs"
                              style={{ color: "green", cursor: "pointer" }}
                            />
                            <i
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete?"
                                  )
                                )
                                  deleteRow(index);
                              }}
                              className="fa fa-trash action-childs"
                              style={{ color: "red", cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                      }
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BlockedClientList;
