import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export function addClient(formData) {
  return axios.post(address + "/api/public/clients/upsertClient", formData);
}
export function getActiveClientList() {
  return axios.get(address + "/api/public/clients/getActiveClientList");
}

export function updateClient(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(address + "/api/clients/upsertClient", formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getNextNM_Id() {
  return axios.get(address + "/api/public/clients/getNextNM_Id");
}

export function addClientImage(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/clientProfilePicture/createImage",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function addSalarySlips(formData) {
  return axios.post(address + "/api/css/add", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function addBankStatement(formData) {
  return axios.post(address + "/api/cbs/add", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getImageByClientId(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address + "/api/clientProfilePicture/getImageByClientId/" + id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
export function getSalarySlipByClientId(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/css/getFilesByClientId/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getBankStatementByClientId(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/cbs/getFilesByClientId/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
