import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Image,
  Note,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LogoImage from "../assets/img/logo/merpol_logo.png";
import { getCurrentDate } from "utils/dateHandler";

class PdfGenKassa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: ["Initial Item"],
      inputValue: "",
    };
  }

  handelChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  addItem = (event) => {
    event.preventDefault();
    const newItems = this.state.items;
    newItems.push(this.state.inputValue);
    this.setState({
      items: newItems,
      inputValue: "",
    });
  };

  render() {
    // const klienteArray = [];

    const styles = StyleSheet.create({
      parent: {
        display: "flex",
        flexDirection: "column",
        padding: "50px",
        paddingBottom: "20px",
        width: "100%",
      },
      table: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: "20px",
        paddingBottom: "10px",
      },
      tablePaymentPeriod: {
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: "20px",
        paddingBottom: "10px",
      },
      paymentPeriod: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      textField: { paddingBottom: "20px", fontSize: "22px" },
      paymentTextField: { paddingBottom: "20px", fontSize: "18px" },
      textFieldKliente: {
        width: "100%",
        paddingBottom: "20px",
        fontSize: "16px",
      },
      img: { width: "60%", margin: "0 auto" },
      mainHeading: {
        paddingBottom: "30px",
        fontStyle: "bold",
        fontSize: "24px",
        fontWeight: "bold",
        borderBottom: "1px solid #264147",
      },
      bottomText: { paddingTop: "50px", fontSize: "18px", textAlign: "center" },
      signatureField: {
        paddingTop: "0px",
        paddingRight: "30px",
        textAlign: "right",
      },
      signatureText: {
        paddingTop: "30px",
        fontStyle: "bold",
        fontSize: "20px",
      },
    });

    const { userName } = this.props;

    // const { userDetails } = this.props;
    // var i = 0;
    // userDetails.forEach(element => {
    //     userDetails[i] = element.key + ": " + element.value;
    //     i++;
    // });

    const { kassaTransaction } = this.props;
    // this.props.paybackPeriodHistory(paybackPeriod)
    const pdf = (
      <Document>
        <Page wrap>
          <View>
            <Image style={styles.img} src={LogoImage} />
            <View style={styles.parent}>
              <Text style={{ margin: "20px" }}>
                {" "}
                Transaction Made By: {userName.value}
              </Text>
              {/* <Text style={styles.mainHeading}>User Details:</Text>
                            <View style={styles.table}>
                                {userDetails.map(item => (
                                    <Text style={styles.textFieldKliente}>{item}</Text>
                                ))}
                            </View> */}
              <View style={styles.paymentPeriod}>
                <Text style={styles.textField}>Kassa Floats</Text>
              </View>
              <View>
                {kassaTransaction && (
                  <View>
                    <Text style={styles.mainHeading}>Kassa Float Details:</Text>
                    <View style={styles.tablePaymentPeriod}>
                      <Text style={styles.textField}>
                        Date: {kassaTransaction.date.split("T")[0]}
                      </Text>
                      {/* <Text style={styles.textField}>Balance Before: {kassaTransaction.amountBefore}</Text> */}
                      <Text style={styles.textField}>
                        Amount: {kassaTransaction.amountTransaction}{" "}
                      </Text>
                      {/* <Text style={styles.textField}>Balance After: {kassaTransaction.amountAfter}</Text> */}
                      <Text style={styles.textField}>
                        Description: {kassaTransaction.description}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </View>

            <View style={styles.signatureField}>
              <Text>__________________</Text>
              <Text style={styles.signatureText}>Signature</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
    return (
      <div style={{ textAlign: "center" }}>
        <PDFDownloadLink
          document={pdf}
          fileName={"Quote" + getCurrentDate().getTime() + ".pdf"}
        >
          {({ blob, url, loading, error }) => (
            // loading ? "Loading . . ." :
            <i
              class="fa fa-download"
              aria-hidden="true"
              style={{ color: "#3aab7b" }}
            ></i>
          )}
        </PDFDownloadLink>
      </div>
    );
  }
}

export default PdfGenKassa;
