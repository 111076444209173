import { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { updateClient } from "services/client";
import { getClientData } from "services/client";
import { getPrepaidID } from "services/client";
import { addClient } from "services/client";
import { toast, ToastContainer } from "react-toastify";

export default function ClientPrepaidForm() {
  const history = useHistory();
  const [workNoOption, setWorkNoOption] = useState("+5999");
  const [contactNoOption, setContactNoOption] = useState("+5999");
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };

  const [formData, setFormData] = useState({
    id: null,
    Code: "",
    Date: new Date(),
    FirstName: "",
    LastName: "",
    Status: 2,
    address: "",
    idCard: "",
    WorkNo: "",
    ContactNo: "",
    Email: "",
    ExpiryDate: "",
    RegistrationDate: getCurrentDate(),
  });

  const {
    id,
    Code,
    FirstName,
    LastName,
    idCard,
    WorkNo,
    ContactNo,
    Email,
    address,
    ExpiryDate,
    RegistrationDate,
  } = formData;

  useEffect(async () => {
    const { clientID } = history.location.state || { clientID: null };
    let id = null;
    if (clientID) {
      id = clientID;
      setEditMode(true);
    } else {
      const response = await getPrepaidID();
      id = response.data.id;
    }
    setFormData({ ...formData, Code: id, id });
  }, []);

  useEffect(async () => {
    if (!editMode) return;
    const prepaidID = formData.id;
    let { data } = await getClientData(prepaidID);
    setFormData({ ...formData, ...data });
  }, [editMode]);

  const validateInput = (name, value) => {
    if (name === "Code" || name === "WorksAt") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    if (name === "FirstName" || name === "LastName") {
      let pattern = new RegExp("^[a-zA-Z ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only alphabets and spaces";
    }
    if (
      name === "WorkNo" ||
      name === "FaxNumber" ||
      name === "ContactNo" ||
      name === "ChildrenCount" ||
      name === "phoneNumber" ||
      name == "idCard"
    ) {
      let pattern = new RegExp("^[0-9 +]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    return true;
  };

  const handleInputChange = (e) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateEmail = (e) => {
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(e.target.value)) {
      return true;
    }
    toast.warning("invalidEmail", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setFormData({ ...formData, Email: "" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      await updateClient(formData);
    } else {
      await addClient(formData, 4);
    }
    history.goBack();
    return;
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" className="main-container">
            <Card className="form-wrapper mt-4" style={{ border: "none" }}>
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}></Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit} style={{ border: "none" }}>
                  <Row className="padding-class">
                    {/* Code Field */}
                    <Col sm="12" lg="12">
                      <Form.Group>
                        <label className="requiredelement">Code</label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="text"
                          size="lg"
                          value={Code}
                          disabled
                          name="Code"
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {/* First Name Field */}
                    <Col sm="12" md="6" lg="6">
                      <Form.Group>
                        <label className="requiredelement">Nomber</label>
                        <Form.Control
                          required
                          placeholder="Frank"
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                          type="text"
                          value={FirstName}
                          name="FirstName"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Last Name Field */}
                    <Col sm="12" md="6" lg="6">
                      <Form.Group>
                        <label
                          htmlFor="exampleLastName"
                          className="requiredelement"
                        >
                          Fam
                        </label>
                        <Form.Control
                          required
                          placeholder="Semper"
                          type="text"
                          value={LastName}
                          name="LastName"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label className="requiredelement">Adres</label>
                        <Form.Control
                          required
                          placeholder="Adres"
                          type="text"
                          value={address}
                          name="address"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* ID Card Field */}
                    <Col sm="12" md="6">
                      <Form.Group>
                        <label className="requiredelement">Sedula</label>
                        <Form.Control
                          required
                          placeholder=""
                          type="text"
                          value={idCard}
                          name="idCard"
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                          maxLength={"10"}
                          minLength={"10"}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Expiry Date Field */}
                    <Col sm="12" md="6">
                      <Form.Group>
                        <label className="requiredelement">
                          Fecha di Vensementu
                        </label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="date"
                          value={ExpiryDate}
                          name="ExpiryDate"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", padding: "20px 10px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* Contact Number Option Field */}
                    <Col sm="12" md="6" lg="4">
                      <Form.Group>
                        <label className="requiredelement"> Celullar</label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          required
                          value={contactNoOption}
                          name="contactNoOption"
                          onChange={(e) => {
                            setContactNoOption(e.target.value);
                          }}
                          style={{ fontSize: "14px", height: "41px" }}
                        >
                          <option value={"+5999"}> +5999</option>
                          <option value={"+599"}> +599</option>
                          <option value={"+297"}> +297</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Contact Number Field */}
                    <Col sm="12" md="6" lg="8">
                      <Form.Group>
                        <label> &nbsp;</label>
                        <Form.Control
                          required
                          placeholder="042"
                          type="text"
                          value={ContactNo}
                          name="ContactNo"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", height: "41px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="padding-class">
                    {/* Work Number Option Field */}
                    <Col sm="12" md="6" lg="4">
                      <Form.Group>
                        <label>Telefon</label>
                        <Form.Control
                          as="select"
                          defaultValue=""
                          required
                          value={workNoOption}
                          name="workNoOption"
                          onChange={(e) => {
                            setWorkNoOption(e.target.value);
                          }}
                          style={{ fontSize: "14px", height: "41px" }}
                        >
                          <option value={"+5999"}> +5999</option>
                          <option value={"+599"}> +599</option>
                          <option value={"+297"}> +297</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* Work Number Field */}
                    <Col sm="12" md="6" lg="8">
                      <Form.Group>
                        <label>&nbsp;</label>
                        <Form.Control
                          placeholder="00-0000-00"
                          type="text"
                          value={WorkNo}
                          name="WorkNo"
                          className="placeholder-Class"
                          onChange={(e) => handleInputChange(e)}
                          style={{ fontSize: "14px", height: "41px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Email Field */}
                  <Row className="padding-class">
                    <Col sm="12">
                      <Form.Group>
                        <label>Email</label>
                        <Form.Control
                          placeholder="Email"
                          type="text"
                          value={Email}
                          name="Email"
                          className="placeholder-Class"
                          onBlur={validateEmail}
                          onChange={(e) => handleInputChange(e)}
                          style={{ padding: "20px 10px", fontSize: "14px" }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="12">
                      <Form.Group>
                        <label>fecha di inskripshon</label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="date"
                          value={RegistrationDate}
                          name="RegistrationDatek"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="text-center mt-2">
                    <Col sm="12">
                      <div className="button-wrapper">
                        <Button
                          className="add-btn res-size"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <div>
                              {" "}
                              <i class="fa fa-spinner" aria-hidden="true">
                                &nbsp;
                              </i>{" "}
                              Saving...
                            </div>
                          ) : (
                            <div>Save</div>
                          )}
                        </Button>
                        <Link to="/admin/ClientList">
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{ border: "none" }}
                          >
                            Back
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}
