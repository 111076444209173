import address from "./address";

import axios from "axios";

const getNewToken = async (refreshToken, token) => {
  try {
    const { data } = await axios.post(address + "/api/auth/refresh-session", {
      refreshToken: refreshToken,
    });
    const newToken = data.data["access_token"];
    const newRefreshToken = data.data["refresh_token"];
    const expiryDate = data.data["expiryDate"];

    if (token && refreshToken) {
      return { newToken, newRefreshToken, expiryDate };
    }
  } catch (error) {
    return null;
  }
};
const isTokenValid = async (token) => {
  try {
    const { data } = await axios.get(address + "/api/auth/ifValid", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export default function checkUser() {
  const storage = window.localStorage;
  const token = storage.getItem("token");
  const refreshToken = storage.getItem("refreshToken");
  const expiryDate = storage.getItem("expiryDate");
  if (token) {
    if (Date.now() < expiryDate) {
      return true;
    } else {
      storage.removeItem("token");
      storage.removeItem("refreshToken");
      storage.removeItem("expiryDate");
      storage.removeItem("role");
      storage.removeItem("id");
      // const data = await getNewToken(refreshToken, token)
      // if (data == null)
      //     return false
      // storage.setItem("token", data.newToken)
      // storage.setItem("refreshToken", data.newRefreshToken)
      // storage.setItem('expiryDate', data.expiryDate)
      // return true
    }
  }
  return false;
}
export function getToken() {
  const storage = window.localStorage;
  return storage.getItem("token");
}

export async function login(formData) {
  // requires email and password only
  // role 0 is admin
  // role 1 is employee
  // role 2 is merchant
  try {
    const { data } = await axios.post(address + "/api/auth/loginWeb", formData);
    if (data?.role) {
      return data;
    } else {
      if (!data.qrCodeDataUrl) {
        const { id, email } = data.data;
        const storage = window.localStorage;
        storage.setItem("id", id);
        storage.setItem("email", email);

        // If needed, you can redirect the user to OTP verification at this point
        // return some flag indicating OTP is required
      } else {
        // If the user is new and needs to set up 2FA (QR code provided)
        return data;
      }

      console.log("===data1", data);
      return data;
    } // return the data object in both cases
  } catch (err) {
    return err.response.data; // return the error response if something goes wrong
  }
}

export async function verifyOtp(otp) {
  // requires email and password only
  // role 0 is admin
  // role 1 is employee
  // role 2 is merchant
  // role 3 is specialAdmin
  const id = window.localStorage.getItem("id");
  const payload = {
    otp,
    userId: id,
  };
  try {
    const response = await axios.post(
      address + "/api/auth/validateOtp",
      payload
    );
    const accessToken = response.data.data["accessToken"];
    const refreshToken = response.data.data["refreshToken"];
    const role = response.data.data["role"];
    const expiryDate = response.data.data["expiryDate"];
    if (accessToken) {
      const storage = window.localStorage;
      storage.setItem("token", accessToken);
      storage.setItem("refreshToken", refreshToken);
      storage.setItem("role", role);
      storage.setItem("expiryDate", expiryDate);
    }
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function register(formData) {
  const { data } = await axios.post(address + "/api/auth/signup", formData);
}

export function getRole() {
  const storage = window.localStorage;
  return storage.getItem("role");
}
export function getUserId() {
  return window.localStorage.getItem("id");
}
