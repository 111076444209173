import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getRemainingThisYearPayBacksList } from "services/client";
import Grid from "@mui/material/Grid";
import { Tooltip } from "../components/Tooltip";
export default function PaybackPeriodHistoryList() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const history = useHistory();

  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);
  // Load Paybacks From DB

  useEffect(() => {
    setIsLoading(true);
    setFilterTableData([]);
    getRemainingThisYearPayBacksList()
      .then((response) => {
        setTableData(response.data.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Search Paybacks
  useEffect(() => {
    let tempTable = [];

    tableData.map((item) => {
      if (
        item.code.includes(toSearch.toLowerCase()) ||
        item.payBackDate.includes(toSearch.toLocaleLowerCase()) ||
        item.Email.includes(toSearch.toLowerCase()) ||
        item.ContactNo.includes(toSearch.toLowerCase())
      ) {
      } else {
        tempTable.push(item);
      }
    });

    setFilterTableData(tempTable);
  }, [toSearch]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card.Title as="h3" className="heading">
                      Remaining PayBacks
                    </Card.Title>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <div
                  style={{
                    overflowX: "scroll",
                    overflowY: "hidden",
                  }}
                >
                  <Table className="table-hover" responsive>
                    <thead>
                      <tr>
                        <th className="border-0">Code</th>
                        <th
                          style={{ textAlign: "center" }}
                          className="border-0"
                        >
                          FullName
                        </th>

                        <th
                          style={{ textAlign: "center" }}
                          className="border-0"
                        >
                          Email
                        </th>
                        <th className="border-0">phoneNumber</th>

                        <th className="border-0">address</th>
                        <th className="border-0">Actions</th>
                        <th
                          style={{ whiteSpace: "nowrap" }}
                          className="border-0"
                        >
                          Issuance Date
                        </th>
                        <th
                          style={{ whiteSpace: "nowrap" }}
                          className="border-0"
                        >
                          date of PayBack
                        </th>
                        <th className="border-0">Amount</th>
                        <th className="border-0">AmountPaidByClient</th>
                        <th className="border-0">Remaining Amount</th>
                        <th className="border-0"></th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          minHeight: "100px",
                        }}
                      >
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    ) : (
                      <tbody>
                        {tableData
                          .filter((item) => item.dealerId !== "D-250")
                          .map((item, index) => {
                            if (filterTableData.includes(item)) {
                              return null;
                            }

                            return (
                              <tr key={index}>
                                <td>{item.code}</td>
                                <td
                                  style={{ whiteSpace: "nowrap" }}
                                >{`${item.FirstName} ${item.LastName}`}</td>

                                <td>{item.Email}</td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {item.ContactNo}{" "}
                                </td>

                                <td>{item.address} </td>
                                <td className="actions-styling">
                                  <Tooltip
                                    position="bottom"
                                    message="Transaction History"
                                  >
                                    <i
                                      className="fas fa-money-bill-alt action-childs"
                                      style={{
                                        color: "black",
                                        cursor: "pointer",
                                        color: "gray",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          `/admin/currentYearTransactionHistory/?id=${item.code}&issuanceId=${item.id}`
                                        )
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    position="bottom"
                                    message="Issuance History"
                                  >
                                    <i
                                      className="fas fa-history action-childs"
                                      style={{
                                        color: "black",
                                        cursor: "pointer",
                                        color: "gray",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          "/admin/IssuanceHistory/?id=" +
                                            item.code
                                        )
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip position="bottom" message="Memberships">
                                <i
                                  className="fas fa-address-card action-childs"
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    color: "gray",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      "/admin/MembershipHistory/?id=" + item.code
                                    )
                                  }
                                />
                              </Tooltip>
                                </td>

                                <td style={{ whiteSpace: "nowrap" }}>
                                  {item.issuanceDate}
                                </td>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {item.payBackDate}
                                </td>
                                <td>{item.Amount}</td>
                                <td>{item.amountPaidByClient}</td>

                                <td>{item.remaining}</td>
                                <td></td>
                              </tr>
                            );
                          })}
                      </tbody>
                    )}
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
