import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getTransactionHistory } from "services/issuanceHistory";
import { CSVLink } from "react-csv";
import Multiselect from "multiselect-react-dropdown";
import { getAllIssuanceHistory } from "services/issuanceHistory";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import CustomSelect from "../utils/CustomSelect";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

export default function IssuanceHistoryReport() {
  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("id");
  const [form, setForm] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [issuanceHistory, setIssuanceHistory] = useState([{}]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [filter, setFilter] = useState({
    clients: null,
    dateFrom: null,
    dateTo: null,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [filtering, setFiltering] = useState(false); // New state for filtering status

  useEffect(() => {
    setIsLoading(true);
    getAllIssuanceHistory()
      .then((response) => {
        const { data } = response;
        const formattedData = data.map((item) => {
          return {
            value: item.Client_id,
            label: item.Client_id,
          };
        });
        setIssuanceHistory(data);
        setOptions(formattedData);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const filterData = () => {
    setFiltering(true);

    const toFilter = filter.clients.map((item) => item.value);

    const filteredData = issuanceHistory.filter((item, idx) => {
      if (item.DateTime) {
        const tDate = new Date(item.DateTime.split("T")[0]);
        const dateTo = new Date(filter.dateTo);
        const dateFrom = new Date(filter.dateFrom);
        if (
          tDate >= dateFrom &&
          tDate <= dateTo &&
          toFilter.indexOf(item.Client_id) > -1
        ) {
          return true;
        }
      }
      return false;
    });

    setTimeout(() => {
        setFilteredData(filteredData)
      setFiltering(false);
      setForm(false);
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (filter.clients.length < 1) {
      alert("Lista di Kliente is empty");
    } else {
      setForm(!form);
      filterData();
    }
  };

  const handleChange = (item) => {
    setFilter({ ...filter, clients: item });
    setForm(true);
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h4" className="text-center m-3 heading">
                  Issuance history Filter
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      {isLoading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          {" "}
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      ) : (
                        <>
                          <Form.Group>
                            <label className="requiredelement">Kliente</label>
                            <CustomSelect
                              options={options}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                                MultiValue,
                                animatedComponents,
                              }}
                              onChange={handleChange}
                              allowSelectAll={true}
                              value={filter.clients}
                            />
                          </Form.Group>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label className="requiredelement">Date From</label>
                        <Form.Control
                          required
                          placeholder="abcd"
                          type="date"
                          name="Title"
                          onChange={(e) => {
                            setForm(true);
                            setFilter({
                              ...filter,
                              dateFrom: e.target.value,
                            });
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label className="requiredelement">Date To</label>
                        <Form.Control
                          required
                          placeholder="abcd"
                          type="date"
                          name="Title"
                          onChange={(e) => {
                            setForm(true);
                            setFilter({ ...filter, dateTo: e.target.value });
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="text-center justify-content-center mt-2">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                          disabled={filtering} // Disable the button while filtering
                        >
                          {filtering ? "Filtering..." : "Filter"}
                        </Button>
                        {!form && (
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                            disabled={isDownloading ? true : false}
                          >
                            <CSVLink
                              data={filteredData}
                              filename={"Issuance_report.csv"}
                              onClick={() => {
                                setIsDownloading(true);
                                setTimeout(() => setIsDownloading(false), 1000);
                              }}
                            >
                              {isDownloading ? "Downloading..." : "Download"}
                            </CSVLink>
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
