import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from 'react-router-dom';
//          Importing Components
import { Button, Form, Card, Table, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import getMerchantList from "../services/merchant";
import getMerchantTypeList from "../services/merchantType";
import { deleteMerchantType, deleteMerchantTypeDiscountByMerchantType_id } from "../services/merchantType";

function MerchantTypeList() {
  const [tableData, setTableData] = React.useState([{
    Checked: false,
    id: "",
    Title: ""
  }])
  const history = useHistory();
  const [status, setStatus] = React.useState(false)
  const [toSearch, setToSearch] = React.useState("")
  const [filterTableData, setFilterTableData] = React.useState([])
  useEffect( () => {
    setFilterTableData( [] );
    getMerchantTypeList().then( ( response ) => {
      setTableData( response.data );
    } ).catch( ( error ) => {
    } );
  }, [] );
  useEffect( () => {
    let tempTable = []
    tableData.map( ( item, index ) => {
      const Title = item?.Title?.toLowerCase();
      if ( Title.includes( toSearch.toLowerCase() ) ) {}
      else { tempTable.push( item ) }
    } );
    setFilterTableData( tempTable );
  }, [ toSearch ] );
  const toggleStatus = (index) => {
    let tempTable = [...tableData]
    tempTable[index].Status = !tempTable[index].Status
    setTableData(tempTable)
  }
  const deleteRow = ( itemToDelete ) => {
    getMerchantList().then( ( rsp ) => {
      const result = rsp.data.map( ( item ) => {
        if ( item.MerchantType_id == tableData[ itemToDelete ].id ) {
          alert( "Cannot Delete Merchant has it in use !!!" );
          return true;
        }
        return false;
      } );
      for ( let i = 0; i < result.length; i++ ) {
        if ( result[ i ] == true ) { return }
      }
      deleteMerchantTypeDiscountByMerchantType_id( tableData[ itemToDelete ].id ).then( ( response ) => {
        deleteMerchantType( tableData[ itemToDelete ].id ).then( ( response ) => {} ).catch( ( error ) => {} );
      } ).catch( ( error ) => {
      } );
    } ).catch( ( error ) => {
    } );
    setTableData( tableData.filter( ( item, index ) => index !== itemToDelete ) );
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">Merchant Type</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={() => history.push("/admin/MerchantTypeForm")}
                  >
                    ADD
                  </Button>
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0">Title</th>
                      <th className="border-0">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => {
                      if (filterTableData.includes(item)) {
                        return;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            {" "}
                            <Form.Control
                              placeholder="Fax"
                              type="checkbox"
                              checked={item.Checked}
                              onChange={() => {
                                let temp = [...tableData];
                                temp[index].Checked = !temp[index].Checked;
                                setTableData(temp);
                              }}
                              style={{ width: "16px" }}
                            ></Form.Control>
                          </td>
                          <td> {item.Title} </td>

                          <td>
                            <i
                              className="fa fa-edit"
                              style={{ color: "green", cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  "/admin/MerchantTypeForm/?id=" + item.id
                                )
                              }
                            />
                            &nbsp; &nbsp;
                            <i
                              className="fa fa-trash red"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete?"
                                  )
                                )
                                  deleteRow(index);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MerchantTypeList;
