import React, { useState, useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { Link, useHistory } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import addRegisterr from "../services/register";
import {
  getUserByEmail,
  getUserSingleData,
  setUserRole,
  updateUser,
} from "../services/register";
import _uniqueId from "lodash/uniqueId";

const addRegister = () => {
  const [inputregisterData, setInputRegisterData] = useState({
    id: "",
    email: "",
    password: "",
  });
  const [oldPassowrd, setOldPassword] = useState("");
  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const history = useHistory();
  const [role, setRole] = React.useState("0");
  const queryParams = new URLSearchParams(window.location.search);
  const [params, setParams] = useState(queryParams.get("id"));

  useEffect(() => {
    if (params) {
      getUserSingleData(params)
        .then((response) => {
          setRole(response.data.isAdmin);
          setOldPassword(response.data.password);
          response.data.password = "";
          setInputRegisterData(response.data);
        })
        .catch((error) => {});
    }
    setInputRegisterData({ ...inputregisterData, ["id"]: uniqueID });
  }, []);

  const onChnageHandler = (e) => {
    setInputRegisterData({
      ...inputregisterData,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (params) {
      if (inputregisterData.password == "") {
        updateUser({ ...inputregisterData, ["password"]: oldPassowrd })
          .then((response) => {
            getUserByEmail(inputregisterData.email)
              .then((res) => {
                setUserRole(res.data.id, { isAdmin: JSON.parse(role) })
                  .then((rs) => {
                    history.push("/admin/register");
                  })
                  .catch((er) => {});
              })
              .catch((err) => {});
          })
          .catch((error) => {});
      } else {
        updateUser(inputregisterData)
          .then((response) => {
            getUserByEmail(inputregisterData.email)
              .then((res) => {
                setUserRole(res.data.id, { isAdmin: JSON.parse(role) })
                  .then((rs) => {
                    history.push("/admin/register");
                  })
                  .catch((er) => {});
              })
              .catch((err) => {});
          })
          .catch((error) => {});
      }
    } else {
      addRegisterr(inputregisterData)
        .then((response) => {
          getUserByEmail(inputregisterData.email)
            .then((res) => {
              setUserRole(res.data.id, { isAdmin: JSON.parse(role) })
                .then((rs) => {
                  history.push("/admin/register");
                })
                .catch((er) => {});
            })
            .catch((err) => {});
        })
        .catch((error) => {});
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h3" className="text-center m-3 heading">
                Usuarionan
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={onSubmitHandler}>
                <Row>
                  <Col sm="12" md="12">
                    <Form.Group>
                      <label className="requiredelement">Email</label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={inputregisterData.email}
                        onChange={onChnageHandler}
                        pattern="[a-zA-Z0-9_.-]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z]{1,}"
                        required
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="12">
                    <Form.Group>
                      <label className="requiredelement">Password</label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={inputregisterData.password}
                        onChange={onChnageHandler}
                        // pattern="(?=.*[0-9])[A-Za-z0-9!@#$%^&*]{6,15}"
                        required
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <Form.Check
                      inline
                      label="Admin"
                      type="Radio"
                      name="RecievedCreditInPast"
                      value={"0"}
                      checked={role == "0" ? true : false}
                      onClick={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="Employee"
                      type="Radio"
                      name="RecievedCreditInPast"
                      value={"1"}
                      className="ml-3"
                      checked={role == "1" ? true : false}
                      onClick={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="Merchant"
                      type="Radio"
                      name="RecievedCreditInPast"
                      value={"2"}
                      className="mr-4"
                      checked={role == "2" ? true : false}
                      onClick={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <Form.Check
                      inline
                      label="Special Admin"
                      type="Radio"
                      name="RecievedCreditInPast"
                      value={"3"}
                      checked={role == "3" ? true : false}
                      onClick={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="incasso"
                      type="Radio"
                      style={{ marginRight: "43px" }}
                      name="RecievedCreditInPast"
                      value={"4"}
                      checked={role == "4" ? true : false}
                      onClick={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="Super Admin"
                      type="Radio"
                      name="RecievedCreditInPast"
                      value={"5"}
                      checked={role == "5" ? true : false}
                      onClick={(e) => {
                        setRole(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <Row className="text-center mt-3">
                  <Col md="12">
                    <div className="button-wrapper">
                      <Button
                        className="btn-fill res-size "
                        type="submit"
                        style={{
                          backgroundColor: "#3AAB7B",
                          border: "none",
                        }}
                      >
                        Save
                      </Button>
                      <Link to="/admin/register">
                        <Button
                          className="btn-fill res-size"
                          variant="danger"
                          style={{
                            border: "none",
                          }}
                        >
                          Back
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>

                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default addRegister;
