import React, { useEffect } from "react";
//          Importing CSS Files
import "../../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { upsertIssuanceHistory } from "services/issuanceHistory";
import { getissuancehistoryByID } from "services/issuanceHistory";
import { getCurrentDate } from "utils/dateHandler";
import addBalanceHistory from "services/balanceHistory";
import "../view.css";
import { updateClientRequestStatus } from "services/clientRequest";

export default function AddBalance({ payload }) {
  const history = useHistory();
  const [data, setData] = React.useState({});
  const [amount, setAmount] = React.useState(null);
  const [requestData, setRequestData] = React.useState(
    JSON.parse(payload.requestData)
  );

  useEffect(() => {
    setAmount(requestData.amount || 0);
    getissuancehistoryByID(requestData.issuanceHistoryID)
      .then((resp) => {
        if (!resp.data) {
          alert("no Issuance Found");
          history.goBack();
          return;
        }
        setData(resp.data);
      })
      .catch((err) => {});
  }, []);
  const handleReject = async () => {
    await updateClientRequestStatus(0, payload.id);
    history.goBack();
    return;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const currentDate = getCurrentDate();
    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();

    const date =
      currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
    const time =
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds();
    const amountToAdd = parseFloat(data.Amount) + parseFloat(amount);
    const balanceToAdd = parseFloat(data.Balance) + parseFloat(amount);
    const formData = {
      ...data,
      Amount: amountToAdd,
      Balance: balanceToAdd,
      DateDeposit: date + " " + time,
    };
    const userId = window.localStorage.getItem("id") || null;
    const balanceHistory = {
      amount: amount,
      dateDeposit: date + " " + time,
      issuanceHistoryID: data.id || null,
      handledByUserId: userId,
    };

    upsertIssuanceHistory(formData)
      .then(async (resp) => {
        await addBalanceHistory(balanceHistory);
        await updateClientRequestStatus(1, payload.id);
        history.goBack();
      })
      .catch((err) => {});
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h3" className="text-center m-3 heading">
                  Add Balance
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">Amount</label>
                        <Form.Control
                          required
                          placeholder="Amount"
                          type="number"
                          value={amount}
                          name="amount"
                          step={0.01}

                          //onInput={(e)=>validateInput(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">
                          CurrentBalance
                        </label>
                        <Form.Control
                          required
                          placeholder="Amount"
                          type="text"
                          value={data.Amount}
                          disabled
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                          disabled={amount ? false : true}
                        >
                          Accept
                        </Button>

                        <Button
                          className="btn-fill res-size"
                          variant="danger"
                          style={{
                            border: "none",
                          }}
                          onClick={handleReject}
                        >
                          Reject
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
