import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
//          Importing Services
import checkUser from "../services/auth";
import { getUserData, deleteUser } from "../services/register";

function Register() {
  const role = JSON.parse(localStorage.getItem("role"));
  const [tableData, setTableData] = React.useState([
    { Checked: false, id: "", email: "", password: "", isAdmin: "" },
  ]);
  const history = useHistory();
  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);

  useEffect(() => {
    // if (!checkUser()) {
    //   history.push("/login");
    //   return;
    // }
    setFilterTableData([]);
    getUserData()
      .then(function (response) {
        var sorted = response.data.sort(SortArray);
        setTableData(sorted);
      })
      .catch(function (error) {});
  }, []);
  const SortArray = (x, y) => {
    if (x.email < y.email) {
      return -1;
    }
    if (x.email > y.email) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    let tempTable = [];
    tableData.map((item, index) => {
      const email = item?.email?.toLowerCase();
      if (email.includes(toSearch.toLowerCase())) {
      } else {
        tempTable.push(item);
      }
    });
    setFilterTableData(tempTable);
  }, [toSearch]);
  const deleteRow = (itemToDelete) => {
    deleteUser(tableData[itemToDelete].id)
      .then((response) => {})
      .catch((error) => {});
    setTableData(tableData.filter((item, index) => index !== itemToDelete));
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  Usuarionans
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {role !== 3 && (
                  <div className="top-btn-wrapper">
                    <Button
                      className="btn-fill res-size"
                      type="submit"
                      style={{
                        backgroundColor: "#3AAB7B",
                        border: "none",
                      }}
                      onClick={() => history.push("/admin/addregister")}
                    >
                      ADD
                    </Button>
                  </div>
                )}

                <Col sm="12" md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Role</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => {
                      if (filterTableData?.includes(item)) {
                        return;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              placeholder="Fax"
                              type="checkbox"
                              checked={item.Checked}
                              onChange={() => {
                                let temp = [...tableData];
                                temp[index].Checked = !temp[index].Checked;
                                setTableData(temp);
                              }}
                              style={{ width: "16px" }}
                            ></Form.Control>
                          </td>
                          <td>{item.email}</td>
                          <td>
                            {item.isAdmin == 0 ? "Admin" : ""}
                            {item.isAdmin == 1 ? "Employee" : ""}
                            {item.isAdmin == 2 ? "Merchant" : ""}
                            {item.isAdmin == 3 ? "Special Admin" : ""}
                            {item.isAdmin == 4 ? "incasso" : ""}
                            {item.isAdmin == 5 ? "Super Admin" : ""}
                          </td>
                          <td>
                            {role !== 3 && (
                              <i
                                className="fa fa-edit"
                                style={{
                                  color: "green",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  history.push(
                                    "/admin/addregister/?id=" + item.id
                                  )
                                }
                              />
                            )}
                            &nbsp; &nbsp;
                            <i
                              className="fa fa-trash red ml-3"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete?"
                                  )
                                )
                                  deleteRow(index);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Register;
