import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { getTotalSalesOfCurrentUser } from "services/reports";

export default function TotalDailySalesReport() {
  const [totalSales, setTotalSales] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [filtered, setFiltered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  useEffect(() => {
    if (!totalSales) return;
    setFiltered(true);
  }, [totalSales]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!date) {
      alert("date is empty");
    } else {
      setIsLoading(true); // Start loading
      getTotalSalesOfCurrentUser(date)
        .then((res) => {
          setTotalSales(res.data);
          setIsLoading(false); // Stop loading after data fetch
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false); // Stop loading on error
        });
    }
  };

  return (
    <div>
      <div>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title as="h4" className="text-center m-3 heading">
                    Total Sales of Users
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="requiredelement">Date</label>
                          <Form.Control
                            required
                            placeholder="abcd"
                            type="date"
                            name="Title"
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="text-center justify-content-center mt-2">
                      <Col md="12">
                        <div className="button-wrapper">
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Filtering..." : "Filter"}
                          </Button>
                          {!isLoading && filtered && (
                            <Button
                              className="btn-fill res-size"
                              type="submit"
                              style={{
                                backgroundColor: "#3AAB7B",
                                border: "none",
                              }}
                              disabled={isDownloading}
                            >
                              <CSVLink
                                data={totalSales}
                                filename={"TotalDaily_Sales_report.csv"}
                                onClick={() => {
                                  setIsDownloading(true);
                                  setTimeout(
                                    () => setIsDownloading(false),
                                    1000
                                  );
                                }}
                              >
                                {isDownloading ? "Downloading..." : "Download"}
                              </CSVLink>
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
