import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { getTransactionHistory } from "services/issuanceHistory";
import { CSVLink, CSVDownload } from "react-csv";
import getMerchantHistory from "services/reports";
import CustomSelect from "utils/CustomSelect";
import { components } from "react-select";

import makeAnimated from "react-select/animated";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

export default function MerchantReport() {
  const [merchantHistory, setMerchantHistory] = React.useState([{}]);
  // var merchantHistory = []
  const [date, setDate] = React.useState(null);
  const [period, setPeriod] = React.useState();
  const [filtered, setFiltered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setDate(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const periodMonths = period.map((p) => p.value);
    getMerchantHistory(date, periodMonths)
      .then((response) => {
        setMerchantHistory(response.data);
        setFiltered(true);
        // merchantHistory = response.data;
      })
      .catch((error) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <div>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title
                    as="h4"
                    className="text-center m-3 heading"
                  ></Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Date</label>
                          <Form.Control
                            required
                            value={date}
                            placeholder="abcd"
                            type="month"
                            name="Title"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Period</label>

                          <CustomSelect
                            options={[1, 2, 3, 4, 6, 10].map((item) => {
                              return {
                                value: item,
                                label: item,
                              };
                            })} // Options to display in the dropdown
                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              MultiValue,
                              animatedComponents,
                            }}
                            onChange={(e) => {
                              setPeriod(e);
                            }}
                            allowSelectAll={true}
                            value={period}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="text-center justify-content-center mt-2">
                      <Col md="12">
                        <div className="button-wrapper">
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                            disabled={isLoading ? true :false} 
                          >
                            {isLoading ? "Filtering..." : "Filter"}
                          </Button>
                          {filtered && !isLoading && (
                            <Button
                              className="btn-fill res-size"
                              style={{
                                backgroundColor: "#3AAB7B",
                                border: "none",
                              }}
                              disabled={isDownloading ? true : false}
                            >
                              <CSVLink
                                data={merchantHistory}
                                filename={"merchant_report.csv"}
                                onClick={() => {
                                  setIsDownloading(true);
                                  setTimeout(
                                    () => setIsDownloading(false),
                                    1000
                                  ); 
                                }}
                              >
                                {isDownloading ? "Downloading..." : "Download"}
                              </CSVLink>
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* {
                !form &&
                <div>

                </div>
            } */}
    </div>
  );
}
