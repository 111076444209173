import { getToken } from "./auth"
import address from "./address"
import axios from "axios";

export default function createPaybackPeriodHistory(formData) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/paybackperiodHistory/create', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getPaybackPeriodHistory(id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/paybackperiodHistory/get/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}
export function getAllPaybackPeriodHistory(id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/paybackperiodHistory/getAllPayBackHistory/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}