import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function getAllMembershipByClientId(Client_id) {
  const token = getToken()
  if (!token) return "Authentication Fail Sign In agian"
  return axios.get(address + '/api/membership/getAllMembershipByClientId/' + Client_id, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
}

export function getMembershipById(membershipId) {
  const token = getToken()
  if (!token) return "Authentication Fail Sign In agian"
  return axios.get(address + '/api/membership/getMembershipById/' + membershipId, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
}

export function createMembership(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(address + "/api/membership/createMembership", formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function updateMembership(membershipId, formData) {
  const token = getToken()
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(address + "/api/membership/updateMembership/" + membershipId, formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteMembership(membershipId) {
  const token = getToken()
  if (!token) return "Authentication Fail Sign In agian"
  return axios.delete(address + '/api/membership/deleteMembership/' + membershipId, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
}