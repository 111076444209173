import React, { useEffect, useState } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import {
  getMerchantData,
  updateMerchant,
  addMerchant,
} from "../services/merchant";
import { getUserData, getUserSingleData } from "../services/register";
import { getMerchantTypeDiscountByMerchantType_id } from "services/merchantType";
import getMerchantTypeList from "../services/merchantType";
import getDeviceData from "../services/device";
import _uniqueId from "lodash/uniqueId";
import CustomSelect from "utils/CustomSelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();
function MerchantForm() {
  const role = JSON.parse(localStorage.getItem("role"));
  const history = useHistory();
  const [merchantTypeData, setMerchantTypeData] = React.useState([]);
  console.log("===merchantTypeData", merchantTypeData);
  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("id");

  const [ClientID, setClientID] = React.useState();

  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const [formData, setFormData] = React.useState({
    id: "",
    Code: "",
    Name: "",
    MerchantType_id: "",
    AccountNo: "",
    BankName: "",
    Email: "",
    address: "",
    phoneNumber: "",
    User_id: "",
    maxCredit: "",
    Remarks: "",
  });

  const [selectedDevices, setSelectedDevices] = React.useState([]);
  const [merchantTypes, setMerchantTypes] = React.useState([]);
  const [merchants, setMerchants] = React.useState([]);
  const [selectedMerchant, setSelectedMerchant] = React.useState([]);
  const [devices, setDevices] = React.useState([]);

  const deviceList = [
    {
      label: "Android Terminal",
      value: "Android Terminal",
    },
    {
      label: "Smart Phone",
      value: "Smart Phone",
    },
    {
      label: "Application",
      value: "Application",
    },
  ];
  const [selectedDevice, setSelectedDevice] = React.useState(null);

  const fetchData = async (value) => {
    try {
      const response = await getMerchantTypeDiscountByMerchantType_id(value);
      if (response.data) {
        const data = response.data.map((item) => ({
          merchant_id: params ? params : "",
          no_of_months_id: item.id,
          month: item.NumberOfMonths,
          max_credit: "0",
          merchant_type_id: item.MerchantType_id,
          used_credit: "0",
        }));

        setMerchantTypeData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const params = queryParams.get("id");
    if (params != null) {
      setClientID(params);
    } else {
      setFormData({ ...formData });
    }
    getMerchantTypeList()
      .then((response) => {
        response.data.unshift({});
        setMerchantTypes(response.data);
      })
      .catch((error) => {});
    getUserData()
      .then((res) => {
        const myMerchants = [];
        for (let index = 0; index < res.data.length; index++) {
          const user = res.data[index];
          if (user.isAdmin === 2) {
            myMerchants.push(user);
          }
        }
        const formattedData = myMerchants.map((item) => {
          return {
            value: item.id,
            label: `${item.email}`,
          };
        });
        setMerchants(formattedData);
      })
      .catch((err) => {});
    getDeviceData()
      .then((res) => {
        setDevices(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (ClientID == null) return setMerchantTypeData([]);

    getMerchantData(ClientID)
      .then((response) => {
        const merchantData =
          response && response.data.merchant_type_max_credits;
        setMerchantTypeData(merchantData);
        setFormData(response.data);

        setSelectedDevices({
          value: response.data.deviceType,
          label: response.data.deviceType,
        });
        response.data.User_id &&
          getUserSingleData(response.data.User_id).then((res_User) => {
            setSelectedMerchant({
              value: res_User.data.id,
              label: `${res_User.data.email}`,
            });
          });
      })
      .catch((error) => {});
  }, [ClientID]);

  const {
    id,
    Code,
    Name,
    MerchantType_id,
    AccountNo,
    BankName,
    address,
    phoneNumber,
    Email,
    User_id,
    pinCode,
    maxCredit,
    Remarks,
  } = formData;

  const validateInput = (name, value) => {
    if (name === "Code" || name == "address") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    if (name === "Name") {
      return true;
    }
    if (name === "BankName") {
      let pattern = new RegExp("^[a-zA-Z ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only alphabets and spaces";
    }
    if (name === "AccountNo") {
      let pattern = new RegExp("^[0-9 ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    if (name === "phoneNumber") {
      let pattern = new RegExp("^[0-9 +]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    if (name === "pinCode") {
      let pattern = new RegExp("^[0-9 +]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    if (name === "maxCredit") {
      let pattern = new RegExp("^[0-9 +]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    return true;
  };
  const validateEmail = (email) => {
    if (!email) return true;
    if (email.length < 1) return true;
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(email)) {
      return true;
    }
    return "not a valid email";
  };

  const handleInputChange = (e) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      alert(valid);
      return;
    }
    if (e.target.name === "MerchantType_id") {
      const inputValue = e.target.value;

      if (inputValue.trim() !== "") {
        fetchData(inputValue);
      } else {
        setMerchantTypeData([]);
      }
    }
    if (e.target.name === "MerchantType_id") {
      const inputValue = e.target.value;
      if (inputValue.trim() !== "") {
        fetchData(inputValue);
      } else {
        setMerchantTypeData([]);
      }
    }

    if (e.target.name === "Devices") {
      let myDevices = [...selectedDevices];
      if (myDevices.includes(e.target.value)) {
        myDevices = myDevices.filter((item) => item !== e.target.value);
      } else {
        myDevices.push(e.target.value);
      }
      setSelectedDevices([...myDevices]);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (enewDatavent) => {
    event.preventDefault();
    const valid = validateEmail(Email);
    if (valid != true) {
      alert(valid);
      return;
    }
    if (selectedMerchant.value === undefined) {
      alert("Please Select a Merchant User");
      return;
    }

    if (ClientID) {
      updateMerchant({
        ...formData,
        Devices: JSON.stringify(selectedDevices),
        merchantTypeMaxCredits: formData.merchant_type_max_credits,
      })
        .then((response) => {
          history.push("/admin/MerchantList");
        })
        .catch((error) => {});
    } else {
      const data = merchantTypeData.map((item) => ({
        no_of_months_id: item.no_of_months_id,
        max_credit: item.max_credit,
        merchant_type_id: item.merchant_type_id,
        used_credit: item.used_credit,
      }));
      addMerchant({
        ...formData,
        Devices: JSON.stringify(selectedDevices),
        merchantTypeMaxCredits: data,
      })
        .then((response) => {
          history.push("/admin/MerchantList");
        })
        .catch((error) => {
          if (error.response.data.message === "user id already exist") {
            alert(
              "Merchant user already exists. Please Select a different merchant user"
            );
          }
        });
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...merchantTypeData];

    if (field === "max_credit") {
      if (value === "") {
        newData[index][field] = "0";
      } else {
        newData[index][field] = parseInt(value, 10).toString();
      }
    } else if (field === "used_credit") {
      if (value === "") {
        newData[index][field] = "0";
      } else {
        newData[index][field] = parseInt(value, 10);
      }
    } else {
      newData[index][field] = parseInt(value, 10);
    }

    setMerchantTypeData(newData);
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h3" className="text-center m-3 heading">
                Merchant
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label className="requiredelement">Code</label>
                      <Form.Control
                        required
                        placeholder="123"
                        type="text"
                        value={Code}
                        name="Code"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label className="requiredelement">Nomber</label>
                      <Form.Control
                        required
                        placeholder="Nomber"
                        type="text"
                        value={Name}
                        name="Name"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Form.Group>
                      <label>Adres</label>
                      <Form.Control
                        placeholder="Adres"
                        type="text"
                        value={address}
                        name="address"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label>Email</label>
                      <Form.Control
                        placeholder="Email"
                        type="text"
                        value={Email}
                        name="Email"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label> Phone Number</label>
                      <Form.Control
                        placeholder="042"
                        type="text"
                        value={phoneNumber}
                        name="phoneNumber"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label className="requiredelement">Kuenta di Banko</label>
                      <Form.Control
                        required
                        placeholder="Kuenta di Banko"
                        type="lastName"
                        value={AccountNo}
                        name="AccountNo"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label className="requiredelement">Nomber di Banko</label>
                      <Form.Control
                        required
                        placeholder="Nomber di Banko"
                        type="text"
                        value={BankName}
                        name="BankName"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Form.Group>
                      <label className="requiredelement">Merchant User</label>
                      {/* <Form.Control required as="select" name="User_id" value={User_id} onChange={(e) => { handleInputChange(e) }}>
                        <option></option>
                        {merchants.map((item) => (<option value={item.id}>{item.email}</option>))}
                      </Form.Control>

                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback> */}
                      <CustomSelect
                        options={merchants} // Options to display in the dropdown
                        // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                        isSearchable={true}
                        value={selectedMerchant}
                        isMulti={false}
                        hideSelectedOptions={false}
                        placeholder={"Select Merchant.."}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={async (e) => {
                          setSelectedMerchant(e);
                          setFormData({ ...formData, User_id: e.value });
                        }}
                        allowSelectAll={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Form.Group>
                      <label className="requiredelement">Device Type</label>
                      <CustomSelect
                        options={deviceList}
                        isMulti={false}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={async (e) => {
                          setSelectedDevices(e);
                          setFormData({ ...formData, deviceType: e.value });
                        }}
                        allowSelectAll={false}
                        value={selectedDevices}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label className="requiredelement">Merchant Type</label>
                      <Form.Control
                        required
                        as="select"
                        name="MerchantType_id"
                        value={MerchantType_id}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        disabled={params !== null}
                      >
                        {merchantTypes.map((item) => (
                          <option value={item.id}>{item.Title}</option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12" md="6">
                    <Form.Group>
                      <label className="requiredelement">Pin Code</label>
                      <Form.Control
                        required
                        placeholder="Pin Code"
                        type="number"
                        value={pinCode}
                        name="pinCode"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {merchantTypeData &&
                  merchantTypeData
                    .sort((a, b) => a.month - b.month)
                    .map((item, index) => (
                      <Row key={index}>
                        <Col sm="12" md={params ? "4" : "6"}>
                          <Form.Group>
                            <label className="requiredelement">
                              Month {index + 1}
                            </label>
                            <Form.Control
                              type="number"
                              placeholder="Month"
                              value={item.month}
                              disabled
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "no_of_month_id",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col sm="12" md={params ? "4" : "6"}>
                          <Form.Group>
                            <label className="requiredelement">
                              Max Credit {index + 1}
                            </label>
                            <Form.Control
                              type="number"
                              placeholder="Max Credit"
                              value={item.max_credit}
                              disabled={role === 3}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "max_credit",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        {params && (
                          <Col sm="12" md={params ? "4" : "6"}>
                            <Form.Group>
                              <label className="requiredelement">
                                Used Credit {index + 1}
                              </label>
                              <Form.Control
                                type="number"
                                name="used_credit"
                                placeholder="credit Used"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "used_credit",
                                    e.target.value
                                  )
                                }
                                value={item.used_credit}
                                disabled={
                                  MerchantType_id === "prefix-24" ? false : true
                                }
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    ))}

                <Row>
                  <Col md="12">
                    <Form.Group>
                      <label>Notes</label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder=""
                        value={Remarks}
                        name="Remarks"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      {/* <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Form.Group>
                      <label>Device</label>
                      <Form.Control as="select" multiple name="Devices" value={selectedDevices} onChange={(e) => { handleInputChange(e) }}>
                        {devices.map((item) => (<option value={item.id}>{item.nameNumber}</option>))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row className="text-center">
                  <Col md="12">
                    <div className="button-wrapper">
                      <Button
                        className="btn-fill res-size"
                        type="submit"
                        style={{ backgroundColor: "#3AAB7B", border: "none" }}
                      >
                        Save
                      </Button>
                      <Link to="/admin/MerchantList">
                        <Button
                          className="btn-fill res-size"
                          variant="danger"
                          style={{ border: "none" }}
                        >
                          Back
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default MerchantForm;
