import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ListGroup,
} from "react-bootstrap";
import Grid from "@mui/material/Grid";
//          Importing Services
import {
  allowBalanceByIssuance,
  getissuancehistoryByClientId,
} from "../services/issuanceHistory";
import {
  deleteIssuanceHistory,
  getissuancehistoryAmountByClientId,
} from "../services/issuanceHistory";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { formatDate } from "utils/dateHandler";
function IssuanceHistory() {
  const role = JSON.parse(localStorage.getItem("role"));
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [issuanceHistory, setIssuanceHistory] = React.useState([]);
  const [amountToBePaid, setRemainingAmount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleClose = (e) => {
    setOpen(false);
    setDeleteId(null);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("id");

  useEffect(() => {
    setIsLoading(true);

    //remaining amount that will be paid by User
    getissuancehistoryAmountByClientId(params)
      .then(async (res) => {
        const remainingData = [...res.data];
        const sumData = remainingData.reduce(
          (total, amount) => total + amount.remainingAmount,
          0
        );
        setRemainingAmount(sumData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getissuancehistoryByClientId(params)
      .then(async (res) => {
        res.data.sort((a, b) => b.DateTime.localeCompare(a.DateTime));
        const issuanceHistoryData = [...res.data];

        for (let i = 0; i < issuanceHistoryData.length; i++) {
          const result = await allowBalance(issuanceHistoryData[i]);
          issuanceHistoryData[i].allowBal = result.allowBal;
          issuanceHistoryData[i].alert = result.alert;
        }
        setIssuanceHistory(issuanceHistoryData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  const handleCsv = () => {
    history.push(`/admin/IssuanceHistoryCsv?id=${params}`);
  };
  function handleDelete() {
    deleteIssuanceHistory(deleteId)
      .then((res) => {
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }
  const ifAddBalanceValid = (date) => {
    let previousDate = new Date(date);
    let currentDate = new Date();
    let prevYear = previousDate.getFullYear();
    let currYear = currentDate.getFullYear();
    if (prevYear != currYear) return false;
    let prevMonth = previousDate.getMonth();
    let currMonth = currentDate.getMonth();
    if (prevMonth != currMonth) return false;
    return true;
  };

  const allowBalance = async (issuanceHistory) => {
    const returnResp = {
      allowBal: false,
      alert: false,
    };
    try {
      const resp = await allowBalanceByIssuance(issuanceHistory);
      if (resp.data.allow == true) {
        if (resp.data.msg) {
          returnResp.alert = true;
          returnResp.allowBal = true;
        } else {
          returnResp.alert = false;
          returnResp.allowBal = true;
        }
      }
    } catch (error) {}
    return returnResp;
  };

  const handleAddBalanceClick = (issuanceHistory) => {
    if (issuanceHistory.alert) {
      if (window.confirm("This client has not paid last month fully"))
        history.push(
          `/admin/addBalanceForm?id=${issuanceHistory.id}&cid=${params}`
        );
    } else {
      history.push(
        `/admin/addBalanceForm?id=${issuanceHistory.id}&cid=${params}`
      );
    }
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <div className="d-flex flex-md-row flex-column align-items-center justify-content-between">
                  <div className="mx-2">
                    <Card className="card-stats">
                      <Card.Body>
                        <div className="d-flex  justify-content-between w-100">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins text-warning"></i>
                          </div>
                          <div className="flex-grow-1 mx-5"></div>
                          <div className="numbers">
                            <p className="card-category">Remaining Amount</p>
                            <Card.Title as="h4">
                              NAF {(amountToBePaid || 0).toFixed(2)}
                            </Card.Title>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div>
                    <Button
                      className="btn-fill res-size"
                      variant="danger"
                      style={{ border: "none" }}
                      onClick={() => {
                        if (role === 4) {
                          history.push("/admin/currentYearDebt");
                        } else {
                          history.push(`/admin/ClientList?id=${params}`);
                        }
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                {/* <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size" type="submit" style={{ backgroundColor: "#22577E", border: "none" }}
                    onClick={() => history.push(`/admin/PaymentForm?id=${params}`)}
                  >
                    Payment Form
                  </Button>

                </div> */}
                <Table className="table-hover mt-3">
                  <thead>
                    <tr>
                      <th className="border-0">Fecha</th>
                      <th className="border-0">Kliente</th>
                      <th className="border-0">Montante </th>
                      <th className="border-0">Montante Paid</th>
                      <th className="border-0">Balance</th>
                      <th className="border-0">Merhants</th>
                      <th className="border-0">Nfc Card </th>
                      {role === 0 || role === 3 || role === 5 ? (
                        <th className="border-0">Pin Code</th>
                      ) : (
                        ""
                      )}
                      {role !== 4 ? (
                        <th className="border-0">Balance Actions</th>
                      ) : (
                        ""
                      )}

                      <th className="border-0">Paybackperiod History</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading === false ? (
                      issuanceHistory.length > 0 ? (
                        issuanceHistory.map((item, index) => (
                          <tr key={index}>
                            <td>{formatDate(item.DateTime.split("T")[0])}</td>
                            <td>{item.Client_id}</td>
                            <td>
                              {item.Amount
                                ? parseFloat(item.Amount).toFixed(2)
                                : 0}
                            </td>
                            <td>
                              {item.AmountPaid
                                ? parseFloat(item.AmountPaid).toFixed(2)
                                : 0}
                            </td>
                            <td>
                              {item.Balance
                                ? parseFloat(item.Balance).toFixed(2)
                                : 0}
                            </td>
                            <td>
                              {item.Merchants ? (
                                <div
                                  style={{
                                    maxHeight: "50px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <ListGroup>
                                    {item.Merchants.split(",").map((item) => {
                                      return (
                                        <ListGroup.Item>{item}</ListGroup.Item>
                                      );
                                    })}
                                  </ListGroup>
                                </div>
                              ) : (
                                "None"
                              )}
                            </td>
                            <td>{item.NfcCard_id}</td>
                            {role === 0 || role === 3 || role === 5 ? (
                              <td>{item.Pincode}</td>
                            ) : (
                              ""
                            )}

                            {item.NfcCard_id == "M-1010" && role == 3 ? (
                              <td>{""}</td>
                            ) : role !== 4 ? (
                              <td>
                                <ButtonGroup
                                  className="me-2"
                                  aria-label="First group"
                                >
                                  <Button
                                    className="btn-fill res-size"
                                    type="submit"
                                    style={{
                                      backgroundColor: "#22577E",
                                      border: "none",
                                    }}
                                    // disabled={!ifAddBalanceValid(item.DateTime.split('T')[0])}
                                    disabled={!item.allowBal}
                                    onClick={() => handleAddBalanceClick(item)}
                                  >
                                    <i
                                      class="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                  <Button
                                    className="btn-fill res-size"
                                    type="submit"
                                    style={{
                                      backgroundColor: "#22577E",
                                      border: "none",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/admin/BalanceHistoryList?id=${item.id}&cid=${params}`
                                      )
                                    }
                                  >
                                    <i
                                      class="fa fa-history"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                </ButtonGroup>
                              </td>
                            ) : (
                              ""
                            )}

                            <td>
                              <Button
                                className="btn-fill res-size"
                                type="submit"
                                style={{
                                  backgroundColor: "#22577E",
                                  border: "none",
                                }}
                                onClick={() =>
                                  history.push(
                                    `/admin/PaybackPeriodHistoryList?id=${item.id}&cid=${params}`
                                  )
                                }
                              >
                                <i class="fa fa-history" aria-hidden="true"></i>
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="btn-fill res-size"
                                type="submit"
                                variant="info"
                                style={{ border: "none" }}
                                onClick={() =>
                                  history.push(
                                    `/admin/IssuanceCardForm?id=${item.id}`
                                  )
                                }
                              >
                                View
                              </Button>
                              &nbsp;
                              <Button
                                className="btn-fill res-size"
                                type="submit"
                                style={{
                                  backgroundColor: "#22577E",
                                  border: "none",
                                }}
                                onClick={() =>
                                  history.push(
                                    `/admin/PaymentForm?id=${item.id}&cid=${params}`
                                  )
                                }
                              >
                                Payment
                              </Button>
                              &nbsp;
                              {role === 0 || role === 3 || role === 5 ? (
                                <>
                                  <Button
                                    className="btn-fill res-size"
                                    type="submit"
                                    style={{
                                      backgroundColor: "#FF0000",
                                      border: "none",
                                    }}
                                    onClick={() => handleClickOpen(item.id)}
                                  >
                                    Delete
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">
                            <h5 style={{ textAlign: "center" }}>
                              No Issuance history to show
                            </h5>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <h5 style={{ textAlign: "center" }}>Loading...</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {open && (
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title">
                        {"Delete Issuance History?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          You are about to delete this issuance history. Do you
                          wish to continue?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ justifyContent: "center" }}>
                        <Button
                          // onClick={handleClose}
                          onClick={handleDelete}
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default IssuanceHistory;
