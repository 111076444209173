import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { getNfcList } from "../../services/nfc";
import { getAllMerchantWithPagafasilType } from "../../services/user";
import { OnNfcAndPinCodeMultipleIssuance } from "../../services/issuanceHistory";
import { CreateTransactionHistory } from "../../services/transaction";
import { toast, ToastContainer } from "react-toastify";

const Index = () => {
  const [allNfcCards, setAllNfcCards] = useState([]);
  const [selectedNfcCard, setSelectedNfcCard] = useState("");
  const [selectedallMerchant, SelectedAllMerchant] = useState([{}]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [nfcCardResponse, setNfccardResponse] = useState();
  const [selectedMonthData, setSelectedMonthData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [secondForm, showSecondForm] = useState(false);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    nfcCardId: "",
    merchantId: "",
  });
  const { merchantId } = formData;

  const [formDataTwo, setSecondFormData] = useState({
    Client_id: "",
    ItemDescription: "",
    dateTime: "",
    AmountUser: "",
    issuancehistoryId: "",
    transactionType: "",
    merchantId: "",
  });
  const { AmountUser } = formDataTwo;

  useEffect(() => {
    getNfcList()
      .then((nfcResponse) => {
        const formattedNfcData = nfcResponse.data.map((item) => ({
          value: item.id,
          label: `${item.number}`,
        }));
        setAllNfcCards([...formattedNfcData]);
      })
      .catch((error) => {
        console.error("Error fetching NFC cards:", error);
      });

    getAllMerchantWithPagafasilType()
      .then((merchantResponse) => {
        const formattedMerchantData = merchantResponse.data.map((item) => ({
          value: item.id,
          label: item.Name,
        }));
        SelectedAllMerchant(formattedMerchantData);
      })
      .catch((error) => {
        console.error("Error fetching merchants:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(false);
    setSelectedMonthData({});
    showSecondForm(false);

    if (formData.nfcCardId === "") {
      toast.info("Please select an NFC card", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (formData.merchantId === "") {
      toast.info("Please select a merchant", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await OnNfcAndPinCodeMultipleIssuance(formData);
      setNfccardResponse(response?.data?.data);
      setFormSubmitted(true);
    } catch (error) {
      console.error("Issuance error:", error);
      toast.error("Error occurred while processing OR Invalid Card! data", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNfcCardChange = (selectedOption) => {
    setSelectedNfcCard(selectedOption);
    setFormData({ ...formData, nfcCardId: selectedOption.value });
    setFormSubmitted(false);
    setSelectedMonthData({});
    showSecondForm(false);
  };

  const handleMerchantChange = (selectedOption) => {
    setSelectedMerchant(selectedOption);
    setFormData({ ...formData, merchantId: selectedOption.value });
    setFormSubmitted(false);
    setSelectedMonthData({});
    showSecondForm(false);
  };

  const handleNfcCardpaybackType = (selectedOption) => {
    if (selectedOption === "") {
      setSelectedMonthData({});
      showSecondForm(false);
    } else {
      let paybackDetail = {};
      nfcCardResponse.forEach((item) => {
        if (Number(selectedOption) === item.paybackPeriodCount) {
          paybackDetail = item;
        }
      });

      setSelectedMonthData(paybackDetail);
    }
  };

  const handleInputChange = (e) => {
    let updatedFormData = { ...formDataTwo, [e.target.name]: e.target.value };
    if (e.target.name === "ItemDescription") {
      updatedFormData.transactionType = e.target.value === "expense" ? 1 : 2;
    }

    setSecondFormData(updatedFormData);
  };

  const handleTransactionFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formDataTwo,
      issuancehistoryId: selectedMonthData?.data?.id,
      merchantId: merchantId,
      Client_id: selectedMonthData?.data?.Client_id,
      dateTime: new Date().toLocaleString("en-US", {
        timeZone: "America/Curacao",
        hour12: false,
      }),
    };

    if (formDataTwo.ItemDescription === "") {
      toast.info("Please select Transaction Type", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (formDataTwo.AmountUser === "") {
      toast.info("Please select amount", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setLoading(true); // Start loading
    try {
      const response = await CreateTransactionHistory(updatedFormData);
      if (response?.data?.success === true) {
        toast.success("Transaction performed successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        resetFormAndState();
      } else {
        toast.error(response?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const { message } = error.response.data;
        console.error("Transaction error:", message);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.error("Transaction error:", error);
        toast.error("An error occurred during transaction", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const resetFormAndState = () => {
    setFormSubmitted(false);
    setSelectedNfcCard("");
    setSelectedMerchant("");
    setNfccardResponse(null);
    setSelectedMonthData();
    showSecondForm(false);
    setSecondFormData({
      Client_id: "",
      ItemDescription: "",
      dateTime: "",
      AmountUser: "",
      issuancehistoryId: "",
      transactionType: "",
      merchantId: "",
    });
    setFormData({
      nfcCardId: "",
      merchantId: "",
    });
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md="10">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h4" className="text-center m-3 heading">
                  Create Transaction
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="12" lg="8" xl="5">
                        <Form.Group>
                          <label className="requiredelement">
                            pagafasil merchant
                          </label>
                          <Select
                            options={selectedallMerchant}
                            onChange={handleMerchantChange}
                            value={selectedMerchant}
                            isSearchable={true}
                            placeholder={"Select Merchant.."}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="12" xl="5">
                        <Form.Group>
                          <label className="requiredelement">NFC Card</label>
                          <Select
                            options={allNfcCards}
                            onChange={handleNfcCardChange}
                            value={selectedNfcCard}
                            isSearchable={true}
                            placeholder={"Search or select NFC Card.."}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs="12" sm="12" md="12" lg="4" xl="2">
                        <div className="d-flex  justify-content-end justify-content-md-center pt-2">
                          <Button
                            type="submit"
                            className="btn-fill res-size"
                            variant="danger"
                            style={{ border: "none" }}
                            disabled={loading}
                          >
                            Next
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
                {formSubmitted && (
                  <>
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="12" lg="9" xl="9">
                        <label className="requiredelement">
                          Please select a payback period
                        </label>

                        <Form.Control
                          as="select"
                          name=""
                          onChange={(e) =>
                            handleNfcCardpaybackType(e.target.value)
                          }
                        >
                          <option value={""}></option>
                          {nfcCardResponse &&
                            nfcCardResponse.map((item) => (
                              <option
                                key={item.paybackPeriodCount}
                                value={item.paybackPeriodCount}
                              >
                                {`${item.paybackPeriodCount} Month`}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                      <Col xs="12" sm="12" md="12" lg="3" xl="3">
                        <div className="d-flex  justify-content-end justify-content-md-center pt-4">
                          <Button
                            className="btn-fill res-size "
                            variant="danger"
                            onClick={() => {
                              if (!Object.keys(selectedMonthData).length) {
                                toast.info(
                                  "Please select a payback period month",
                                  {
                                    position: toast.POSITION.TOP_RIGHT,
                                  }
                                );
                                showSecondForm(false);
                              } else {
                                showSecondForm(true);
                              }
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                {secondForm && selectedMonthData && (
                  <div className="">
                    <Form onSubmit={handleTransactionFormSubmit}>
                      <Row className="align-items-center pt-4">
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <label className="requiredelement">
                            Please select transaction type
                          </label>

                          <Form.Control
                            as="select"
                            name="ItemDescription"
                            onChange={handleInputChange}
                          >
                            <option></option>

                            <option key={"expense"} value={"expense"}>
                              Expense
                            </option>
                            <option key={"retour"} value={"retour"}>
                              Retour
                            </option>
                          </Form.Control>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <Form.Group>
                            <label className="requiredelement">
                              Client name
                            </label>
                            <Form.Control
                              required
                              placeholder="client Name"
                              type="text"
                              value={
                                selectedMonthData &&
                                selectedMonthData?.clientCodeAndFullName
                                  ?.FullName
                              }
                              name="client Name"
                              disabled
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <Form.Group>
                            <label className="requiredelement"> Code</label>
                            <Form.Control
                              required
                              placeholder="Group Code"
                              type="text"
                              value={
                                selectedMonthData &&
                                selectedMonthData?.clientCodeAndFullName?.Code
                              }
                              name="Code"
                              disabled
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                          <Form.Group>
                            <label className="requiredelement">Amount</label>
                            <Form.Control
                              required
                              placeholder="Amount"
                              type="text"
                              value={AmountUser}
                              name="AmountUser"
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* 
    <Row>
      <Col xs="12" sm="12" md="12" lg="6" xl="6">
        <Form.Group>
          <label className="requiredelement">Amount</label>
          <Form.Control
            required
            placeholder="Group Code"
            type="text"
            value={AmountUser}
            name="Code"
            onChange={(e) => handleInputChange(e)}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            Please provide a value.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row> */}

                      <div className="d-flex justify-content-center">
                        <Button
                          type="submit"
                          className="btn-fill res-size"
                          variant="info"
                          style={{ border: "none" }}
                          disabled={loading}
                        >
                          submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default Index;
