const getCurrentDate = () => {
  process.env.TZ = "America/Curacao";
  return new Date();
};
const ifNull = (value) => {
  return value == null ? 0 : parseFloat(value);
};

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};
export { getCurrentDate, ifNull, formatDate };
