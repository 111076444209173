import { getToken } from "./auth"
import address from "./address"
import axios from "axios";

export default function getPendingList() {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/clientRequest/getPendingList', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}
export function updateClientRequestStatus(status, id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/clientRequest/updateStatus/' + id, {status}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getAcceptedList() {
    const token = getToken()

    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/clientRequest/getAcceptedList', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}


export function getRejectedList() {
    const token = getToken()

    if (!token) return "Authentication Fail Sign In agian"

    return axios.get(address + '/api/clientRequest/getRejectedList', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}