import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { getMaxRemainingCreditClient } from "services/issuanceHistory";
import { getGiftNfcData } from "services/nfc";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import getClientList from "services/client";
import CustomSelect from "utils/CustomSelect";
import PdfGenCard from "./PdfGenCard";
import getMerchantList from "services/merchant";
import { getissuancehistoryByClientId } from "services/issuanceHistory";
import { getUserId } from "services/auth";
import addIssuanceHistory from "services/issuanceHistory";
import { addIssuanceHistoryRow } from "services/issuanceHistory";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

function GiftCardIssuance() {
  const history = useHistory();

  const [cards, setCards] = React.useState([]);
  const [merchants, setMerchants] = React.useState([]);
  // const [client, setClient] = React.useState("G-0000");
  const role = JSON.parse(localStorage.getItem("role"));
  const [uniqueID] = React.useState(Math.random() * 100000 * Math.random());
  const [selectedMerchant, setSelectedMerchant] = React.useState();
  const [selectedGiftCard, setSelectedGiftCard] = React.useState({
    value: "",
    label: "",
  });
  const [issuanceFormData, setIssuanceFormData] = React.useState({
    DateTime: new Date().toISOString().split("T")[0],
    Client_id: "P-0000",
    NfcCard_id: null,
    Pincode: JSON.stringify(Math.floor(1000 + Math.random() * 9000)),
    Balance: "",
    id: uniqueID,
    TypeOfReturnPayment: "Gift Card",
    ExpiryDate: "",
    Amount: 0,
    merchants: [],
  });
  // const [clientBorrowLimit, setClientBorrowLimit] = React.useState(0);
  // const [bonusLimit, setBonusLimit] = React.useState(0)

  const transformResponseIntoReactMulti = (data, targetColumn, name) => {
    const formattedData = data.map((item) => {
      return {
        value: item.id,
        target: {
          name,
        },
        label: `${item[targetColumn]}`,
      };
    });
    return formattedData;
  };

  const FetchClients = async () => {
    try {
      getClientList().then((response) => {
        const myClientsList = [];
        for (let index = 0; index < response.data.length; index++) {
          const client = response.data[index];
          if (client.Status === 1) {
            myClientsList.push(client);
          }
        }
        const formattedData = myClientsList.map((item) => {
          return {
            value: item.id,
            target: {
              name: "clients",
            },
            label: `${item.FirstName} ${item.LastName}: ${item.Code}`,
          };
        });
        setClients(formattedData);
      });
    } catch (error) {}
  };

  const FetchCards = async () => {
    try {
      const response = await getGiftNfcData();
      if (response.data.length > 0) {
        const data = response.data.filter((item) => (item.status = 1));
        setCards(transformResponseIntoReactMulti(data, "number", "cards"));
      }
    } catch (error) {}
  };

  const FetchMerchants = async () => {
    try {
      const response = await getMerchantList();
      const formattedData = response.data.map((item) => {
        return {
          value: item.id,
          target: {
            name: "merchants",
          },
          label: `${item.Name}: ${item.Code}`,
        };
      });
      setMerchants(formattedData);
    } catch (error) {}
  };

  useEffect(() => {
    FetchCards();
    // FetchClients()
    FetchMerchants();
  }, []);

  const handleClientChange = async (clientID) => {
    try {
      const { data } = await getMaxRemainingCreditClient(clientID);
      setClientBorrowLimit(parseInt(data.amount));
      setBonusLimit(parseInt(data.bonus));
      let pinCode = issuanceFormData.Pincode;
      let cardID = issuanceFormData.NfcCard_id;
      let res = await getissuancehistoryByClientId(clientID);
      let issuanceHistory = res.data.map(
        (item) =>
          item.NfcCard_id.startsWith("G") || item.NfcCard_id.startsWith("g")
      );
      if (issuanceHistory.length > 0) {
        let card = issuanceHistory[0].NfcCard_id || issuanceFormData.NfcCard_id;
        setSelectedGiftCard({
          value: card,
          label: card,
        });
        pinCode = issuanceHistory[0].Pincode || issuanceFormData.Pincode;
        cardID = card;
      }
      setIssuanceFormData({
        ...issuanceFormData,
        Client_id: clientID,
        Pincode: pinCode,
        NfcCard_id: cardID,
      });
    } catch (error) {}
  };

  const handleChange = async (e) => {
    // if (e.target.name == 'clients') {
    //      await handleClientChange(e.value)
    // }
    // else
    if (e.target.name == "cards") {
      setSelectedGiftCard(e);
      setIssuanceFormData({ ...issuanceFormData, NfcCard_id: e.value });
    } else if (e.target.name == "date") {
      setIssuanceFormData({ ...issuanceFormData, DateTime: e.target.value });
    } else if (e.target.name == "pincode") {
      setIssuanceFormData({ ...issuanceFormData, Pincode: e.target.value });
    }
    // else if (e.target.name == "paymentType") {
    //     setIssuanceFormData({ ...issuanceFormData, TypeOfReturnPayment: e.target.value })
    // }
    else if (e.target.name == "Amount") {
      // if (!validAmount(e.target.value)) {
      //     e.target.value = 0
      // }
      setIssuanceFormData({
        ...issuanceFormData,
        Amount: e.target.value,
        Balance: e.target.value,
      });
    } else if (e.target.name == "ExpiryDate") {
      setIssuanceFormData({ ...issuanceFormData, ExpiryDate: e.target.value });
    }
  };
  const validateSubmitData = (data) => {
    const resp = {
      error: false,
      msg: "",
    };
    if (data.Amount == 0) {
      resp.error = true;
      resp.msg = "Montante should be greater than 0";
    } else if (!data.NfcCard_id || data.NfcCard_id.length == 0) {
      resp.error = true;
      resp.msg = "Select Prepaid Card";
    }
    return resp;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createIssuanceData = {
      ...issuanceFormData,
      handledByUserId: getUserId(),
    };
    const resp = validateSubmitData(createIssuanceData);
    if (resp.error) {
      alert(resp.msg);
      return;
    }
    try {
      let formRes = await addIssuanceHistory(createIssuanceData);
      await createIssuanceData.merchants.map(async (merchantID, index) => {
        const rowData = {
          id: JSON.stringify(Math.random() * 100000 * Math.random()),
          issuancehistoryId: formRes.data.id,
          merchantId: merchantID,
          numberOfMonthsId: null,
        };
        await addIssuanceHistoryRow(rowData);
      });
      alert("The prepaid card has been issued successfully!");
      history.goBack();
    } catch (error) {}
  };

  const handleAddMerchant = (e) => {
    let merchants = [];
    const resp = ifFormFilled();
    if (resp) {
      alert(resp);
      setSelectedMerchant(null);
    } else {
      e.forEach((element) => {
        merchants.push(element.value);
      });
      setSelectedMerchant(e);
    }

    setIssuanceFormData({ ...issuanceFormData, merchants });
  };

  const validAmount = (amount) => {
    const limit =
      bonusLimit > clientBorrowLimit ? bonusLimit : clientBorrowLimit;
    if (parseFloat(amount) > limit) {
      return false;
    }
    return true;
  };

  const ifFormFilled = () => {
    const validationList = ["NfcCard_id", "DateTime", "Amount", "Pincode"];
    for (const key in issuanceFormData) {
      if (
        validationList.find((element) => (element = key)) &&
        !issuanceFormData[key]
      ) {
        return "Fill " + key;
      }
    }
    return false;
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h4" className="text-center m-3 heading">
                Prepaid Card Issuance
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Date Field */}
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Date</label>
                      <Form.Control
                        required
                        placeholder="123"
                        type="date"
                        name="date"
                        value={issuanceFormData.DateTime}
                        onChange={handleChange}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">Prepaid Cards</label>
                      <CustomSelect
                        options={cards}
                        name="cards"
                        required={selectedGiftCard ? true : null}
                        isSearchable={true}
                        isMulti={false}
                        hideSelectedOptions={false}
                        placeholder={"Select Prepaid Card.."}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={handleChange}
                        value={selectedGiftCard}
                        allowSelectAll={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Client ID Field */}
                  {/* <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                        <Form.Group>
                                            <label htmlFor="exampleLastName" className="requiredelement">Kliente Code</label>
                                            <CustomSelect
                                                name="client"
                                                options={clients}
                                                selected
                                                isSearchable={true}
                                                isMulti={false}
                                                hideSelectedOptions={false}
                                                placeholder={"Select Client.."}
                                                components={{ Option, MultiValue, animatedComponents }}
                                                onChange={handleChange}
                                                allowSelectAll={false}
                                            />
                                        </Form.Group>
                                    </Col> */}
                  {/* NFC Card Field */}
                </Row>
                <Row>
                  {/* Amount Field */}
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg={role === 0 || role === 5 ? "6" : "12"}
                    xl={role === 0 || role === 5 ? "6" : "12"}
                  >
                    <Form.Group>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <label className="requiredelement">Montante</label>
                        {/* <div style={{ marginLeft: "20px", color: "#9A9A9A", fontSize: "15px" }}>Bonus:&nbsp;{bonusLimit} &nbsp;&nbsp; Max:&nbsp;{clientBorrowLimit}</div> */}
                      </div>
                      <Form.Control
                        required
                        placeholder="100"
                        type="number"
                        name="Amount"
                        value={issuanceFormData.Amount}
                        onChange={handleChange}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* Code Pin Field */}
                  {role === 0 || role === 5 ? (
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                      <Form.Group>
                        <label className="requiredelement">Pin Code</label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="text"
                          name="pincode"
                          value={issuanceFormData.Pincode}
                          onChange={(e) => handleChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {/* <Row>
                                    <Col xs="12" sm="12" md="12" lg={"12"} xl={"12"}>
                                        <Form.Group>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <label className="requiredelement">Payment Type</label>
                                            </div>
                                            <Form.Control
                                                required as="select" name="paymentType"
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                value={issuanceFormData.TypeOfReturnPayment}
                                            >
                                                <option></option>
                                                <option value="Cash" selected>Cash</option>
                                                <option value="MCB">MCB</option>
                                                <option value="RBC">RBC</option>
                                                <option value="Banco Di Caribe">Banco Di Caribe</option>
                                                <option value="Maestro">Maestro</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row> */}
                <Row>
                  <Col sm="12" md="12">
                    <Form.Group>
                      <label className="requiredelement">
                        Fecha di Vensementu
                      </label>
                      <Form.Control
                        required
                        placeholder="123"
                        type="date"
                        value={issuanceFormData.ExpiryDate}
                        name="ExpiryDate"
                        onChange={(e) => handleChange(e)}
                        style={{ fontSize: "14px", padding: "20px 10px" }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="12" lg={"12"} xl={"12"}>
                    <Form.Group>
                      <label className="requiredelement">Merchant</label>
                      <CustomSelect
                        options={merchants}
                        isSearchable={true}
                        isMulti={true}
                        hideSelectedOptions={false}
                        placeholder={"Select Merchant.."}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={handleAddMerchant}
                        value={selectedMerchant}
                        allowSelectAll={false}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="text-center justify-content-center mt-2">
                  <Col md="12">
                    <div className="button-wrapper">
                      <>
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{ backgroundColor: "#3AAB7B", border: "none" }}
                        >
                          {/* {isLoading ? <div> <i class="fa fa-spinner" aria-hidden="true">&nbsp;</i> Saving...</div> : <div>Save</div>} */}
                          Save
                        </Button>
                      </>
                      <Button
                        className="btn-fill res-size"
                        variant="danger"
                        style={{ border: "none" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                      <PdfGenCard
                        staticArray={[
                          { key: "Date", value: issuanceFormData.DateTime },
                          {
                            key: "Kliente Code",
                            value: issuanceFormData.Client_id,
                          },
                          {
                            key: "Montante",
                            value: parseFloat(issuanceFormData.Amount).toFixed(
                              2
                            ),
                          },
                        ]}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GiftCardIssuance;
