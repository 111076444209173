import { getToken } from "./auth"
import address from "./address"
import axios from "axios";

export function addInsurance(Amount, id, paymentType) {
    const insuranceAmount = parseFloat(Amount) * 1 / 100
    const insuranceTax = insuranceAmount * 6 / 100
    const insuranceData = {
        amount: insuranceAmount,
        tax: insuranceTax,
        paymentType: paymentType,
        issuanceHistoryFk: id
    }

    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"

    return axios.post(address + '/api/insurance/createInsurance', insuranceData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}

export function getInsuranceAmountByIssuanceHistoryId(id) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian";
    return axios.get(address + '/api/insurance/getInsuranceAmountByIssuanceHistoryId/' + id, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
}