import { useEffect } from "react";
import { checkSedulaExist } from "services/client";
export default function useOutsideAlerter(ref) {
    let lastValue = ""
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (ref.current.value != lastValue) {
                    lastValue = ref.current.value
                    checkSedulaExist(lastValue)
                        .then(res => {
                            if (res.data.exist == true)
                                alert("Sedula already Exist")
                        })
                        .catch(err => console.log(err))
                }

            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}