import React, { useEffect, useState } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import getKassaHistory from "services/kassaHistory";
//          Importing Services

//          Importing Services
////-----------------------------///

export default function kassaHistory() {
  const queryParams = new URLSearchParams(window.location.search);
  const [employeeHistory, setEmployeeHistory] = React.useState([]);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const defaultDate = `${year}-${month}-${day}`;
  const [date, setDate] = useState(defaultDate);

  useEffect(() => {
    const id = queryParams.get("id") || null;
    if (!id) {
      return;
    }
    getKassaHistory(id, defaultDate)
      .then((resp) => {
        setEmployeeHistory(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleDateChange = (e) => {
    setDate(e.target.value);
    getKassaHistory(queryParams.get("id"), e.target.value)
      .then((resp) => {
        setEmployeeHistory(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  History
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="top-btn-wrapper">
                  <Link to="/admin/kassafloats">
                    <Button
                      className="btn-fill res-size"
                      type="submit"
                      variant="danger"
                      style={{ border: "none" }}
                    >
                      BACK
                    </Button>
                  </Link>
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="date"
                      className="mt-4"
                      placeholder="FilterBy Date"
                      value={date}
                      onChange={handleDateChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0">Email </th>
                      <th className="border-0">Amount Before</th>
                      <th className="border-0">Transaction Amount</th>
                      <th className="border-0">Amount After</th>
                      <th className="border-0">Description</th>
                      <th className="border-0">Payment Type</th>
                      <th className="border-0">Client ID</th>
                      <th className="border-0">Date</th>
                      <th className="border-0">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeHistory.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.Email}</td>
                          <td>{item.AmountBefore}</td>
                          <td
                            style={
                              item.TransactionAmount[0] == "+"
                                ? { color: "green", fontWeight: "Bold" }
                                : { color: "red", fontWeight: "Bold" }
                            }
                          >
                            {item.TransactionAmount}
                          </td>
                          <td>{item.AmountAfter}</td>
                          <td>{item.Description}</td>
                          <td>
                            {item.paymentType === null
                              ? "null"
                              : item.paymentType}
                          </td>
                          <td>{item.clientID}</td>
                          <td>{item.DateTime.split("T")[0]}</td>
                          <td>{item.Time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
