import { getToken } from "./auth"
import address from "./address"
import axios from "axios"

export function updatePaybackPeriod(rowData) {
    const token = getToken();
    if (!token) return "Authentication Fail Sign In agian";
    return axios.post(address + "/api/paybackPeriod/updatePaybackPeriod", rowData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  }