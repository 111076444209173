import { useEffect, useState } from "react"
import AddBalance from "./addBalance"
import AddIssuance from "./addIssuance"

function RequestForm(props) {
    const [data, setData] = useState(props.location.state.data || null)
    const [requestType, setRequestType] = useState("")
    useEffect(() => {
        if (!data) return;
        const requestData = JSON.parse(data.requestData)
        if (requestData.requestType == "balance") {
            setRequestType("balance")
        }
        else if (requestData.requestType == "issuance") {
            setRequestType("issuance")
        }
    }, [data])
    return <>
        {
            requestType == "balance" ? <AddBalance payload={data} />
                : requestType == "issuance" ? <AddIssuance payload={data} />
                    : <div>
                        Request Type Not Found
                    </div>
        }
    </>

}
export default RequestForm









