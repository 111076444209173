import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Image,
  Note,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LogoImage from "../assets/img/logo/merpol_logo.png";
import { getCurrentDate } from "utils/dateHandler";

class PdfGenPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: ["Initial Item"],
      inputValue: "",
    };
  }

  handelChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  addItem = (event) => {
    event.preventDefault();
    const newItems = this.state.items;
    newItems.push(this.state.inputValue);
    this.setState({
      items: newItems,
      inputValue: "",
    });
  };

  render() {
    const klienteArray = [];

    const styles = StyleSheet.create({
      parent: {
        display: "flex",
        flexDirection: "column",
        padding: "50px",
        paddingBottom: "20px",
        width: "100%",
      },
      table: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: 20,
        paddingBottom: 10,
        gap: 15,
      },
      tablePaymentPeriod: {
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: "20px",
        paddingBottom: "10px",
      },
      paymentPeriod: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        textAlign: "center",
        color: "#000",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      textField: { paddingBottom: "10px", fontSize: "22px" },
      paymentTextField: { paddingBottom: "10px", fontSize: "18px" },
      textFieldKliente: {
        width: "100%",
        paddingBottom: "6px",
        fontSize: "16px",
      },
      textFieldCardDetails: {
        fontSize: 13,
        whiteSpace: "nowrap",
        marginRight: 30,
      },
      img: { width: "60%", margin: "0 auto" },
      mainHeading: {
        paddingTop: "10px",
        paddingBottom: "30px",
        fontStyle: "bold",
        fontSize: "24px",
        fontWeight: "bold",
        borderBottom: "1px solid #264147",
      },
      bottomText: { paddingTop: "50px", fontSize: "18px", textAlign: "center" },
      signatureField: {
        paddingTop: "0px",
        paddingRight: "30px",
        textAlign: "right",
      },
      signatureText: {
        paddingTop: "15px",
        fontStyle: "bold",
        fontSize: "20px",
      },
    });

    const { userName } = this.props;

    const { klienteDetails } = this.props;
    var i = 0;
    klienteDetails.forEach((element) => {
      klienteArray[i] = element.key + ": " + element.value;
      i++;
    });
    const { CardDetails } = this.props;
    var i = 0;
    CardDetails.forEach((element) => {
      CardDetails[i] = element.key + ": " + element.value + " NAF";
      i++;
    });

    const { paybackPeriod } = this.props;

    // this.props.paybackPeriodHistory(paybackPeriod)
    const pdf = (
      <Document>
        <Page wrap>
          <View>
            <Image style={styles.img} src={LogoImage} />
            <View style={styles.parent}>
              <Text>Client Name: {userName.value}</Text>
              <Text style={styles.mainHeading}>Kliente Details:</Text>
              <View style={styles.table}>
                {klienteArray.map((item, index) => (
                  <Text style={styles.textFieldKliente}>{item}</Text>
                ))}
              </View>
              <View style={styles.table}>
                {CardDetails.map((item, index) => (
                  <Text style={styles.textFieldCardDetails}>{item}</Text>
                ))}
              </View>
              <View style={styles.paymentPeriod}>
                <Text style={styles.textField}>
                  Payment Period: {this.props.number}
                </Text>
              </View>
              <View>
                {paybackPeriod && (
                  <View>
                    <Text style={styles.mainHeading}>
                      Payment Period Details:
                    </Text>
                    <View style={styles.tablePaymentPeriod}>
                      <Text style={styles.textField}>
                        Invoice#: {paybackPeriod.invoiceNumber}
                      </Text>
                      <Text style={styles.textField}>
                        Date: {paybackPeriod.date.split("T")[0]}
                      </Text>
                      <Text style={styles.textField}>
                        Paid Amount:{" "}
                        {paybackPeriod.amountPaidByClient -
                          paybackPeriod.amountPaidByClientOriginal +
                          " NAF"}{" "}
                      </Text>
                      {paybackPeriod.paidByDealer && (
                        <Text style={styles.textField}>
                          Dealer Amount: {paybackPeriod.dealerAmount}
                        </Text>
                      )}
                      {paybackPeriod.membershipFee && (
                        <Text style={styles.textField}>
                          Membership Fee: {paybackPeriod.membershipFee}
                        </Text>
                      )}
                    </View>
                  </View>
                )}
              </View>
            </View>

            <View style={styles.signatureField}>
              <Text>__________________</Text>
              <Text style={styles.signatureText}>Signature</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
    return (
      <div style={{ textAlign: "center" }}>
        <PDFDownloadLink
          document={pdf}
          fileName={"Quote" + getCurrentDate().getTime() + ".pdf"}
        >
          {({ blob, url, loading, error }) => (
            // loading ? "Loading . . ." :
            <i
              class="fa fa-download"
              aria-hidden="true"
              style={{ color: "#3aab7b" }}
            ></i>
          )}
        </PDFDownloadLink>
      </div>
    );
  }
}

export default PdfGenPaymentForm;
