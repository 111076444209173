import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";

import axios from "axios";
import address from "../services/address";
import checkUser, { login, verifyOtp } from "../services/auth";
import { FormLabel } from "@mui/material";
import OtpInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Otp = (props) => {
  const history = useHistory();
  const email = "Security@merpol.org" || window.localStorage.getItem("email");
  const [otpInput, setOtpInput] = React.useState();
  const [otpResponse, setOtpResponse] = React.useState(null);
  useEffect(async () => {
    if (checkUser()) {
      history.push("/admin/dashboard");
    }
  }, []);
  async function action(event) {
    event.preventDefault();
    const response = await verifyOtp(otpInput);
    const role = window.localStorage.getItem("role");
    if (response.result.toLocaleLowerCase() == "ok") {
      setOtpResponse({ color: "green", message: "Success" });
      if (role === 1) {
        history.push("/admin/nfccard");
      } else {
        history.push("/admin/dashboard");
      }
    } else if (response.result.toLocaleLowerCase() == "error") {
      setOtpResponse({ color: "red", message: response.message });
    }
  }
  const classes = useStyles({});
  const [formData, setFormData] = React.useState({ email: "", password: "" });
  const [submitting, setSubmitting] = React.useState(false);
  return (
    <main className={classes.layout + " loginform"}>
      <Paper className={classes.paper} elevation={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography component="h5" variant="h4" gutterBottom>
            Enter the Authentication Code
          </Typography>
          <Typography component="p" gutterBottom>
            {/* Sent to {email} */}
          </Typography>
        </Box>
        <form method="post" className={classes.form} noValidate>
          <OtpInput
            value={otpInput}
            onChange={(e) => setOtpInput(e)}
            numInputs={6}
            inputStyle={{
              fontSize: "3rem",
              marginLeft: "1.7rem",
              marginRight: "1.5rem",
            }}
            separator={<span>-</span>}
          />

          <Box mt={3} mb={-3}>
            <Button
              onClick={action}
              disabled={submitting}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {submitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {submitting ? "Verifying..." : "Verify"}
            </Button>
          </Box>
          {otpResponse && (
            <h5
              style={{
                color: otpResponse.color,
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              {" "}
              {otpResponse.message}
            </h5>
          )}
        </form>
      </Paper>
    </main>
  );
};

export default Otp;
