import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getRemainingPayBacksList } from "services/client";
import Grid from "@mui/material/Grid";
export default function PaybackPeriodHistoryList() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const history = useHistory();

  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);
  // Load Paybacks From DB

  useEffect(() => {
    setIsLoading(true);
    setFilterTableData([]);
    getRemainingPayBacksList()
      .then((response) => {
        setTableData(response.data.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Search Paybacks
  useEffect(() => {
    let tempTable = [];

    tableData.map((item) => {
      if (
        item.code.includes(toSearch.toLowerCase()) ||
        item.Email.includes(toSearch.toLowerCase()) ||
        item.ContactNo.includes(toSearch.toLowerCase())
      ) {
      } else {
        tempTable.push(item);
      }
    });

    setFilterTableData(tempTable);
  }, [toSearch]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card.Title as="h3" className="heading">
                      Remaining PayBacks
                    </Card.Title>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className="btn-fill res-size"
                      variant="danger"
                      style={{ border: "none", float: "right" }}
                      onClick={() => {
                        history.push(`ClientList`);
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0">Code</th>
                      <th className="border-0">FirstName</th>
                      <th className="border-0">lastName</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">phoneNumber</th>
                      <th className="border-0">address</th>
                      <th className="border-0">Issuance Date</th>
                      <th className="border-0">date of PayBack</th>
                      <th className="border-0">Amount</th>
                      <th className="border-0">AmountPaidByClient</th>
                      <th className="border-0">Remaining Amount</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        minHeight: "100px",
                      }}
                    >
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  ) : (
                    <tbody>
                      {tableData.map((item, index) => {
                        if (filterTableData.includes(item)) {
                          return;
                        }
                        return (
                          <tr key={index}>
                            <td>{item.code}</td>
                            <td>{item.FirstName}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.LastName}
                            </td>
                            <td>{item.Email}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.ContactNo}{" "}
                            </td>
                            <td>{item.address} </td>

                            <td>{item.issuanceDate}</td>
                            <td>{item.payBackDate}</td>
                            <td>{item.Amount}</td>
                            <td>{item.amountPaidByClient}</td>

                            <td>{item.remaining}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
