// Importing Views
import Dashboard from "./views/Dashboard";
import kassaFloats from "./views/kassaFloats";
import addKassaForm from "./views/addKassaForm";
import kassaHistory from "./views/kassaHistory";
import NfcCard from "./views/NfcCard";
import addNfcCard from "./views/addNfcCard";
import Device from "./views/Device";
import addDevice from "./views/addDevice";
import Groups from "./views/Groups";
import addGroups from "./views/addGroups";
import Register from "./views/Register";
import addRegister from "./views/addRegister";
import ClientForm from "./views/ClientForm";
import IssuanceHistory from "./views/IssuanceHistory";
import MerchantTypeList from "./views/MerchantTypeList";
import MerchantList from "./views/MerchantList";
import MerchantForm from "./views/MerchantForm";
import MerchantTypeForm from "./views/MerchantTypeForm";
import PendingPaymentList from "./views/PendingPaymentList";
import IssuanceCardForm from "./views/issuanceCardForm";
import ClientList from "./views/ClientList";
import PendingClientList from "./views/PendingClientList";
import RemainingPayBackClientList from "./views/RemainingPayBackClientList";
import BlockedClientList from "./views/BlockedClientList";
import PaymentForm from "./views/paymentForm";
import TransactionHistory from "./views/transactionHistory";
import MembershipHistory from "./views/membershipHistory";
import MembershipForm from "./views/membershipForm";
import BonIssuanceForm from "./views/bonIssuanceForm";
import BonPaymentForm from "./views/bonPaymentForm";
import Reports from "./views/reports";
import AddBalanceIssuanceHistory from "./views/addBalanceIssuanceHistory";
import DealerBulkPaymentList from "views/dealerBulkPaymentList";
import DealerBulkPaymentForm from "views/dealerBulkPaymentForm";
import MerchantGroupForm from "views/MerchantGroupForm";
import MerchantGroupList from "views/MerchantGroupList";
import BalanceHistoryList from "views/BalanceHistoryList";
import PaybackPeriodHistoryList from "views/PaybackPeriodHistoryList";
import PaymentFormRevamp from "views/PaymentFormRevamp";
import GiftCardList from "views/GiftCardsList";
import GiftCardIssuance from "views/GiftCardIssuance";
import RequestList from "views/clientInbox/RequestList";
import RequestForm from "views/clientInbox/requestForm";
import ClientPrepaidForm from "views/ClientPrepaidForm";
import AllPayBackPeriodList from "views/AllPayBackHistory";
import CurrentYearDebt from "views/currentYearDebt";
import PaymentTab from "./views/paymentTab/TransactionIndex";
import TransactionHistoryCurrentYear from "views/TransactionHistorycurrentYear";
// Preparing the routes of dashboard
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/requestList",
    name: "Request List ",
    icon: "nc-icon nc-circle-09",
    component: RequestList,
    layout: "/admin",
  },
  {
    path: "/requestForm",
    name: "Request Form ",
    icon: "nc-icon nc-circle-09",
    component: RequestForm,
    layout: "/admin",
  },

  {
    path: "/nfccard",
    name: "NFC Card",
    icon: "nc-icon nc-credit-card",
    component: NfcCard,
    layout: "/admin",
  },
  {
    path: "/addnfccard",
    name: "NFC Card",
    icon: "nc-icon nc-credit-card",
    component: addNfcCard,
    layout: "/admin",
  },
  {
    path: "/PrepaidCardList",
    name: "Prepaid Card",
    icon: "nc-icon nc-credit-card",
    component: GiftCardList,
    layout: "/admin",
  },
  {
    path: "/kassafloats",
    name: "Kassa Floats",
    icon: "nc-icon nc-money-coins",
    component: kassaFloats,
    layout: "/admin",
  },
  {
    path: "/addKassaForm",
    name: "Kassa Floats",
    icon: "nc-icon nc-money-coins",
    component: addKassaForm,
    layout: "/admin",
  },
  {
    path: "/kassaHistory",
    name: "History ",
    icon: "nc-icon nc-notes",
    component: kassaHistory,
    layout: "/admin",
  },
  {
    path: "/device",
    name: "Device",
    icon: "nc-icon nc-mobile",
    component: Device,
    layout: "/admin",
  },
  {
    path: "/adddevice",
    name: "Device",
    icon: "nc-icon nc-mobile",
    component: addDevice,
    layout: "/admin",
  },
  //{ path: "/groups", name: "Groups", icon: "nc-icon nc-circle-09", component: Groups, layout: "/admin" },
  {
    path: "/addgroups",
    name: "Groups",
    icon: "nc-icon nc-mobile",
    component: addGroups,
    layout: "/admin",
  },
  {
    path: "/register",
    name: "Usuarionan",
    icon: "nc-icon nc-single-02",
    component: Register,
    layout: "/admin",
  },
  {
    path: "/addregister",
    name: "Registra Usuario",
    icon: "nc-icon nc-single-02",
    component: addRegister,
    layout: "/admin",
  },
  {
    path: "/ClientList",
    name: "Lista di Kliente",
    icon: "nc-icon nc-notes",
    component: ClientList,
    layout: "/admin",
  },
  {
    path: "/pendingClientList",
    name: "LISTA di Kliente (Pending)",
    icon: "nc-icon nc-notes",
    component: PendingClientList,
    layout: "/admin",
  },
  {
    path: "/blockedClientList",
    name: "LISTA di Kliente (Blocked)",
    icon: "nc-icon nc-notes",
    component: BlockedClientList,
    layout: "/admin",
  },
  {
    path: "/remainingPayBackClientsList",
    name: "LISTA di Kliente (paybacks)",
    icon: "nc-icon nc-notes",
    component: RemainingPayBackClientList,
    layout: "/admin",
  },
  {
    path: "/ClientForm",
    name: "Agrega Kliente",
    icon: "nc-icon nc-circle-09",
    component: ClientForm,
    layout: "/admin",
  },
  {
    path: "/ClientPrepaidForm",
    name: "Agrega Kliente",
    icon: "nc-icon nc-circle-09",
    component: ClientPrepaidForm,
    layout: "/admin",
  },
  {
    path: "/MerchantList",
    name: "Merchants",
    icon: "nc-icon nc-notes",
    component: MerchantList,
    layout: "/admin",
  },
  {
    path: "/MerchantForm",
    name: "Agrega Merchant",
    icon: "nc-icon nc-circle-09",
    component: MerchantForm,
    layout: "/admin",
  },
  {
    path: "/MerchantTypeList",
    name: "Merchant Types ",
    icon: "nc-icon nc-notes",
    component: MerchantTypeList,
    layout: "/admin",
  },
  {
    path: "/MerchantTypeForm",
    name: "Agrega Merchant Type",
    icon: "nc-icon nc-circle-09",
    component: MerchantTypeForm,
    layout: "/admin",
  },
  {
    path: "/PendingPaymentList",
    name: "Pending Payments ",
    icon: "nc-icon nc-notes",
    component: PendingPaymentList,
    layout: "/admin",
  },
  {
    path: "/IssuanceHistory",
    name: "Issuance History",
    icon: "nc-icon nc-circle-09",
    component: IssuanceHistory,
    layout: "/admin",
  },

  {
    path: "/IssuanceCardForm",
    name: "Issue Card",
    icon: "nc-icon nc-circle-09",
    component: IssuanceCardForm,
    layout: "/admin",
  },
  {
    path: "/PaymentForm",
    name: "Payment Form",
    icon: "nc-icon nc-circle-09",
    component: PaymentFormRevamp,
    layout: "/admin",
  },

  {
    path: "/addBalanceForm",
    name: "Add Balance Form",
    icon: "nc-icon nc-circle-09",
    component: AddBalanceIssuanceHistory,
    layout: "/admin",
  },
  {
    path: "/TransactionHistory",
    name: "Transaction History",
    icon: "nc-icon nc-circle-09",
    component: TransactionHistory,
    layout: "/admin",
  },
  {
    path: "/currentYearTransactionHistory",
    name: "current Year Transaction History",
    icon: "nc-icon nc-circle-09",
    component: TransactionHistoryCurrentYear,
    layout: "/admin",
  },
  {
    path: "/MembershipHistory",
    name: "Membership History",
    icon: "nc-icon nc-circle-09",
    component: MembershipHistory,
    layout: "/admin",
  },
  {
    path: "/addMembership",
    name: "Add Membership",
    icon: "nc-icon nc-circle-09",
    component: MembershipForm,
    layout: "/admin",
  },
  {
    path: "/dealerBulkPaymentList",
    name: "Bulk Payment",
    icon: "nc-icon nc-circle-09",
    component: DealerBulkPaymentList,
    layout: "/admin",
  },
  {
    path: "/dealerBulkPaymentForm",
    name: "Bulk Payment",
    icon: "nc-icon nc-circle-09",
    component: DealerBulkPaymentForm,
    layout: "/admin",
  },
  {
    path: "/MerchantGroupForm",
    name: "Merchant Group",
    icon: "nc-icon nc-circle-09",
    component: MerchantGroupForm,
    layout: "/admin",
  },
  {
    path: "/MerchantGroupList",
    name: "Merchant Group",
    icon: "nc-icon nc-circle-09",
    component: MerchantGroupList,
    layout: "/admin",
  },
  {
    path: "/BalanceHistoryList",
    name: "Balance History ",
    icon: "nc-icon nc-circle-09",
    component: BalanceHistoryList,
    layout: "/admin",
  },
  {
    path: "/PaybackPeriodHistoryList",
    name: "Paybackperiod History ",
    icon: "nc-icon nc-circle-09",
    component: PaybackPeriodHistoryList,
    layout: "/admin",
  },
  {
    path: "/AllPaybackPeriodHistoryList",
    name: "Paybackperiod History ",
    icon: "nc-icon nc-circle-09",
    component: AllPayBackPeriodList,
    layout: "/admin",
  },

  {
    path: "/issuePrepaidCard",
    name: "Issue Prepaid Card",
    icon: "nc-icon nc-circle-09",
    component: GiftCardIssuance,
    layout: "/admin",
  },
  {
    path: "/currentYearDebt",
    name: " Remaining PayBacks",
    icon: "nc-icon nc-circle-09",
    component: CurrentYearDebt,
    layout: "/admin",
  },
  {
    path: "/Reports",
    name: "Reports",
    icon: "nc-icon nc-notes",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Pagafasil",
    icon: "nc-icon nc-credit-card",
    component: PaymentTab,
    layout: "/admin",
  },
  // { path: "/IssuanceBon", name: "Bon Issuance", icon: "nc-icon nc-circle-09", component: BonIssuanceForm, layout: "/admin" },
  // { path: "/BonPayment", name: "Bon Payment", icon: "nc-icon nc-circle-09", component: BonPaymentForm, layout: "/admin" },
];

export default dashboardRoutes;
