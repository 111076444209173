import React from "react";
import { useEffect } from "react";
import { getClientData } from "services/client";
import { getInsuranceAmountByIssuanceHistoryId } from "services/insurance";
import { getIssuanceHistoryPaybackPeriod } from "services/issuanceHistory";
import { getissuancehistoryByID } from "services/issuanceHistory";
import { getSumOfTransactionsByIssuanceHistoryId } from "services/transaction";
import { getCurrentDate } from "utils/dateHandler";
import { getUniqueString } from "utils/getUniqueString";
import Paybackperiods from "./Paybackperiods";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { ifNull } from "utils/dateHandler";
import { useHistory } from "react-router-dom";
import { upsertIssuanceHistory } from "services/issuanceHistory";
import { formatDate } from "utils/dateHandler";

const myCurrency = "NAF";

export default function PaymentFormRevamp() {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const [paybackPeriods, setPaybackPeriods] = React.useState([]);
  const [selectedPayback, setSelectedPayback] = React.useState(null);
  const [paymentData, setpaymentData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);


  //Begin = helper Functions
  const getDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const callApi = async (func, parameter) => {
    try {
      return await func(parameter);
    } catch (e) {
    }
    return null;
  };
  //end
  const getTotalAmount = (paybackPeriods) => {
    let total = 0;
    paybackPeriods.map((itm, idx) => {
      total += ifNull(itm.amountPaidByClient);
    });
    return total;
  };

  //Begin = handling Initial fetching Responses
  const getDealerAmount = (amount) => {
    let date = getCurrentDate().getDate();
    let dealerAmount = 0;
    const amountPaidByClient = ifNull(amount);
    if (date >= 1 && date <= 10) {
      dealerAmount = (amountPaidByClient * 5) / 100;
    } else if (date >= 11 && date <= 15) {
      dealerAmount = (amountPaidByClient * 3.5) / 100;
    } else if (date >= 16 && date <= 20) {
      dealerAmount = (amountPaidByClient * 2) / 100;
    } else if (date >= 21 && date <= 25) {
      dealerAmount = (amountPaidByClient * 0.5) / 100;
    } else {
      dealerAmount = (amountPaidByClient * 0) / 100;
    }
    return dealerAmount;
  };

  const handlePaybackPeriodResponse = (data) => {
    if (!data) return;
    data.sort(
      (a, b) =>
        new Date(a.date.split("T")[0].split("-")) -
        new Date(b.date.split("T")[0].split("-"))
    );
    data.sort((a, b) => a.type - b.type);
    const today = getCurrentDate().toISOString().split("T")[0];
    const paybackPeriods = data.map((item, index) => {
      item.invoiceNumber = getUniqueString();
      item.TypeOfReturnPayment = "Cash";
      item.dateDeposit = today; 
      if (item.PaidByDealer) {
        item.DealerAmount = getDealerAmount(item.amountPaidByClient);
        item.amountPaidToDealer = item.DealerAmount;
      }
      item.amountPaidByClientOriginal = ifNull(item.amountPaidByClient);
      item.amountPaidByClient = 0;
      item.amount = ifNull(item.amount).toFixed(2);
      item.amountPaidToDealer = ifNull(item.amountPaidToDealer).toFixed(2);
      return item;
    });
    setPaybackPeriods(paybackPeriods);
  };

  const handleIssuanceResponse = async (data) => {
    let response = null;

    response = await callApi(getClientData, data.Client_id);
    let clientData = response ? { userName: response.data.FirstName } : null;

    response = await callApi(getIssuanceHistoryPaybackPeriod, data.id);
    response && handlePaybackPeriodResponse(response.data.data);
    return clientData;
  };
  //end

  //Begin = inital fetch
  const fetchData = async (params) => {
    let response = null;

    response = await callApi(
      getInsuranceAmountByIssuanceHistoryId,
      params.issuanceHistoryId
    );
    let insAmount = response
      ? { insuranceAmount: response.data.data ? response.data.data.amount : 0 }
      : null;

    response = await callApi(
      getSumOfTransactionsByIssuanceHistoryId,
      params.issuanceHistoryId
    );
    let sumOfTransaction = response
      ? { amountUsed: response.data.amount || 0 }
      : null;

    response = await callApi(getissuancehistoryByID, params.issuanceHistoryId);
    let issuanceHistory =
      response && response.data
        ? {
            ...response.data,
            TypeOfReturnPayment: "Cash",
            DateDeposit: getDate(getCurrentDate()),
          }
        : null;

    let clientData = null;
    if (issuanceHistory)
      clientData = await handleIssuanceResponse(response.data);
    setpaymentData({
      ...insAmount,
      ...sumOfTransaction,
      ...issuanceHistory,
      ...clientData,
    });
  };
  //end

  useEffect(async () => {
    const params = {
      issuanceHistoryId: queryParams.get("id"),
      clientId: queryParams.get("cid"),
    };
    if (params.issuanceHistoryId && params.clientId) {
      await fetchData(params);
    }
  }, []);

  const ifDisable = (period) => {
    if (!selectedPayback || !period) return false;
    if (selectedPayback.Period.id == period.id) return true;
    return false;
  };

  const reFetch = async () => {
    const params = {
      issuanceHistoryId: queryParams.get("id"),
      clientId: queryParams.get("cid"),
    };
    if (params.issuanceHistoryId && params.clientId) {
      await fetchData(params);
    }
  };

  const updatePaybackPeriodState = async (newPaybackPeriod) => {
    let updatedPaybackPeriods = [...paybackPeriods].map((item) => {
      if (item.id == newPaybackPeriod.id) return newPaybackPeriod;
      return item;
    });
    const totalAmount = getTotalAmount(updatedPaybackPeriods);
    await callApi(upsertIssuanceHistory, {
      id: updatedPaybackPeriods[0].issuanceHistory_Id,
      TypeOfReturnPayment: paymentData.TypeOfReturnPayment,
      AmountPaid: totalAmount,
    });
    setPaybackPeriods([...updatedPaybackPeriods]);
    alert("Succeeded");
    setSelectedPayback(null);
    reFetch();
  };
  return (
    <>
      {paymentData && (
        <Container>
          <Row className="justify-content-center">
            <Col md="10" className="main-container">
              <Card className="form-wrapper mt-4" style={{ border: "none" }}>
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title
                    as="h4"
                    className="text-center m-3 client-heading"
                  >
                    Payment Form
                  </Card.Title>
                  <Button
                    className="btn-fill res-size "
                    variant="danger"
                    style={{ border: "none" }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="4">
                      <Form.Group>
                        <label>Issued Date</label>
                        <Form.Control
                          type="text"
                          value={formatDate(paymentData.DateTime.split("T")[0])}
                          name="DateTime"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="4">
                      <Form.Group>
                        <label>Kliente</label>
                        <Form.Control
                          value={paymentData.Client_id}
                          type="text"
                          name="Client"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="4">
                      <Form.Group>
                        <label>NFC Card</label>
                        <Form.Control
                          value={paymentData.NfcCard_id}
                          name="NfcCard_id"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group>
                        <label>Amount Given</label>
                        <Form.Control
                          value={`${myCurrency}${parseFloat(
                            paymentData.Amount
                          ).toFixed(2)}`}
                          name="Amount"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group>
                        <label className="requiredelement">Amount Paid</label>
                        <Form.Control
                          placeholder="Enter Amount"
                          value={
                            paymentData.AmountPaid
                              ? `${myCurrency}${parseFloat(
                                  paymentData.AmountPaid
                                ).toFixed(2)}`
                              : `${myCurrency}0`
                          }
                          name="AmountPaid"
                          disabled
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group>
                        <label>Insurance Amount</label>
                        <Form.Control
                          value={`${myCurrency}${parseFloat(
                            paymentData.insuranceAmount
                          ).toFixed(2)}`}
                          name="Insurance Amount"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group>
                        <label>Amount Due</label>
                        <Form.Control
                          value={`${myCurrency}${parseFloat(
                            paymentData.Amount
                          ).toFixed(2)}`}
                          name="Amount_Due"
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group>
                        <label className="requiredelement">Amount Used</label>
                        <Form.Control
                          placeholder="Enter Amount"
                          value={`${myCurrency}${parseFloat(
                            paymentData.amountUsed
                          ).toFixed(2)}`}
                          name="AmountUsed"
                          disabled
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group>
                        <label className="requiredelement">Balance</label>
                        <Form.Control
                          placeholder="Enter Amount"
                          value={`${myCurrency}${parseFloat(
                            paymentData.Balance
                          ).toFixed(2)}`}
                          name="Balance"
                          disabled
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {paybackPeriods.length &&
                    paybackPeriods.map((item, index) => {
                      return (
                        <Button
                          disabled={ifDisable(item)}
                          className="btn-fill res-size mr-2 mb-2"
                          onClick={() =>
                            setSelectedPayback({ Period: item, index })
                          }
                          style={{ backgroundColor: "#22577E", border: "none" }}
                        >
                          Payback Period{" "}
                          {item.type == 5 ? "Prepaid" : index + 1}
                        </Button>
                      );
                    })}
                  <Button
                    className="btn res-size mr-2"
                    onClick={() => setSelectedPayback(null)}
                  >
                    <i className="fa fa-times" aria-hidden="true">
                      {" "}
                    </i>
                  </Button>

                  {selectedPayback && (
                    <Paybackperiods
                      updatePaybackPeriodState={updatePaybackPeriodState}
                      myCurrency={myCurrency}
                      paymentData={paymentData}
                      paybackPeriodProp={selectedPayback.Period}
                      number={selectedPayback.index + 1}
                      getDealerAmount={getDealerAmount}
                     
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
