import { getToken } from "./auth"
import address from "./address"
import axios from "axios";

export default function getKassaHistory(userId,date) {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/kassaHistory/getKassaHistory/' + userId + '?date='+date, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}