import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Tooltip } from "../components/Tooltip";
//          Importing Services
import checkUser from "../services/auth";
import getClientList from "../services/client";
import {
  deleteClient,
  updateClient,
  deleteClientImage,
  deleteClientBankStatement,
  deleteClientSalarySlips,
} from "../services/client";
import { toast, ToastContainer } from "react-toastify";
import { getAllIssuanceHistories } from "../services/issuanceHistory";
function ClientList() {
  let userRole = JSON.parse(localStorage.getItem("role"));
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([
    {
      Checked: false,
      id: "",
      Code: "",
      FirstName: "",
      LastName: "",
      WorkNo: "",
      ContactNo: "",
      WorksAt: "",
      Email: "",
      FaxNumber: "",
      Status: 0,
      MaxBorrowAmount: "",
      Dealer_id: "",
    },
  ]);
  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("id") || null;
  const history = useHistory();
  const [toSearch, setToSearch] = React.useState("");

  const [filterTableData, setFilterTableData] = React.useState([]);

  // Load Clients From DB
  useEffect(() => {
    setIsLoading(true);
    setFilterTableData([]);
    getClientList()
      .then((response) => {
        setTableData(response.data);
        if (params) {
          setToSearch(params);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const updateLocalStorage = (value) => {
    localStorage.setItem("toSearch", value);
  };

  const getLocalStorageValue = () => {
    return localStorage.getItem("toSearch") || "";
  };

  useEffect(() => {
    const storedToSearch = getLocalStorageValue();
    if (storedToSearch) {
      setToSearch(storedToSearch);
    }
  }, []);

  // Search Clients
  React.useEffect(() => {
    let tempTable = [];
    tableData.map((item) => {
      const Dealer_id = item?.Dealer_id?.toLowerCase();
      const Code = item?.Code?.toLowerCase();
      const LastName = item?.LastName?.toLowerCase();
      const FirstName = item?.FirstName?.toLowerCase();
      const Email = item?.Email?.toLowerCase();
      if (
        (Dealer_id && Dealer_id.includes(toSearch.toLowerCase())) ||
        (Code && Code.includes(toSearch.toLowerCase())) ||
        (LastName && LastName.includes(toSearch.toLowerCase())) ||
        (FirstName && FirstName.includes(toSearch.toLowerCase())) ||
        (Email && Email.includes(toSearch.toLowerCase()))
      ) {
      } else {
        tempTable.push(item);
      }
    });
    setFilterTableData(tempTable);

    updateLocalStorage(toSearch);
  }, [toSearch, tableData]);

  const toggleStatus = (index) => {
    let tempTable = [...tableData];

    tempTable[index].Status =
      tempTable.Status == 2 ? 1 : !tempTable[index].Status;
    updateClient(tempTable[index])
      .then((response) => {})
      .catch((error) => {});
    setTableData(tempTable);
  };

  // Sorting Table
  function sortHandler(a, b) {
    if (a.Code < b.Code) {
      return -1;
    }
    if (a.Code > b.Code) {
      return 1;
    }
    return 0;
  }
  const sortedArrayData = tableData;
  sortedArrayData.sort(sortHandler);
  const deleteRow = (itemToDelete) => {
    deleteClientBankStatement(itemToDelete)
      .then((response) => {
        deleteClientSalarySlips(itemToDelete)
          .then((response) => {
            deleteClientImage(itemToDelete)
              .then((response) => {
                deleteClient(itemToDelete)
                  .then((response) => {
                    window.location.reload();
                  })
                  .catch((error) => {});
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
    setTableData(tableData.filter((item, index) => item?.id !== itemToDelete));
  };

  const issuanceHistoryCronJob = () => {
    getAllIssuanceHistories()
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            "issuanceHistory cron job run successfully Successfully",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          toast.error("Failed to run issuanceHistory cronjob!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error("An error occurred while adding.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  LISTA di Kliente
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{ backgroundColor: "#3AAB7B", border: "none" }}
                    onClick={() => history.push("/admin/ClientForm")}
                  >
                    ADD
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    variant="info"
                    style={{ border: "none" }}
                    onClick={() => {
                      setTableData(
                        tableData.map((item) => {
                          if (item.Checked === true) {
                            item.Status = true;
                            updateClient(item)
                              .then((response) => {})
                              .catch((error) => {});
                            item.Checked = false;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    Active
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    variant="danger"
                    style={{ border: "none" }}
                    onClick={() => {
                      setTableData(
                        tableData.map((item) => {
                          if (item.Checked === true) {
                            item.Status = false;
                            updateClient(item)
                              .then((response) => {})
                              .catch((error) => {});
                            item.Checked = false;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    Block
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{ backgroundColor: "#22577E", border: "none" }}
                    onClick={() => history.push("/admin/pendingClientList")}
                  >
                    New Clients
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    variant="danger"
                    onClick={() => history.push("/admin/blockedClientList")}
                  >
                    Blocked Clients
                  </Button>
                  {(userRole === 0 || userRole === 5) && (
                    <Button
                      className="btn-fill res-size"
                      type="submit"
                      style={{ backgroundColor: "#22577E", border: "none" }}
                      onClick={() =>
                        history.push("/admin/remainingPayBackClientsList")
                      }
                    >
                      Remaining PayBack List
                    </Button>
                  )}

                  {/* <Button
                    className="btn-fill res-size"
                    variant="danger"
                    onClick={issuanceHistoryCronJob}
                  >
                    cron Job
                  </Button> */}
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      value={toSearch}
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0">Code</th>
                      <th className="border-0">Nomber</th>
                      <th className="border-0">Fam</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Celullar</th>
                      <th className="border-0">Ta traha na</th>
                      <th className="border-0">Dealer</th>

                      <th className="border-0">Kredito Maksimo</th>

                      <th className="border-0">Status</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        minHeight: "100px",
                      }}
                    >
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  ) : (
                    <tbody>
                      {tableData
                        .filter(
                          (item) =>
                            !(
                              (userRole !== 0 || userRole !== 5) &&
                              item.id.startsWith("i")
                            )
                        )
                        .filter(
                          (item) => !(userRole === 3 && item.id === "M-0006")
                        )
                        .filter((item) => !filterTableData.includes(item))
                        .filter((item) => item.Status === 1)
                        .map((item, index) => (
                          <tr
                            key={index}
                            style={
                              item.Status == 2
                                ? { backgroundColor: "#7FB5A2" }
                                : null
                            }
                          >
                            <td>
                              {" "}
                              <Form.Control
                                placeholder="Fax"
                                type="checkbox"
                                checked={item.Checked}
                                onChange={() => {
                                  let temp = [...tableData];
                                  const itemIndex = temp.findIndex(
                                    (tempItem) => tempItem.id === item.id
                                  );

                                  if (itemIndex !== -1) {
                                    temp[itemIndex].Checked =
                                      !temp[itemIndex].Checked;
                                    setTableData(temp);
                                  }
                                }}
                                style={{ width: "16px" }}
                              ></Form.Control>
                            </td>
                            <td>{item.Code}</td>
                            <td>{item.FirstName}</td>
                            <td>{item.LastName}</td>
                            <td>{item.Email}</td>
                            <td>{item.ContactNo}</td>
                            <td>{item.WorksAt}</td>
                            <td>{item.Dealer_id}</td>

                            {item.Code == "M-1010" && userRole == 3 ? (
                              <td>{""}</td>
                            ) : (
                              <td>{item.MaxBorrowAmount}</td>
                            )}

                            <td>
                              {item.Status == 1 ? (
                                <Button
                                  onClick={() => {
                                    let temp = [...tableData];
                                    const itemIndex = temp.findIndex(
                                      (tempItem) => tempItem.id === item.id
                                    );
                                    toggleStatus(itemIndex);
                                  }}
                                  style={{ border: "none" }}
                                >
                                  <i
                                    className="fa fa-toggle-on"
                                    style={{
                                      color: "green",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Button>
                              ) : item.Status == 2 ? (
                                <Button
                                  onClick={() => toggleStatus(index)}
                                  style={{ border: "none" }}
                                >
                                  <i
                                    className="fa fa-hourglass"
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => toggleStatus(index)}
                                  style={{ border: "none" }}
                                >
                                  <i
                                    className="fa fa-ban"
                                    style={{
                                      color: "red",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Button>
                              )}
                            </td>
                            <td className="actions-styling">
                              <Tooltip
                                position="bottom"
                                message="Issuance History"
                              >
                                <i
                                  className="fas fa-history action-childs"
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    color: "gray",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      "/admin/IssuanceHistory/?id=" + item.id
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                position="bottom"
                                message="Transaction History"
                              >
                                <i
                                  className="fas fa-money-bill-alt action-childs"
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    color: "gray",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      "/admin/TransactionHistory/?id=" + item.id
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip position="bottom" message="Memberships">
                                <i
                                  className="fas fa-address-card action-childs"
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    color: "gray",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      "/admin/MembershipHistory/?id=" + item.id
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                position="bottom"
                                message="PayBackPeriod History"
                              >
                                <i
                                  className="fas fa-chart-line action-childs"
                                  style={{
                                    color: "black",
                                    cursor: "pointer",
                                    color: "gray",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      `/admin/AllPaybackPeriodHistoryList?id=${item.id}`
                                    )
                                  }
                                />
                              </Tooltip>

                              <i
                                className="fa fa-edit action-childs"
                                style={{ color: "green", cursor: "pointer" }}
                                onClick={() => {
                                  if (item.id.includes("P-")) {
                                    history.push("/admin/ClientPrepaidForm", {
                                      clientID: item.id,
                                    });
                                  } else {
                                    history.push(
                                      `/admin/ClientForm/?id=${item.id}`
                                    );
                                  }
                                }}
                              />
                              <i
                                className="fa fa-trash action-childs"
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete?"
                                    )
                                  )
                                    deleteRow(item.id);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </Table>
              </Card.Body>
            </Card>
            <ToastContainer />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ClientList;
