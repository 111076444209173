import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function getTop3MerchantsByTransaction() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/topThreeMerchantsByTransaction", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getTotalKassaFloatYesterday() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/totalKassaFloatYesterday", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getTotalSalesOfEachMerchant(limit, page) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(
    address + "/api/dashboard/merchant/sale?limit=" + limit + "&page=" + page,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getMonthlyTotalProfit() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/monthlyTotalProfit", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getTotalMembership(date = null) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/membership/sum?date=" + date, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getTodaysTotalProfit() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/todaysTotalProfit", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getCurrentDateTransaction() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/currentDateTransaction", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getPaidByClientToday() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/paidByClientToday", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getTodaysTransaction() {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/dashboard/todaysTransaction", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllNotifications() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/dashboard/getAllNotifications", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function updateNotification(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(address + "/api/dashboard/upsertNotification", formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
