import React, { useEffect, useRef, useState } from "react";
//          Importing Routes
import { useLocation, Route, Switch, useHistory } from "react-router-dom";
//          Importing Images
import sidebarImage from "../assets/img/sidebar-3.jpg";
//          Importing Components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
//          Importing Services
import checkUser from "../services/auth";
import routes from "../routes";
import io from 'socket.io-client';
import address from "services/address";

const socket = io(address);
function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const history = useHistory();

  const timer = useRef();
  const logout = () => {
    window.localStorage.removeItem("token")
    window.localStorage.removeItem("refreshToken")
    window.localStorage.removeItem("expiryDate")
    window.localStorage.removeItem("id")
    window.localStorage.removeItem("role")
  }
  const timeoutTime = 1000 * 60 * 60 //in ms
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState(null);

  useEffect(() => {
    timer.current = setTimeout(() => {
      logout()
      history.push('/login')
    }, timeoutTime)

    window.addEventListener('keypress', (event) => {
      
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        logout()
        history.push('/login')
      }, timeoutTime)
    });
    window.addEventListener('click', (event) => {
     

      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        logout()
        history.push('/login')
      }, timeoutTime)
    });
    socket.on('connect', () => {
      setIsConnected(true);
    });
    socket.on("newClientRequest", (arg) => {
    });
    socket.on('disconnect', () => {
      setIsConnected(false);
    });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('newClientRequest')
    };

  }, [])

  const sendPing = () => {
    socket.emit('ping');
  }

  const getRoutes = (routes) => {
    const auth = checkUser()
    if (!auth) {
      history.push('/login')
      window.location.reload(false)
    }
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} socket={socket} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });

  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} socket={socket} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
