import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Components
import { Card, Table, Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
//          Importing Services
import { getTransactionhistoryCurrentYearByClientId } from "../services/issuanceHistory";

function TransactionHistoryCurrentYear() {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [transactionHistory, setTransacionHistory] = React.useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const idParam = queryParams.get("id");
  const issuanceIdParam = queryParams.get("issuanceId");

  useEffect(() => {
    setIsLoading(true);

    let formData = {
      id: idParam,
      issuanceId: issuanceIdParam,
    };

    getTransactionhistoryCurrentYearByClientId(formData)
      .then((res) => {
        res.data.data.sort((a, b) =>
          (b.Date + b.Time).localeCompare(a.Date + a.Time)
        );

        // res.data.data.sort((a, b) => new Date(...a.dateTime.split("T")[0].split('-').reverse()) + new Date(...b.dateTime.split("T")[0].split('-').reverse()));
        setTransacionHistory(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [idParam, issuanceIdParam]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card.Title as="h3" className="heading">
                      Transaction History
                    </Card.Title>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className="btn-fill res-size"
                      variant="danger"
                      style={{ border: "none", float: "right" }}
                      onClick={() => {
                        history.push(`/admin/currentYearDebt`);
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover mt-3">
                  <thead>
                    <tr>
                      <th className="border-0">Client</th>
                      <th className="border-0">Merchant</th>
                      <th className="border-0">Date</th>
                      <th className="border-0">Time</th>
                      <th className="border-0">Transaction Type</th>
                      <th className="border-0">Amount</th>
                      <th className="border-0">Payback Period</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading === false ? (
                      transactionHistory.length > 0 ? (
                        transactionHistory.map((item, index) => (
                          <tr key={index}>
                            <td>{item.Client_id}</td>
                            <td>{item.Name}</td>
                            <td>{item.Date}</td>
                            <td>{item.Time}</td>
                            <td>
                              {item.transactionType == 1 ? "Expense" : "Retour"}
                            </td>
                            <td>
                              {item.transactionType == 2
                                ? -1 * parseFloat(item.Amount).toFixed(2)
                                : parseFloat(item.Amount).toFixed(2)}
                            </td>
                            <td>{item.PaybackMonths}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">
                            <h5 style={{ textAlign: "center" }}>
                              No transaction history to show
                            </h5>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <h5 style={{ textAlign: "center" }}>Loading...</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default TransactionHistoryCurrentYear;
