import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function getMerchantHistory(date, period) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/merchant/" + date + "&" + period, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getSupermarketReport(date) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/supermarket/" + date, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getClientMonthlyReport(date) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/clientMonthlyReport/" + date, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getTotalSales(users, date) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/totalSales/" + users + "&" + date, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getDealerReport(dealers, date, interestType, period) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(
    address +
      "/api/reports/dealerReport/" +
      dealers +
      "&" +
      date +
      "&" +
      interestType +
      "&" +
      period,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
export function getSpecialDealerReport(dealers, date, interestType, period) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(
    address +
      "/api/reports/specialDealerReport/" +
      dealers +
      "&" +
      date +
      "&" +
      interestType +
      "&" +
      period,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getMembershipNewReport(dealers, dateFrom, dateTo) {
  const token = getToken();
  if (!token) return "token not found";


  const dealerIds = dealers.join(",");

  
  return axios.get(
    `${address}/api/reports/membership?dealers=${dealerIds}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

// getMembershipNewReport

export function getTransactionReport(merchants, dateFrom, dateTo, interestOn) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(
    address +
      "/api/reports/transaction/" +
      merchants +
      "&" +
      dateFrom +
      "&" +
      dateTo +
      "&" +
      interestOn,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getRemainingPayBackReport(dateFrom, dateTo) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(
    address + "/api/reports/remainingPayBacks/" + dateFrom + "&" + dateTo,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getTotalSalesOfCurrentUser(date) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/totalSalesOfCurrentUser/" + date, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getInsuranceReport(clients, date) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(
    address + "/api/reports/insuranceReport/" + clients + "&" + date,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
export function getMembershipReport(clients) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/membershipReport/" + clients, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getCreditIssuedReport(client) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/creditIssuedReport/" + client, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getCreditUsedAndIssuedReport(client) {
  const token = getToken();
  if (!token) return "token not found";
  return axios.get(address + "/api/reports/creditUsedAndIssuedReport/" + client, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
