


import React, { useEffect, useState } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Multiselect from "multiselect-react-dropdown";
import { getCreditIssuedReport } from "services/reports";
import { getAllActiveClientList } from "services/client";
import CustomSelect from "utils/CustomSelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

export default function CreditIssued() {
  const [dealers, setDealers] = useState([]); // Initialize dealers as an empty array
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [dealerReport, setDealerReport] = useState(null);
  const [filtered, setFiltered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [date, setDate] = useState(null); // Initialize date as null

  console.log("===selectedDealers", selectedDealers);

  useEffect(() => {
    getAllActiveClientList()
      .then((response) => {
        const { data } = response;
        const formattedData = data.map((item) => {
          return {
            value: item.id,
            label: item.id,
          };
        });
        setDealers(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching dealer data:", error);
      });
  }, []);

  useEffect(() => {
    if (dealerReport) {
      setFiltered(true);
    }
  }, [dealerReport]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedDealers.length < 1) {
      alert("List of Users is empty");
    } else {
      setIsLoading(true); // Start loading
      let client = selectedDealers?.value;
      getCreditIssuedReport(client)
        .then((res) => {
          setDealerReport(res.data);
        })
        .catch((err) => {
          console.error("Error fetching membership report:", err);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading
        });
    }
  };

  const handleDownloadClick = () => {
    setIsDownloading(true); // Start downloading
    setTimeout(() => {
      setIsDownloading(false); // Stop downloading after a delay (simulating a download process)
    }, 1000);
  };

  const handleChange = (item) => {
    setSelectedDealers(item);
  };

  return (
    <div>
      <div>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title as="h4" className="text-center m-3 heading">
                    Credit Issued
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="requiredelement">Clients</label>
                          <CustomSelect
                            options={dealers}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={handleChange}
                            value={selectedDealers}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="text-center justify-content-center mt-2">
                      <Col md="12">
                        <div className="button-wrapper">
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Filtering..." : "Filter"}
                          </Button>
                          {!isLoading && filtered && (
                            <Button
                              className="btn-fill res-size"
                              style={{
                                backgroundColor: "#3AAB7B",
                                border: "none",
                              }}
                            >
                              <CSVLink
                                data={dealerReport}
                                filename={"creditIssued_report.csv"}
                                onClick={handleDownloadClick}
                                disabled={isDownloading}
                              >
                                {isDownloading ? "Downloading..." : "Download"}
                              </CSVLink>
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
