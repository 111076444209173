import React from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { getUserId } from "services/auth";
import { updatePaybackPeriod } from "services/paybackPeriod";
import createPaybackPeriodHistory from "services/paybackPeriodHistory";
import { ifNull } from "utils/dateHandler";
import PdfGenPaymentForm from "./PdfGenPaymentForm";
import "bootstrap/dist/css/bootstrap.min.css";

import { formatDate } from "utils/dateHandler";
export default function Paybackperiods({
  myCurrency,
  paymentData,
  paybackPeriodProp,
  number,
  getDealerAmount,
  updatePaybackPeriodState,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [paybackPeriod, setPaybackPeriod] = React.useState(paybackPeriodProp);
  const [isChecked, setIsChecked] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  let role = JSON.parse(localStorage.getItem("role"));
  const handleShow = () => setShowModal(true);
  const handleModalClose = () => {
    setIsChecked(false);
    setShowModal(false);
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setPaybackPeriod(paybackPeriodProp);
  }, [paybackPeriodProp]);

  const ifAmountToBePaid = (period) => {
    let amnt =
      parseFloat(period.amount) - ifNull(period.amountPaidByClientOriginal);
    return amnt == 0 ? false : true;
  };

  const handlePaidAmount = (value) => {
    const maxLimit = (
      parseFloat(paybackPeriod.amount) -
      ifNull(paybackPeriod.amountPaidByClientOriginal)
    ).toFixed(2);
    console.log(
      "===",
      maxLimit,
      paybackPeriod.amount,
      ifNull(paybackPeriod.amountPaidByClientOriginal)
    );
    if (role === 5) {
      if (value < 0 && value < -paybackPeriod.amountPaidByClientOriginal) {
        paybackPeriod.amountPaidByClient =
          -paybackPeriod.amountPaidByClientOriginal;
        let dealerAmount = 0;
        if (paybackPeriod.paidByDealer) {
          dealerAmount = getDealerAmount(paybackPeriod.amountPaidByClient);
        }
        paybackPeriod.dealerAmount = dealerAmount;
      } else if (value > 0 && ifNull(value) > maxLimit) {
        paybackPeriod.amountPaidByClient = maxLimit;
        let dealerAmount = 0;
        if (paybackPeriod.paidByDealer) {
          dealerAmount = getDealerAmount(paybackPeriod.amountPaidByClient);
        }
        paybackPeriod.dealerAmount = Math.abs(dealerAmount);
      } else {
        paybackPeriod.amountPaidByClient = value;
        let dealerAmount = 0;
        if (paybackPeriod.paidByDealer) {
          dealerAmount = getDealerAmount(paybackPeriod.amountPaidByClient);
        }
        paybackPeriod.dealerAmount = dealerAmount;
      }
    } else {
      if (ifNull(value) > maxLimit) {
        // if ((ifNull(paybackPeriod.amountPaidByClientOriginal) + ifNull(value)) > ifNull(paybackPeriod.amount)) {
        paybackPeriod.amountPaidByClient = 0;
        paybackPeriod.dealerAmount = 0;
      } else {
        paybackPeriod.amountPaidByClient = value;
        let dealerAmount = 0;
        if (paybackPeriod.paidByDealer) {
          dealerAmount = getDealerAmount(paybackPeriod.amountPaidByClient);
        }
        paybackPeriod.dealerAmount = Math.abs(dealerAmount);
      }
    }
  };
  const handlePaidByDealer = (value) => {
    let dealerAmount = 0;
    if (value) {
      dealerAmount = getDealerAmount(paybackPeriod.amountPaidByClient);
    }
    paybackPeriod.dealerAmount = dealerAmount;
    paybackPeriod.paidByDealer = value;
  };
  const handleRemarks = (value) => {
    paybackPeriod.remarks = value;
  };
  const handleTypeOfReturnPayment = (value) => {
    paybackPeriod.TypeOfReturnPayment = value;
  };
  const handleDateDeposit = (value) => {
    paybackPeriod.dateDeposit = value;
  };
  const handleChange = (e) => {
    if (e.target.name == "PaidAmount") {
      handlePaidAmount(role !== 5 ? Math.abs(e.target.value) : e.target.value);
    } else if (e.target.name == "PaidByDealer") {
      handlePaidByDealer(e.target.checked);
    } else if (e.target.name == "Remarks") {
      handleRemarks(e.target.value);
    } else if (e.target.name == "TypeOfReturnPayment") {
      handleTypeOfReturnPayment(e.target.value);
    } else if (e.target.name == "dateDeposit") {
      handleDateDeposit(e.target.value);
    }
    setPaybackPeriod({ ...paybackPeriod });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = getUserId();
    let pp = {
      ...paybackPeriod,
      amountPaidByClient:
        ifNull(paybackPeriod.amountPaidByClient) +
        ifNull(paybackPeriod.amountPaidByClientOriginal),
      handledByUserId: userId,
      amountPaidToDealer:
        ifNull(paybackPeriod.amountPaidToDealer) + paybackPeriod.dealerAmount,
    };
    try {
      await updatePaybackPeriod(pp);
      await updatePaybackPeriodState(pp);
      await createPaybackPeriodHistory({
        ...pp,
        amountPaidByClient: ifNull(paybackPeriod.amountPaidByClient),
      });
      setShowModal(false);
    } catch (e) {}
  };

  const getInterestOn = (type) => {
    let interestOn = "Interest on ";
    if (type == 1) interestOn += "Client";
    else if (type == 2) interestOn += "Merchant";
    else if (type == 3) interestOn += "Client(Gas)";
    else if (type == 4) interestOn += "Client(Pharmacy)";
    else if (type == 5) interestOn += "Prepaid";
    return interestOn;
  };

  const labelStyle = {
    fontWeight: "560",
  };

  return (
    <>
      <Form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <h4 className="client-heading" style={{ marginTop: "0" }}>
              Payback Period&nbsp;{number}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <Form.Group>
              <label>Type</label>
              <Form.Control
                placeholder=""
                value={getInterestOn(paybackPeriod.type)}
                name=""
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <Form.Group>
              <label>Invoice#</label>
              <Form.Control
                placeholder="Invoice Number"
                value={paybackPeriod.invoiceNumber}
                name="invoiceNumber"
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg="4" xl="4">
            <Form.Group>
              <label>Date</label>
              <Form.Control
                placeholder="Date"
                value={formatDate(paybackPeriod.date.split("T")[0])}
                name="Date"
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="4" xl="4">
            <Form.Group>
              <label>Amount Paid </label>
              <Form.Control
                placeholder="0"
                value={`${myCurrency}${ifNull(
                  paybackPeriod.amountPaidByClientOriginal
                )}`}
                name="Amount"
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="4" xl="4">
            <Form.Group>
              <label>Amount To Be Paid</label>
              <Form.Control
                placeholder="0"
                value={`${myCurrency}${(
                  parseFloat(paybackPeriod.amount) -
                  ifNull(paybackPeriod.amountPaidByClientOriginal)
                ).toFixed(2)}`}
                name="Amount"
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="align-paybackPeriods-center">
          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <Form.Group>
              <label>Pay Amount</label>
              <Form.Control
                placeholder="123"
                name="PaidAmount"
                type="number"
                value={paybackPeriod.amountPaidByClient}
                disabled={role === 5 ? false : !ifAmountToBePaid(paybackPeriod)}
                onChange={handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="6" xl="6">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignpaybackPeriods: "center",
              }}
            >
              <Form.Control
                style={{ width: "20px" }}
                type="checkbox"
                checked={paybackPeriod.paidByDealer ? true : false}
                name="PaidByDealer"
                onChange={(e) => {
                  if (ifAmountToBePaid(paybackPeriod)) {
                    handleChange(e);
                  }
                }}
              />
              <label style={{ marginLeft: "7px", marginBottom: "-1px" }}>
                Paid By Dealer
              </label>
            </div>
          </Col>
        </Row>
        {paybackPeriod.paidByDealer ? (
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Form.Group>
                <label>Dealer Amount</label>
                <Form.Control
                  value={`${myCurrency}${
                    paybackPeriod.dealerAmount ||
                    paybackPeriod.amountPaidToDealer ||
                    0
                  }`}
                  name="dealerAmount"
                  disabled
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <Form.Group>
              <label>Remarks</label>
              <Form.Control
                placeholder="good"
                type="text"
                name="Remarks"
                value={paybackPeriod.remarks}
                onChange={handleChange}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="6">
            <Form.Group>
              <label className="requiredelement">Type of Payment </label>
              <Form.Control
                as="select"
                name="TypeOfReturnPayment"
                value={paybackPeriod.TypeOfReturnPayment}
                required
                onChange={handleChange}
              >
                <option value="" selected></option>
                {role !== 4 ? (
                  <>
                    <option value="Cash" selected>
                      Cash
                    </option>
                    <option value="MCB">MCB</option>
                    <option value="RBC">RBC</option>
                    <option value="Banco Di Caribe">Banco Di Caribe</option>
                    <option value="Maestro">Maestro</option>
                    <option value="CIBC">CIBC</option>
                    <option value="Orco Bank">Orco Bank</option>
                    <option value="Incasso">Incasso</option>
                  </>
                ) : (
                  <>
                     
                                <option value="Incasso" selected>
                    Incasso
                  </option>
                   <option value="MCB">MCB</option>
                   <option value="Banco Di Caribe">Banco Di Caribe</option>
                   <option value="Orco Bank">Orco Bank</option>
                   </>

                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a value.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" xl="6">
            <Form.Group>
              <label className="requiredelement">
                Date Deposit{!ifAmountToBePaid(paybackPeriod) ? "ed" : ""}
              </label>
              <Form.Control
                placeholder="abcd"
                required
                disabled={!ifAmountToBePaid(paybackPeriod)}
                value={
                  paybackPeriod.dateDeposit
                    ? paybackPeriod.dateDeposit.split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
                type="date"
                name="dateDeposit"
                onChange={handleChange}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a value.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="text-center justify-content-center mt-2">
          <Col md="12">
            <div>
              <Button
                className="btn-fill res-size"
                style={{ backgroundColor: "#22577E", border: "none" }}
                onClick={handleShow}
                disabled={role === 5 ? false : !ifAmountToBePaid(paybackPeriod)}
              >
                Submit
              </Button>

              <Modal
                show={showModal}
                onHide={handleModalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header
                  className="d-flex align-items-center "
                  closeButton
                  style={{
                    borderBottom: "1px solid #cdcdcd",
                  }}
                >
                  <p style={{ fontSize: "20px", fontWeight: "540" }}>
                    Payback Period&nbsp;{number} Preview
                  </p>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label style={labelStyle}>Kliente :</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>{paymentData.Client_id}</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label style={labelStyle}>NFC Card :</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>
                        <label>{paymentData.NfcCard_id}</label>
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label style={labelStyle} className="text-center">
                        Invoice# :
                      </label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>{paybackPeriod.invoiceNumber || ""}</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label
                        style={{
                          whiteSpace: "nowrap",

                          fontWeight: "560",
                        }}
                      >
                        Earlier Payement :
                      </label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>
                        {" "}
                        {`${myCurrency} ${ifNull(
                          paybackPeriod.amountPaidByClientOriginal
                        )}`}
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label style={labelStyle}>Date :</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>{paybackPeriod.date.split("T")[0]}</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label style={labelStyle}>Paid Amount :</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>
                        {" "}
                        {`${myCurrency} ${
                          paybackPeriod.amountPaidByClient || 0
                        }`}
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label
                        style={{
                          whiteSpace: "nowrap",

                          fontWeight: "560",
                        }}
                      >
                        Type of Payment :
                      </label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>{paybackPeriod.TypeOfReturnPayment || ""}</label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label style={labelStyle}>Date Deposited : </label>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="3"
                      xl="3"
                      className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                      <label>
                        {paybackPeriod.dateDeposit
                          ? paybackPeriod.dateDeposit.split("T")[0]
                          : null}
                      </label>
                    </Col>
                  </Row>
                  {paybackPeriod.paidByDealer ? (
                    <Row>
                      <Col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="3"
                        xl="3"
                        className="d-flex align-items-center justify-content-center justify-content-md-start"
                      >
                        <label style={labelStyle}>Dealer Amount :</label>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="3"
                        xl="3"
                        className="d-flex align-items-center justify-content-center justify-content-md-start"
                      >
                        <label>
                          {" "}
                          {`${myCurrency} ${
                            paybackPeriod.dealerAmount ||
                            paybackPeriod.amountPaidToDealer ||
                            0
                          }`}
                        </label>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="3"
                        xl="3"
                        className="d-flex align-items-center justify-content-center justify-content-md-start"
                      >
                        <label></label>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="6"
                        lg="3"
                        xl="3"
                        className="d-flex align-items-center justify-content-center justify-content-md-start"
                      >
                        <label></label>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <div
                    className="pt-3 pb-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignpaybackPeriods: "center",
                    }}
                  >
                    <Form.Control
                      style={{ width: "20px" }}
                      type="checkbox"
                      checked={isChecked}
                      name="PaidByDealer"
                      onChange={handleCheckboxChange}
                    />
                    <label style={{ marginLeft: "7px", paddingTop: "3px" }}>
                      I confirm that the entered data is correct
                    </label>
                  </div>
                  {isChecked && (
                    <Form
                      className="d-flex align-items-center justify-content-center w-100"
                      style={{ gap: "10px" }}
                    >
                      <Button
                        className="btn-fill res-size"
                        type="submit"
                        style={{ backgroundColor: "#22577E", border: "none" }}
                      >
                        {isLoading ? (
                          <div>
                            {" "}
                            <i class="fa fa-spinner" aria-hidden="true">
                              &nbsp;
                            </i>{" "}
                            Paying...
                          </div>
                        ) : (
                          <div>Pay</div>
                        )}
                      </Button>
                      {paymentData.userName ? (
                        <PdfGenPaymentForm
                          // paybackPeriodHistory={(e) => {
                          //     paybackPeriodHistory = e
                          // }}
                          userName={{
                            key: "userName",
                            value: paymentData.userName,
                          }}
                          number={number}
                          klienteDetails={[
                            { key: "Kliente", value: paymentData.Client_id },
                            { key: "Card # ", value: paymentData.NfcCard_id },
                          ]}
                          CardDetails={[
                            {
                              key: "Amount given",
                              value: paymentData.Amount,
                            },
                            {
                              key: "Amount used",
                              value: paymentData.amountUsed,
                            },
                            {
                              ...paybackPeriod,
                              key: "Previously Paid Amount",
                              value: paybackPeriod.amountPaidByClientOriginal,
                            },
                          ]}
                          paybackPeriod={{
                            ...paybackPeriod,
                            amountPaidByClient:
                              ifNull(paybackPeriod.amountPaidByClient) +
                              ifNull(paybackPeriod.amountPaidByClientOriginal),
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Form>
                  )}
                </Modal.Body>
              </Modal>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
