import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import { getNfcSingleData, updateNfc, addNfc } from "../services/nfc";
import { toast, ToastContainer } from "react-toastify";

function addNfcCard() {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [ClientID, setClientID] = React.useState(null);
  const [formData, setFormData] = React.useState({
    id: "",
    number: "",
    status: "1",
  });

  const { id, number, status } = formData;
  useEffect(() => {
    const params = queryParams.get("id");
    if (params != null) {
      setClientID(params);
    }
  }, []);
  useEffect(() => {
    if (ClientID == null) return;
    getNfcSingleData(ClientID)
      .then((response) => {
        setFormData({
          ...response.data,
          ["status"]: JSON.stringify(response.data.status),
        });
      })
      .catch((error) => {});
  }, [ClientID]);
  const validateInput = (name, value) => {
    if (name === "number") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    return true;
  };
  const handleInputChange = (e) => {
    setErrorMessage("");
    if (e.target.name === "number") {
      const valid = validateInput(e.target.name, e.target.value);
      if (valid != true) {
        toast.warning(valid, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        ["id"]: e.target.value,
      });
      return;
    }
    if (e.target.name == "status") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      return;
    }
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (ClientID) {
      updateNfc({
        id: formData.id,
        number: formData.number,
        status: JSON.parse(formData.status),
      })
        .then((response) => {
          setLoading(false);
          if (response) {
            history.push("/admin/nfccard");
          } else {
            setErrorMessage(`${number} card number is already exists`);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      addNfc({
        id: formData.id,
        number: formData.number,
        status: JSON.parse(formData.status),
      })
        .then((response) => {
          setLoading(false);
          if (response.data) {
            history.push("/admin/nfccard");
          } else {
            setErrorMessage(`${number} card number is already exists`);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h3" className="text-center m-3 heading">
                NFC Card
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Row>
                  <Col sm="12" md="12">
                    <Form.Group>
                      <label className="requiredelement">
                        Number di Karchi
                      </label>
                      <Form.Control
                        required
                        placeholder="Number di Karchi"
                        type="text"
                        value={number}
                        name="number"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="radio-wrapper">
                      <label className="mt-1 requiredelement">
                        Card Status
                      </label>
                      <Form.Check
                        inline
                        label="Activate"
                        type="Radio"
                        name="status"
                        value="1"
                        checked={status === "1" ? true : false}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                      <Form.Check
                        inline
                        label="Deactivate"
                        type="Radio"
                        name="status"
                        value="0"
                        checked={status === "0" ? true : false}
                        onClick={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="text-center mt-3">
                  {errorMessage ? (
                    <Col xs="12" style={{ fontSize: "13px", color: "red" }}>
                      {errorMessage}
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col md="12">
                    <div className="button-wrapper">
                      <Button
                        className="btn-fill res-size"
                        type="submit"
                        style={{ backgroundColor: "#3AAB7B", border: "none" }}
                      >
                        {loading ? (
                          <div>
                            {" "}
                            <i class="fa fa-spinner" aria-hidden="true">
                              &nbsp;
                            </i>{" "}
                            Saving...
                          </div>
                        ) : (
                          <div>Save</div>
                        )}
                      </Button>
                      <Link to="/admin/nfccard">
                        <Button
                          className="btn-fill res-size"
                          variant="danger"
                          style={{ border: "none" }}
                        >
                          Back
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}
export default addNfcCard;
