import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function getMerchantList() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/merchants/getAllMerchants", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getMerchantData(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/merchants/getMerchantById/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllMerchantCreditHistory() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/merchants/creditHistory", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllMerchantOfUser(userId) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/merchants/getMerchantById/" + userId, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function updateMerchant(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(address + "/api/merchants/upsertMerchant", formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function addMerchant(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(address + "/api/merchants/createMerchant", formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function loadSuperMarket() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(
    address + "/api/merchants/updateClientInterest",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function CloseSuperMarket() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(
    address + "/api/merchants/updateClientInterestMaxCredit",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function deleteMerchant(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.delete(address + "/api/merchants/deleteMerchant/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
