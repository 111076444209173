import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import getDeviceData from "../services/device";
import {
  getDeviceSingleData,
  updateDevice,
  addDeviceData,
} from "../services/device";
import _uniqueId from "lodash/uniqueId";
import getClientList from "services/client";
import { updateDealerBulkPayement } from "services/dealerBulkPayment";
import { addDealerBulkPayment } from "services/dealerBulkPayment";
import { getAllDealerBulkPaymentById } from "services/dealerBulkPayment";
import { getDealerBulkPaymentById } from "services/dealerBulkPayment";

// pdf
import PdfGen from "./PdfGen.jsx";
import { getUniqueString } from "utils/getUniqueString";
import { getRole } from "services/auth";

function DealerBulkPaymentForm() {
  const history = useHistory();
  const [allClients, setAllClients] = React.useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const [dbpId, setdbpId] = React.useState(null);
  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const [dealers, setDealers] = React.useState([]);
  const [selectedDealerName, setSelectedDealerName] = React.useState("");

  const [formData, setFormData] = React.useState({});
  useEffect(() => {
    if (parseInt(getRole()) == 1) return;
    const params = queryParams.get("id");
    if (params != null) {
      setdbpId(params);
    }
    getClientList()
      .then((response) => {
        const myClientsList = [];
        for (let index = 0; index < response.data.length; index++) {
          const client = response.data[index];
          if (client.Status === 1 && client.Code.includes("D")) {
            myClientsList.push(client);
          }
        }
        setAllClients(myClientsList);
      })
      .catch((error) => {});
    setFormData({ ...formData, InvoiceNumber: getUniqueString() });
  }, []);

  useEffect(() => {
    if (dbpId == null) return;
    getDealerBulkPaymentById(dbpId)
      .then((response) => {
        setFormData(response.data[0]);
        setSelectedDealerName(
          response.data[0].FirstName + " " + response.data[0].LastName
        );
      })
      .catch((error) => {});
  }, [dbpId]);
  const { InvoiceNumber, Dealer, Amount, Date, Remarks } = formData;

  const validateInput = (name, value) => {
    if (name === "Amount") {
      // let pattern = new RegExp("^[0-9]*$");
      let pattern = /^[+-]?\d*(?:[.,]\d*)?$/;
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    return true;
  };

  const handleInputChange = (e) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      alert(valid);
      return;
    }
    if (e.target.name == "Dealer") {
      allClients.map((item) => {
        if (item.id == e.target.value) {
          setSelectedDealerName(item.FirstName + " " + item.LastName);
        }
      });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (dbpId) {
      updateDealerBulkPayement(formData)
        .then((response) => {})
        .catch((error) => {});
    } else {
      let data = { ...formData };
      data.createdByUserId = localStorage.getItem("id");
      addDealerBulkPayment(data)
        .then((response) => {})
        .catch((error) => {});
    }
    history.push("/admin/dealerBulkPaymentList");
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="form-wrapper mt-4">
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h3" className="text-center m-3 heading">
                  Bulk Payment Form
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label htmlFor="exampleLastName">Invoice #</label>
                        <Form.Control
                          // required
                          // placeholder="0"
                          type="text"
                          value={InvoiceNumber}
                          name="InvoiceNumber"
                          disabled
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label
                          htmlFor="exampleLastName"
                          className="requiredelement"
                        >
                          Kliente Code
                        </label>
                        <Form.Control
                          required
                          as="select"
                          name="Dealer"
                          disabled={dbpId ? true : false}
                          value={Dealer}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        >
                          <option></option>
                          {allClients.map((item) => {
                            return (
                              <option
                                value={item.id}
                              >{`${item.FirstName} ${item.LastName}: ${item.Code}`}</option>
                            );
                          })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label className="requiredelement">Amount</label>
                        <Form.Control
                          required
                          placeholder="0"
                          type="text"
                          value={Amount}
                          name="Amount"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <label className="requiredelement">Date</label>
                        <Form.Control
                          required
                          placeholder="00-00-0000"
                          type="Date"
                          value={Date ? Date.split("T")[0] : Date}
                          name="Date"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="requiredelement">Remarks</label>
                        <Form.Control
                          required
                          placeholder="abc"
                          type="text"
                          value={Remarks}
                          name="Remarks"
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="text-center">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{
                            backgroundColor: "#3AAB7B",
                            border: "none",
                          }}
                        >
                          Save
                        </Button>
                        <Link to="/admin/dealerBulkPaymentList">
                          <Button
                            className="btn-fill res-size"
                            variant="danger"
                            style={{
                              border: "none",
                            }}
                          >
                            Back
                          </Button>
                        </Link>
                        {Dealer && Amount && Date && Remarks && (
                          <PdfGen
                            // userName={
                            //     { key: "userName", value: userName }
                            // }
                            staticArray={[
                              { key: "Invoice Number", value: InvoiceNumber },
                              { key: "Dealer ID", value: Dealer },
                              { key: "Dealer Name", value: selectedDealerName },
                              {
                                key: "Amount",
                                value: parseFloat(Amount).toFixed(2),
                              },
                              { key: "Date", value: Date.split("T")[0] },
                              { key: "Remarks", value: Remarks },
                            ]}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DealerBulkPaymentForm;
