import React, { useEffect, useState } from "react";
//          Importing CSS Files
import "./clientForm.css";
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import {
  createMembership,
  getMembershipById,
  updateMembership,
} from "services/membershipHistory";

import PdfGenMembership from "./PdfGenMembership.jsx";
import { getUniqueString } from "utils/getUniqueString";

function MembershipForm() {
  let role = JSON.parse(localStorage.getItem("role"));
  const history = useHistory();
  const [itemId, setItemId] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const [params, setParams] = useState(queryParams.get("id"));
  const [clientCode, setClientCode] = useState(null);
  const [formData, setFormData] = React.useState({
    amount: "",
    paymentType: "Cash",
    month: new Date().toISOString().split("T")[0],
    clientFk: params ? params : queryParams.get("id"),
  });
  const { amount, month, clientFk, paymentType } = formData;

  useEffect(() => {
    setClientCode(queryParams.get("id"));
    const updateId = queryParams.get("itemId");
    if (updateId != null) {
      setItemId(updateId);
    }
  }, []);
  useEffect(() => {
    if (itemId == null) return;
    getMembershipById(itemId)
      .then((response) => {
        setFormData(response.data.data);
        setParams(response.data.data.clientFk);
      })
      .catch((error) => {});
  }, [itemId]);

  const validateInput = (name, value) => {
    if (name === "amount") {
      // let pattern = new RegExp("^[0-9]*$");
      let pattern = /^\d*\.?\d*$/;
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers are allowed";
    }
    return true;
  };

  const handleInputChange = (e) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      alert(valid);
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (itemId) {
      updateMembership(itemId, formData)
        .then((response) => {
          // history.push("/admin/MembershipHistory/?id=" + params)
        })
        .catch((err) => {
          // history.push("/admin/MembershipHistory/?id=" + params)
        });
    } else {
      createMembership(formData)
        .then((response) => {
          history.push("/admin/MembershipHistory/?id=" + params);
        })
        .catch((err) => {
          history.push("/admin/MembershipHistory/?id=" + params);
        });
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" className="main-container">
            <Card className="form-wrapper mt-4" style={{ border: "none" }}>
              <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                <Card.Title as="h4" className="text-center m-3 client-heading">
                  Membership Form
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                      <Form.Group>
                        <label>Client Code</label>
                        <Form.Control
                          name="clientFk"
                          value={clientFk}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          disabled
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                      <Form.Group>
                        <label>Type of Payment</label>
                        <Form.Control
                          as="select"
                          name="paymentType"
                          value={paymentType}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        >
                          
                          {role !== 4 ? (
                  <>
                    <option value="Cash" selected>
                      Cash
                    </option>
                    <option value="MCB">MCB</option>
                    <option value="RBC">RBC</option>
                    <option value="Banco Di Caribe">Banco Di Caribe</option>
                    <option value="Maestro">Maestro</option>
                    <option value="CIBC">CIBC</option>
                    <option value="Orco Bank">Orco Bank</option>
                    <option value="Incasso">Incasso</option>
                  </>
                ) : (
                  <>                  <option value="Incasso" selected>
                    Incasso
                  </option>
                   <option value="MCB">MCB</option>
                   <option value="Banco Di Caribe">Banco Di Caribe</option>
                   <option value="Orco Bank">Orco Bank</option>
                   </>

                )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                      <Form.Group>
                        <label>amount</label>
                        <Form.Control
                          name="amount"
                          value={amount ? amount : ""}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                      <Form.Group>
                        <label>date</label>
                        <Form.Control
                          type="date"
                          value={month}
                          name="month"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="text-center justify-content-center mt-2">
                    <Col md="12">
                      <div className="button-wrapper">
                        <Button
                          className="btn-fill res-size"
                          type="submit"
                          style={{ backgroundColor: "#22577E", border: "none" }}
                        >
                          Save
                        </Button>
                        <Button
                          className="btn-fill res-size"
                          variant="danger"
                          style={{ border: "none" }}
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          Back
                        </Button>
                        {amount && month && (
                          <PdfGenMembership
                            membershipDetails={[
                              { key: "Invoice #", value: getUniqueString() },
                              { key: "Kliente", value: clientFk },
                              { key: "Amount", value: amount },
                              { key: "Date", value: month },
                            ]}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default MembershipForm;
