import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function addIssuanceHistory(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/issuancehistory/createIssuancehistory",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function addIssuanceHistoryRow(rowData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/multipleIssueances/createMultipleIssueances",
    rowData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function allowBalanceByIssuance(issuancehistory) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/issuanceHistory/allowBalance",
    issuancehistory,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getAllIssuanceHistories(issuancehistory) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/issuanceHistory/getAllIssuanceHistories",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function addIssuanceHistoryPaybackPeriod(paybackPeriod) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/paybackPeriod/createPaybackPeriod",
    paybackPeriod,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function upsertIssuanceHistory(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/issuancehistory/upsertIssuancehistory",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function OnNfcAndPinCodeMultipleIssuance(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/issuancehistory/OnNfcAndPinCodeMI",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getissuancehistoryByClientId(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address + "/api/issuancehistory/getIssuanceHistoryByClientId/" + Client_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
export function getissuancehistoryAmountByClientId(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address + "/api/issuancehistory/getIssuanceRemainingAmount/" + Client_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getTransactionhistoryByClientId(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address +
      "/api/transactionHistory/getTransactionHistoryByClientId/" +
      Client_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getTransactionhistoryCurrentYearByClientId(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address +
      "/api/transactionHistory/getTransactionHistoryCurrentYearByClientId",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getMaxRemainingCreditClient(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address + "/api/issuancehistory/getMaxRemainingCreditClient/" + id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
export function getissuancehistoryNoPaidByClientId(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address + "/api/issuancehistory/getIssueanceHistyByClientId/" + Client_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getissuancehistoryByID(issuanceHistoryId) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address +
      "/api/issuancehistory/getIssuancehistoryById/" +
      issuanceHistoryId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getIssuanceHistoryRow(issuanceHistoryId) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address +
      "/api/multipleIssueances/getAllByIssuancesId/" +
      issuanceHistoryId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getIssuanceHistoryPaybackPeriod(issuanceHistoryId) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(
    address +
      "/api/paybackPeriod/getPaybackPeriodByIssuanceHistory/" +
      issuanceHistoryId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getAllIssuanceHistory() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/issuancehistory/getAllIssuancehistories", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteIssuanceHistory(issuanceHistoryId) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.delete(
    address + "/api/issuancehistory/deleteIssuancehistory/" + issuanceHistoryId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
