import React from "react";
//          Importing Routes
import { useLocation, NavLink } from "react-router-dom";
//          Importing Components
import { Nav } from "react-bootstrap";
//          Importing Services
import { getRole } from "../../services/auth";
import ReactBellIcon from "react-bell-icon";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

function Sidebar({ color, image, routes, socket }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const [isClientNotificationActive, setIsClientNotificationActive] =
    useState(false);
  const [isMerchantNotificationActive, setIsMerchantNotificationActive] =
    useState(false);
  const [isNewClientNotifcation, setIsNewClientNotifcation] = useState(false);

  const clientNotificationRef = useRef(false);
  const merchantNotificationRef = useRef(false);
  const newClientNotificationRef = useRef(false);

  const catchSocketAction = (notificationRef, setNotificationActive) => {
    if (notificationRef.current == false) {
      setNotificationActive(true);
      notificationRef.current = true;
      setTimeout(() => {
        setNotificationActive(false);
        notificationRef.current = false;
      }, 5000);
    }
  };
  const isStale = (oldMerchants, newMerchants) => {
    if (oldMerchants.includes(newMerchants) || oldMerchants == newMerchants) {
      return false;
    }
    return true;
  };
  const fetchIdFrom = (merchants) => {
    const merchantIds = merchants.map((item) => item.id);
    return merchantIds.toString();
  };
  useEffect(() => {
    const role = window.localStorage.getItem("role");
    if (role == 0) {
      socket.on("NearCreditLimitMerchants", (response) => {
        const newMerchants = response.data;
        if (!newMerchants || newMerchants.length == 0) return;
        const newMerchantsIds = fetchIdFrom(newMerchants);

        const oldMerchantsIds =
          window.localStorage.getItem("nearCreditMerchants") || [];
        if (!oldMerchantsIds) {
          window.localStorage.setItem(
            "nearCreditMerchants",
            newMerchantsIds.toString()
          );
          catchSocketAction(
            merchantNotificationRef,
            setIsMerchantNotificationActive
          );
        }
        if (isStale(oldMerchantsIds, newMerchantsIds)) {
          window.localStorage.setItem(
            "nearCreditMerchants",
            newMerchantsIds.toString()
          );
          catchSocketAction(
            merchantNotificationRef,
            setIsMerchantNotificationActive
          );
        }
      });
    }
    socket.on("newClientRequest", () => {
      catchSocketAction(clientNotificationRef, setIsClientNotificationActive);
    });
    socket.on("NewClientRegistered", () => {
      catchSocketAction(newClientNotificationRef, setIsNewClientNotifcation);
    });

    return () => {
      socket.off("newClientRequest");
      socket.off("NearCreditLimitMerchants");
      socket.off("NewClientRegistered");
    };
  }, []);
  const renderBellIcon = (color) => {
    return (
      <ReactBellIcon width="20" active={true} animate={true} color={color} />
    );
  };
  const showNotification = (color) => {
    return renderBellIcon(color);
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{ backgroundColor: "#42D0ED" }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a href="#">
            <div>
              <img
                src={require("../../assets/img/logo/MerpolM.svg").default}
                alt="logo"
                className="img-fluid"
                style={{ width: "100px", height: "55px" }}
              />
            </div>
          </a>
          <a className="simple-text mt-1" href="#">
            Merpol
          </a>
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect) {
              if (
                prop.path.includes("add") ||
                (prop.path.includes("Form") && !prop.path.includes("Card")) ||
                prop.path.includes("History") ||
                prop.path.includes("PendingPaymentList") ||
                prop.path.includes("pendingClientList") ||
                prop.path.includes("blockedClientList") ||
                prop.path.includes("remainingPayBackClientsList") ||
                prop.path.includes("MerchantGroupList") ||
                prop.path.includes("issuePrepaidCard")
              )
                return;
            }
            const role = parseInt(getRole());
            if (role === 0 && prop.path === "/currentYearDebt") {
              return null;
            }
            if (role === 3 && prop.path === "/currentYearDebt") {
              return null;
            }
            if (role === 5 && prop.path === "/currentYearDebt") {
              return null;
            }
            if (role == 1) {
              if (
                prop.path != "/groups" &&
                prop.path !== "/ClientList" &&
                // && prop.path != "/dashboard"
                prop.path != "/nfccard" &&
                // && prop.path != "/dealerBulkPaymentList"
                prop.path != "/IssuanceCardForm" &&
                prop.path != "/Reports" &&
                prop.path != "/kassafloats" &&
                prop.path != "/transaction"
              ) {
                return;
              }
            }
            if (role == 2) {
              if (
                prop.path != "/groups" &&
                prop.path !== "/ClientList" &&
                prop.path != "/dashboard"
              ) {
                return;
              }
            } else if (
              role === 4 &&
              prop.path !== "/currentYearDebt" &&
              prop.path !== "/Reports"
            ) {
              return null;
            }
            return (
              <li
                className={
                  prop.upgrade
                    ? "active active-pro"
                    : activeRoute(prop.layout + prop.path)
                }
                key={key}
              >
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={prop.layout + prop.path}
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>&nbsp;
                  {prop.path == "/requestList"
                    ? isClientNotificationActive && showNotification("red")
                    : prop.path == "/MerchantList"
                    ? isMerchantNotificationActive && showNotification("yellow")
                    : prop.path == "/ClientList"
                    ? isNewClientNotifcation && showNotification("green")
                    : ""}
                </NavLink>
              </li>
            );
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}
export default Sidebar;
