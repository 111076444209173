import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routing Components
import { useHistory } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { MerchantRow } from "../components/IssuanceCardForm/marchantRow";
//          Importing Services
import getClientList, { getClientListForIssuance } from "../services/client";
import addIssuanceHistory, {
  getMaxRemainingCreditClient,
} from "../services/issuanceHistory";
import { getClientMaxBorrowAmountById } from "../services/client";
import getMerchantList from "../services/merchant";
import { getMerchantTypeDiscountByMerchantType_id } from "../services/merchantType";
import { getNfcList } from "../services/nfc";
import {
  addIssuanceHistoryRow,
  addIssuanceHistoryPaybackPeriod,
  getissuancehistoryByID,
  getIssuanceHistoryRow,
  getIssuanceHistoryPaybackPeriod,
  getissuancehistoryByClientId,
} from "../services/issuanceHistory";
import _uniqueId from "lodash/uniqueId";
import { getMerchantTypeDiscountById } from "services/merchantType";
import { addInsurance } from "services/insurance";
import { getUniqueString } from "utils/getUniqueString";

import PdfGenCard from "./PdfGenCard";
import { getUserId } from "services/auth";
import CustomSelect from "utils/CustomSelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

function IssuanceCardForm() {
  const role = JSON.parse(localStorage.getItem("role"));
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [params, setParams] = React.useState(queryParams.get("id"));
  const [ClientID, setClientID] = React.useState(null);
  const [allClients, setAllClients] = React.useState([]);
  const [allNfcCards, setAllNfcCards] = React.useState([]);
  const [uniqueID] = React.useState(Math.random() * 100000 * Math.random());
  const [clientBorrowLimit, setClientBorrowLimit] = React.useState(0);
  const [bonusLimit, setBonusLimit] = React.useState(0);
  const [autoPincode] = React.useState(
    JSON.stringify(Math.floor(1000 + Math.random() * 9000))
  );
  const [isLoading, setIsLoading] = React.useState(false);
  // Form Data
  const [formData, setFormData] = React.useState({
    DateTime: new Date().toISOString().split("T")[0],
    Amount: "",
    Client_id: "",
    NfcCard_id: "",
    Pincode: "",
    Balance: "",
    id: uniqueID,
  });
  const { DateTime, Amount, Client_id, NfcCard_id, Pincode, id, paymentType } =
    formData;
  // Merchant Row Form Data
  const [merchantFormData, setMerchantFormData] = React.useState([]);
  const [PaymentPeriods, setPaymentPeriods] = React.useState([]);
  const [NumberOfMonths, setNumberOfMonths] = React.useState(0);
  const [defaulterClients, setDefaulterClients] = React.useState([]);
  const [Interest, setInterest] = React.useState(0);
  const [interestOn, setInterestOn] = React.useState("");
  const [selectedNfcCard, setSelectedNfcCard] = React.useState();
  const [isChecked, setIsChecked] = React.useState(false);
  const [gasMerchantsCheck, setGasMerchantsCheck] = React.useState(false);
  const [isaddMerchantChecked, setAddDataCheckTrue] = React.useState(false);
  const [allGasMerchants, setGasMerchants] = React.useState([]);
  const [paybackPeriodAgainstMerchantId, setPaybackPeriodAgainstMerchantId] =
    React.useState([]);

  console.log(
    "===PaymentPeriods",
    NumberOfMonths,
    Interest,
    interestOn,
    DateTime,
    Amount
  );

  const handleAddMerchant = () => {
    if (DateTime === "") {
      alert("* please select date");
      return;
    } else if (Client_id === "") {
      alert("* please select kliente");
      return;
    } else if (NfcCard_id === "") {
      alert("* please select NFC Card");
      return;
    } else if (Amount === "") {
      alert("* please enter amount");
      return;
    } else if (Pincode === "") {
      alert("* please enter pincode");
      return;
    } else {
      setAddDataCheckTrue(true);
      setMerchantFormData([
        ...merchantFormData,
        {
          id: Math.random() * 1000 * Math.random(),
          Merchants_id: "",
          PaybackPeriod: "",
        },
      ]);
    }
  };
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      if (
        DateTime !== "" &&
        Client_id !== "" &&
        NfcCard_id !== "" &&
        Amount !== "" &&
        Pincode !== ""
      ) {
        setIsChecked(e.target.checked);
        setGasMerchantsCheck(true);
      } else {
        alert("* Please enter all fields first.");
        setIsChecked(false);
        setGasMerchantsCheck(false);
      }
    } else {
      setIsChecked(e.target.checked);
      setGasMerchants([]);
      setPaybackPeriodAgainstMerchantId([]);
      setNumberOfMonths(0);
      setInterest(0);
      setInterestOn("");
      setMerchantFormData([]);
      setPaymentPeriods([]);
      setGasMerchantsCheck(false);
    }
  };

  const handleRemoveGasMerchant = (RID) => {
    let merchants = [...merchantFormData];
    let gasMerchants = [...allGasMerchants];

    let index = merchants.findIndex((item) => item.Merchants_id === RID);
    if (index !== -1) {
      merchants = merchants.filter((item) => item.Merchants_id !== RID);
    }

    index = gasMerchants.findIndex((item) => item.id === RID);
    if (index !== -1) {
      gasMerchants = gasMerchants.filter((item) => item.id !== RID);

      if (index === 0) {
        setPaymentPeriods([]);
      }
      setGasMerchants([...gasMerchants]);
      setMerchantFormData([...merchants]);
    }
  };

  const handleRemoveMerchant = (RID) => {
    let merchants = [...merchantFormData];
    let index = -1;
    index = merchants.findIndex((item) => item.id === RID);
    if (index === 0) {
      setPaymentPeriods([]);
    }
    merchants = merchants.filter((item) => item.id !== RID);
    setMerchantFormData([...merchants]);
  };

  const getMerchantId = (RID, Merchant) => {
    let merchants = [...merchantFormData];
    let index = -1;
    index = merchants.findIndex((item) => item.id === RID);
    merchants[index].Merchants_id = Merchant.id;
    setMerchantFormData([...merchants]);
    if (index === 0) setPaymentPeriods([]);
  };
  const getPaybackPeriod = (RID, period) => {
    let merchants = [...merchantFormData];
    let mIndex = -1;
    mIndex = merchants.findIndex((item) => item.id === RID);
    merchants[mIndex].PaybackPeriod = period.id;
    if (mIndex === 0) {
      setNumberOfMonths(period.NumberOfMonths);
      setInterest(period.Interest);
      setInterestOn(period.interestOn);
      setPaymentPeriods([
        ...generatePaymentPeriods(
          period.NumberOfMonths,
          period.Interest,
          period.interestOn,
          DateTime,
          Amount
        ),
      ]);
    }
    setMerchantFormData([...merchants]);
  };
  const getInterestAmount = (issuedAmount, interest, interestOn) => {
    if (
      interestOn == "Client" ||
      interestOn == "Gas" ||
      interestOn == "Pharmacy" ||
      interestOn == "Prepaid"
    ) {
      return issuedAmount * interest;
    }
    return 0;
  };
  const getTypeByInterestOn = (interestOn) => {
    if (interestOn == "Client") return 1;
    if (interestOn == "Merchant") return 2;
    if (interestOn == "Gas") return 3;
    if (interestOn == "Pharmacy") return 4;
    if (interestOn == "Prepaid") return 5;
    return 2;
  };
  const generatePaymentPeriods = (
    numberOfMonths,
    interest,
    interestOn,
    dateTime,
    amount
  ) => {
    const myPaymentPeriods = [];
    const type = getTypeByInterestOn(interestOn);
    let issuedDate = new Date(dateTime);
    if (fallsWithinDate(new Date()) && numberOfMonths == 10) {
      issuedDate = new Date("2023-06-01");
    } else {
      const now = issuedDate; // Get the current date and time
      const currentMonth = now.getMonth(); // Get the current month (0-11)
      const currentYear = now.getFullYear(); // Get the current year
      const firstDayOfMonth = new Date(currentYear, currentMonth, 2);
      issuedDate = firstDayOfMonth;
    }
    let nextMonthIncrement = 0;
    if (interestOn == "Gas" || interestOn == "Client") {
      nextMonthIncrement += 1;
    } else {
      nextMonthIncrement += 2;
    }
    const issuedAmount = JSON.parse(amount);
    const interestAmount = getInterestAmount(
      issuedAmount,
      interest,
      interestOn
    );
    const perMonthAmount =
      (issuedAmount + interestAmount / 100) / numberOfMonths;
    for (let index = 0; index < numberOfMonths; index++) {
      let nextMonthDate;
      if (fallsWithinDate(new Date()) && numberOfMonths == 10) {
        nextMonthDate = new Date(issuedDate.setMonth(issuedDate.getMonth() + 1))
          .toISOString()
          .split("T")[0];
      } else {
        nextMonthDate = new Date(
          issuedDate.getFullYear(),
          issuedDate.getMonth() + index + nextMonthIncrement,
          2
        )
          .toISOString()
          .split("T")[0];
      }
      myPaymentPeriods.push({
        DateTime: nextMonthDate,
        Amount: Math.round((perMonthAmount + Number.EPSILON) * 100) / 100,
        type,
      });
    }
    return myPaymentPeriods;
  };
  useEffect(() => {
    if (params != null) {
      setClientID(params);
    } else {
      setClientID(null);
      setFormData({ ...formData, ["id"]: uniqueID, Pincode: autoPincode });

      getClientListForIssuance()
        .then((response) => {
          const data = response.data.clients;

          if (
            response.data.defaulterClients &&
            response.data.defaulterClients.length > 0
          ) {
            const defaulterList = response.data.defaulterClients.map(
              (item) => item.Client_id
            );
            setDefaulterClients(defaulterList);
          }
          const formattedData = data.map((item) => {
            return {
              value: item.id,
              label: `${item.FirstName} ${item.LastName}: ${item.Code}`,
            };
          });
          setAllClients(formattedData);
        })
        .catch((error) => {});
      getNfcList()
        .then((response) => {
          // response.data.unshift({});
          const formattedData = response.data.map((item) => {
            return {
              value: item.id,
              label: `${item.number}`,
            };
          });
          setAllNfcCards(formattedData);
        })
        .catch((error) => {});
    }
  }, [params]);

  useEffect(() => {
    if (ClientID === null) {
      return;
    } else {
      getissuancehistoryByID(ClientID)
        .then((res) => {
          setFormData({
            ...res.data,
            ["DateTime"]: res.data.DateTime.split("T")[0],
          });
          getIssuanceHistoryRow(res.data.id)
            .then((rowRes) => {
              let myMerchantFormData = [];
              rowRes.data.data.map((rowItem) => {
                myMerchantFormData.push({
                  id: rowItem.id,
                  Merchants_id: rowItem.merchantId,
                  PaybackPeriod: rowItem.numberOfMonthsId,
                });
              });
              setMerchantFormData([...myMerchantFormData]);
            })
            .catch((rowErr) => {});
          getIssuanceHistoryPaybackPeriod(res.data.id)
            .then((PPRes) => {
              let myPaybackPeriods = [];
              PPRes.data.data.map((PPItem) => {
                myPaybackPeriods.push({
                  DateTime: PPItem.date.split("T")[0],
                  Amount: PPItem.amount,
                });
              });
              setPaymentPeriods([...myPaybackPeriods]);
            })
            .catch((PPErr) => {});
        })
        .catch((err) => {});
    }
  }, [ClientID]);
  const validateInput = (name, value) => {
    if (name === "Code") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    if (name === "FirstName" || name === "LastName" || name === "WorksAt") {
      let pattern = new RegExp("^[a-zA-Z ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only alphabets and spaces";
    }
    if (name === "WorkNo" || name === "FaxNumber" || name === "ContactNo") {
      let pattern = new RegExp("^[0-9 ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    return true;
  };
  const handleInputChange = async (e) => {
    if (e.target.name == "DateTime") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name == "Amount") {
      const myAmount = parseInt(e.target.value);
      if (
        myAmount >
        (bonusLimit > clientBorrowLimit ? bonusLimit : clientBorrowLimit)
      ) {
        const myBorrowLimit = JSON.stringify(clientBorrowLimit);
        setFormData({
          ...formData,
          [e.target.name]: myBorrowLimit,
          ["Balance"]: myBorrowLimit,
        });
        if (
          NumberOfMonths &&
          Interest &&
          interestOn &&
          DateTime &&
          myBorrowLimit
        ) {
          setPaymentPeriods([
            ...generatePaymentPeriods(
              NumberOfMonths,
              Interest,
              interestOn,
              DateTime,
              myBorrowLimit
            ),
          ]);
        } else {
          setPaymentPeriods([]);
        }
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          ["Balance"]: e.target.value,
        });
        if (
          NumberOfMonths &&
          Interest &&
          interestOn &&
          DateTime &&
          e.target.value
        ) {
          setPaymentPeriods([
            ...generatePaymentPeriods(
              NumberOfMonths,
              Interest,
              interestOn,
              DateTime,
              e.target.value
            ),
          ]);
        } else {
          setPaymentPeriods([]);
        }
      }
      return;
    }
    if (e.target.name == "Client_id") {
      const myClient = e.target.value;
      const client_id = e.target.Client_id;
      if (client_id) {
        const { data } = await getMaxRemainingCreditClient(e.target.Client_id);
        setClientBorrowLimit(parseInt(data.amount));
        setBonusLimit(data.bonus);
      }

      setFormData({ ...formData, [e.target.name]: myClient });
      getissuancehistoryByClientId(myClient).then((res) => {
        if (res.data?.length > 0) {
          setFormData({
            ...formData,
            [e.target.name]: myClient,
            ["Pincode"]: res.data[0].Pincode,
            ["NfcCard_id"]: res.data[0].NfcCard_id,
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: myClient,
            ["Pincode"]: autoPincode,
            ["NfcCard_id"]: "",
          });
        }
      });
      return;
    }
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      alert(valid);
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePaymentTypeChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const fallsWithinDate = (currDate) => {
    const startDate = new Date("2023-04-10");
    const endDate = new Date("2023-05-17");
    if (currDate >= startDate && currDate <= endDate) return true;
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const createIssuanceData = { ...formData, handledByUserId: getUserId() };
    addIssuanceHistory(createIssuanceData)
      .then((formRes) => {
        //insurance
        if (merchantFormData.length > 0) {
          const numberOfMonthsId = merchantFormData[0].PaybackPeriod;
          getMerchantTypeDiscountById(numberOfMonthsId)
            .then((res) => {
              // if (res.data.NumberOfMonths == 4 || res.data.NumberOfMonths == 6 || res.data.NumberOfMonths == 10) {
              //   addInsurance(formData.Amount, formRes.data.id, formData.paymentType).then(insRes => {
              //   })
              //     .catch(err => {
              //     })
              // }
              // else if (res.data.NumberOfMonths == 1) {
              //   addInsurance(0, formRes.data.id, formData.paymentType).then(insRes => {
              //   })
              //     .catch(err => {
              //     })
              // }
            })
            .catch((rowErr) => {});
          // MFD (MerchantFormData)
          merchantFormData.map((MFD, index) => {
            const rowData = {
              id: JSON.stringify(Math.random() * 100000 * Math.random()),
              issuancehistoryId: formRes.data.id,
              merchantId: MFD.Merchants_id,
              numberOfMonthsId: MFD.PaybackPeriod,
            };
            addIssuanceHistoryRow(rowData)
              .then((rowRes) => {})
              .catch((rowErr) => {});
          });
        }

        // PP (PaybackPeriod)
        if (PaymentPeriods.length > 0) {
          // temp change from 30th oct till 30th nov for 10 months payback period
          PaymentPeriods.map((PP) => {
            const myPaybackPeriod = {
              date: PP.DateTime,
              amount: PP.type == 3 ? 0 : PP.Amount,
              status: "NotPaid",
              issuanceHistory_Id: formRes.data.id,
              type: PP.type,
            };
            addIssuanceHistoryPaybackPeriod(myPaybackPeriod)
              .then((PPRes) => {})
              .catch((PPErr) => {});
          });
        } else {
          const issuedDate = new Date(formData.DateTime);
          var nextMonth = new Date(
            issuedDate.getFullYear(),
            issuedDate.getMonth() + 1,
            2
          );
          var nextMonth1 = new Date(
            nextMonth.getFullYear(),
            nextMonth.getMonth() + 1,
            2
          );
          nextMonth = nextMonth.toISOString();
          nextMonth1 = nextMonth1.toISOString();
          const myPaybackPeriod1 = {
            invoiceNumber: getUniqueString(),
            date: nextMonth,
            amount: 0,
            type: 1,
            TypeOfReturnPayment: "Cash",
            status: "NotPaid",
            issuanceHistory_Id: formRes.data.id,
          };
          addIssuanceHistoryPaybackPeriod(myPaybackPeriod1)
            .then((PPRes) => {})
            .catch((PPErr) => {});

          const myPaybackPeriod2 = {
            invoiceNumber: getUniqueString(),
            date: nextMonth1,
            amount: 0,
            type: 2,
            TypeOfReturnPayment: "Cash",
            status: "NotPaid",
            issuanceHistory_Id: formRes.data.id,
          };
          addIssuanceHistoryPaybackPeriod(myPaybackPeriod2)
            .then((PPRes) => {})
            .catch((PPErr) => {});

          // const myPaybackPeriod3 = {
          //   invoiceNumber: getUniqueString(),
          //   date: nextMonth1,
          //   amount: 0,
          //   type: 4, // type 4 is pharmacy
          //   TypeOfReturnPayment: "Cash",
          //   status: "NotPaid",
          //   issuanceHistory_Id: formRes.data.id,
          // };
          // addIssuanceHistoryPaybackPeriod(myPaybackPeriod3)
          //   .then((PPRes) => {})
          //   .catch((PPErr) => {
          //   });

          const myPaybackPeriod4 = {
            invoiceNumber: getUniqueString(),
            date: nextMonth1,
            amount: 0,
            type: 5,
            TypeOfReturnPayment: "Cash",
            status: "NotPaid",
            issuanceHistory_Id: formRes.data.id,
          };
          addIssuanceHistoryPaybackPeriod(myPaybackPeriod4)
            .then((PPRes) => {})
            .catch((PPErr) => {});
        }
        history.push("/admin/ClientList");
        setIsLoading(false);
      })
      .catch((formErr) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isChecked && gasMerchantsCheck) {
      let merchantTypeData = null;

      getMerchantTypeDiscountByMerchantType_id("prefix-24")
        .then((res) => {
          console.log("===res", res.data);
          merchantTypeData = res.data[0];
          setPaybackPeriodAgainstMerchantId([...res.data]);

          getMerchantList()
            .then((res) => {
              const PompstationData = res.data.filter(
                (item) => item.MerchantType_id === "prefix-24"
              );

              const allGasMerchantData = PompstationData.map((item) => ({
                id: Math.random() * 1000 * Math.random(),
                Merchants_id: item.id,
                PaybackPeriod: merchantTypeData?.id,
              }));

              setNumberOfMonths(1);
              setInterest(merchantTypeData.Interest);
              setInterestOn("Gas");
              setMerchantFormData([]);
              setMerchantFormData(allGasMerchantData);
              setGasMerchants(PompstationData);
              setPaymentPeriods([
                ...generatePaymentPeriods(
                  1,
                  merchantTypeData.Interest,
                  "Gas",
                  DateTime,
                  Amount
                ),
              ]);
            })
            .catch((error) => {
              // Handle any errors from the API call
              console.error("Error fetching merchant list:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching merchant type discount:", error);
        });
    }
  }, [isChecked, gasMerchantsCheck]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h4" className="text-center m-3 heading">
                Card Issuance
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Date Field */}
                  <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Form.Group>
                      <label className="requiredelement">Date</label>
                      <Form.Control
                        required
                        placeholder="123"
                        type="date"
                        name="DateTime"
                        disabled={ClientID ? true : false}
                        value={DateTime}
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Client ID Field */}
                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label
                        htmlFor="exampleLastName"
                        className="requiredelement"
                      >
                        Kliente Code
                      </label>

                      {!ClientID ? (
                        <CustomSelect
                          options={allClients} // Options to display in the dropdown
                          // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          isDisabled={ClientID ? true : false}
                          isSearchable={true}
                          isMulti={false}
                          hideSelectedOptions={false}
                          placeholder={"Select Client.."}
                          components={{
                            Option,
                            MultiValue,
                            animatedComponents,
                          }}
                          onChange={async (e) => {
                            const client_id = e.value;
                            if (defaulterClients.indexOf(client_id) >= 0) {
                              if (
                                window.confirm("This client is a defaulter")
                              ) {
                              }
                            }
                            if (client_id) {
                              const { data } =
                                await getMaxRemainingCreditClient(client_id);
                              setClientBorrowLimit(data.amount);
                              setBonusLimit(data.bonus);
                            }
                            getissuancehistoryByClientId(client_id).then(
                              (res) => {
                                if (res.data?.length > 0) {
                                  setSelectedNfcCard({
                                    value: res.data[0].NfcCard_id,
                                    label: res.data[0].NfcCard_id,
                                  });
                                  setFormData({
                                    ...formData,
                                    Client_id: e.value,
                                    ["Pincode"]: res.data[0].Pincode,
                                    ["NfcCard_id"]: res.data[0].NfcCard_id,
                                  });
                                } else {
                                  setFormData({
                                    ...formData,
                                    Client_id: e.value,
                                    ["Pincode"]: autoPincode,
                                    ["NfcCard_id"]: "",
                                  });
                                }
                              }
                            );
                          }}
                          allowSelectAll={false}
                        />
                      ) : (
                        <Form.Control
                          required
                          type="text"
                          name="Client_id"
                          disabled
                          value={formData.Client_id}
                        ></Form.Control>
                      )}
                    </Form.Group>
                  </Col>
                  {/* NFC Card Field */}

                  <Col xs="12" sm="12" md="12" lg="6" xl="6">
                    <Form.Group>
                      <label className="requiredelement">NFC Card</label>
                      {!ClientID ? (
                        <CustomSelect
                          options={allNfcCards} // Options to display in the dropdown
                          // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          isDisabled={ClientID ? true : false}
                          isSearchable={true}
                          isMulti={false}
                          hideSelectedOptions={false}
                          placeholder={"Select NFC Card.."}
                          components={{
                            Option,
                            MultiValue,
                            animatedComponents,
                          }}
                          onChange={(e) => {
                            setSelectedNfcCard(e);
                            setFormData({ ...formData, NfcCard_id: e.value });
                          }}
                          value={selectedNfcCard}
                          allowSelectAll={false}
                        />
                      ) : (
                        <Form.Control
                          required
                          type="text"
                          name="NfcCard_id"
                          disabled
                          value={formData.NfcCard_id}
                        ></Form.Control>
                      )}
                      {/* <Form.Control
                        required as="select" name="NfcCard_id" disabled={ClientID ? true : false}
                        value={NfcCard_id} onChange={(e) => { handleInputChange(e) }}
                      >
                        {allNfcCards.map((item) => {
                          return (<option value={item.id}>{item.number}</option>);
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Amount Field */}
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg={role === 0 || role === 5 ? "6" : "12"}
                    xl={role === 0 || role === 5 ? "6" : "12"}
                  >
                    <Form.Group>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <label className="requiredelement">Montante</label>
                        <div
                          style={{
                            marginLeft: "20px",
                            color: "#9A9A9A",
                            fontSize: "15px",
                          }}
                        >
                          Bonus:&nbsp;{bonusLimit} &nbsp;&nbsp; Max:&nbsp;
                          {clientBorrowLimit}
                        </div>
                      </div>
                      <Form.Control
                        required
                        placeholder="100"
                        type="number"
                        name="Amount"
                        disabled={ClientID ? true : false}
                        value={Amount}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* Code Pin Field */}
                  {role === 0 || role === 3 || role === 5 ? (
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg={role === 3 ? "12" : "6"}
                      xl={role === 3 ? "12" : "6"}
                    >
                      <Form.Group>
                        <label className="requiredelement">Pin Code</label>
                        <Form.Control
                          required
                          placeholder="123"
                          type="text"
                          name="Pincode"
                          disabled={ClientID ? true : false}
                          value={Pincode}
                          onChange={(e) => handleInputChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                {role === 0 || role === 3 || role === 5 ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      style={{
                        marginRight: "8px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <label className="pt-2">Select pompstations Client</label>
                  </div>
                ) : null}

                {isChecked && (
                  <Row className="align-items-center">
                    {/* Merchant Field */}
                    {gasMerchantsCheck === true &&
                      allGasMerchants.map((currentMerchant, index) => (
                        <React.Fragment key={index}>
                          <Col xs="12" sm="12" md="12" lg="12" xl="5">
                            <Form.Group>
                              <label className="requiredelement">
                                Merchant {index}
                              </label>
                              <Form.Control
                                as="select"
                                name="merchant_id"
                                disabled={ClientID ? true : false}
                                value={
                                  currentMerchant ? currentMerchant.name : ""
                                }
                              >
                                <option>{currentMerchant?.Name}</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please provide a value.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          {/* Number of Months Field */}
                          <Col xs="12" sm="12" md="12" lg="8" xl="5">
                            <Form.Group>
                              <label className="requiredelement">
                                Number Of Months {index}
                              </label>
                              <Form.Control
                                as="select"
                                name="PaybackPeriod"
                                disabled={ClientID ? true : false}
                                value={
                                  currentMerchant.paybackPeriod
                                    ? currentMerchant.paybackPeriod
                                    : ""
                                }
                              >
                                <option value={currentMerchant.paybackPeriod}>
                                  1
                                </option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please provide a value.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="4" xl="2">
                            <Button
                              className="btn-fill res-size"
                              variant="danger"
                              style={{
                                width: "100%",
                                border: "none",
                                marginBottom: "-9px",
                              }}
                              disabled={ClientID ? true : false}
                              onClick={() => {
                                handleRemoveGasMerchant(currentMerchant.id);
                              }}
                            >
                              <i class="fas fa-trash"></i>
                            </Button>
                          </Col>
                        </React.Fragment>
                      ))}
                  </Row>
                )}

                {!isChecked && !gasMerchantsCheck && merchantFormData.length > 0
                  ? merchantFormData.map((merchantItem, index) => (
                      <MerchantRow
                        RowIndex={index}
                        isaddMerchantChecked={isaddMerchantChecked}
                        allGasMerchants={allGasMerchants}
                        ClientID={ClientID}
                        merchantsId={merchantItem.Merchants_id}
                        getMerchantId={(e) => {
                          getMerchantId(merchantItem.id, e);
                        }}
                        paybackPeriod={merchantItem.PaybackPeriod}
                        getPaybackPeriod={(period) => {
                          getPaybackPeriod(merchantItem.id, period);
                        }}
                        onRemove={() => {
                          handleRemoveMerchant(merchantItem.id);
                        }}
                      />
                    ))
                  : ""}

                {PaymentPeriods.length > 0
                  ? PaymentPeriods.map((periodItem, periodIndex) => (
                      <Row style={{ alignItems: "center" }}>
                        <Col xs="12" sm="12" md="12" lg="12" xl="4">
                          <Form.Group>
                            <label>Payback Period&nbsp;{periodIndex + 1}</label>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="12" xl="4">
                          <Form.Group>
                            <label>Date</label>
                            <Form.Control
                              type="date"
                              value={periodItem.DateTime}
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="12" xl="4">
                          <Form.Group>
                            <label>Amount</label>
                            <Form.Control
                              type="number"
                              value={parseFloat(periodItem.Amount).toFixed(2)}
                              disabled
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    ))
                  : ""}

                {/* Form Buttons Row */}
                <Row className="text-center justify-content-center mt-2">
                  <Col md="12">
                    <div className="button-wrapper">
                      {ClientID ? (
                        ""
                      ) : (
                        <>
                          <Button
                            className="btn-fill res-size"
                            variant="info"
                            style={{ border: "none" }}
                            disabled={isChecked || gasMerchantsCheck}
                            onClick={() => {
                              handleAddMerchant();
                            }}
                          >
                            Add Merchant Row
                          </Button>
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                          >
                            {isLoading ? (
                              <div>
                                {" "}
                                <i class="fa fa-spinner" aria-hidden="true">
                                  &nbsp;
                                </i>{" "}
                                Saving...
                              </div>
                            ) : (
                              <div>Save</div>
                            )}
                          </Button>
                        </>
                      )}
                      <Button
                        className="btn-fill res-size"
                        variant="danger"
                        style={{ border: "none" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>

                      {Amount && NumberOfMonths > 0 && (
                        <PdfGenCard
                          staticArray={[
                            { key: "Date", value: DateTime },
                            { key: "Kliente Code", value: Client_id },
                            {
                              key: "Montante",
                              value: parseFloat(Amount).toFixed(2),
                            },
                            // { key: "Insurance Amount", value: parseFloat(Amount) * 1 / 100 }
                          ]}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default IssuanceCardForm;

// {Amount && NumberOfMonths > 0 && <Row>

//   {/* <Col xs="12" sm="12" md="12" lg={"6"} xl={"6"}>
//     <Form.Group>
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <label className="requiredelement">Insurance Amount</label>
//       </div>
//       <Form.Control
//         required placeholder="100" type="number" name="Amount" disabled={true} value={
//           NumberOfMonths == 4 || NumberOfMonths == 6 || NumberOfMonths == 10 ? parseFloat(Amount) * 1 / 100 :
//             NumberOfMonths
//         }
//         onChange={(e) => { handleInputChange(e) }}
//       ></Form.Control>
//       <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
//     </Form.Group>
//   </Col> */}
//   <Col xs="12" sm="12" md="12" lg={"6"} xl={"6"}>
//     <Form.Group>
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <label className="requiredelement">Payment Type</label>
//       </div>
//       <Form.Control
//         required as="select" name="paymentType" value={paymentType}
//         onChange={(e) =>
//           handlePaymentTypeChange(e)
//         }
//       >
//         <option></option>
//         <option value="Cash" selected>Cash</option>
//         <option value="MCB">MCB</option>
//         <option value="RBC">RBC</option>
//         <option value="Banco Di Caribe">Banco Di Caribe</option>
//         <option value="Maestro">Maestro</option>
//       </Form.Control>
//       <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback>
//     </Form.Group>
//   </Col>
// </Row>}
