import React, { useEffect } from "react";
//          Importing CSS Files
import "../Dashboard.css";
//          Importing Components
import { Button, Form, Row, Col } from "react-bootstrap";
//          Importing Services
import getMerchantList from "../../services/merchant";
import { getMerchantData } from "../../services/merchant";
import {
  getMerchantTypeDiscountByMerchantType_id,
  getMerchantTypeData,
} from "../../services/merchantType";

import CustomSelect from "utils/CustomSelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

export const MerchantRow = ({
  RowIndex,
  ClientID,
  merchantsId,
  isaddMerchantChecked,
  getMerchantId,
  paybackPeriod,
  getPaybackPeriod,
  onRemove,
}) => {
  console.log("===merchantsId", merchantsId);

  const [allMerchants, setAllMerchants] = React.useState([]);
  const [paybackPeriodAgainstMerchantId, setPaybackPeriodAgainstMerchantId] =
    React.useState([]);
  const [interestOn, setInterestOn] = React.useState("Client");
  const [loading, setLoading] = React.useState(false);
  const [merchantName, setMerchantName] = React.useState(false);
  // Get Merchants
  useEffect(() => {
    if (merchantsId) return;
    if (isaddMerchantChecked) {
      getMerchantList()
        .then((res) => {
          const formattedData = res.data.map((item) => {
            return {
              value: item.id,
              fields: item,
              label: `${item.Name}: ${item.Code}`,
            };
          });
          // setAllMerchants([
          //     ...res.data.sort((a, b) => {
          //         if (a.Name < b.Name) { return -1; }
          //         if (a.Name > b.Name) { return 1; }
          //         return 0;
          //     })
          // ]);
          setAllMerchants(formattedData);
        })
        .catch((err) => {});
    }
  }, []);
  // Get Number Of Months Against Merchant
  useEffect(() => {
    if (
      merchantsId &&
      paybackPeriodAgainstMerchantId.length === 0 &&
      isaddMerchantChecked
    ) {
      getMerchantData(merchantsId)
        .then((resp) => {
          setMerchantName(resp.data.Name);
          getMerchantTypeDiscountByMerchantType_id(resp.data.MerchantType_id)
            .then((res) => {
              setPaybackPeriodAgainstMerchantId([...res.data]);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    } else {
      return;
    }
  }, [merchantsId]);
  // Get Merchant Type Against Merchant
  const getMerchantTypeByMerchantId = (Merchant) => {
    setLoading(true);
    getMerchantTypeData(Merchant.MerchantType_id)
      .then((res) => {
        if (res.data.interestOn === null) {
          setInterestOn("Client");
        } else {
          setInterestOn(res.data.interestOn);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Row className="align-items-center">
      {/* Merchant Field */}
      <Col xs="12" sm="12" md="12" lg="12" xl="5">
        <Form.Group>
          <label className="requiredelement">Merchant</label>
          {!ClientID ? (
            <CustomSelect
              options={allMerchants} // Options to display in the dropdown
              // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
              isDisabled={ClientID ? true : false}
              isSearchable={true}
              isMulti={false}
              hideSelectedOptions={false}
              placeholder={"Select Merchant.."}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => {
                getMerchantId(e.fields);
                getMerchantTypeByMerchantId(e.fields);
                setPaybackPeriodAgainstMerchantId([]);
              }}
              allowSelectAll={false}
            />
          ) : (
            <Form.Control
              required
              type="text"
              name="Merchants_id"
              disabled
              value={merchantName}
            ></Form.Control>
          )}
          {/* <Form.Control
                        as="select" name="Merchants_id" disabled={ClientID ? true : false} value={merchantsId ? merchantsId : ""}
                        onChange={(e) => {
                            const value = e.target.value;
                            

                            let MIndex = -1;
                            MIndex = allMerchants.findIndex(item => item.id === value);
                            if (MIndex >= 0 && getMerchantId) { getMerchantId(allMerchants[MIndex]) }
                            if (RowIndex === 0) { getMerchantTypeByMerchantId(allMerchants[MIndex]) }
                            setPaybackPeriodAgainstMerchantId([]);
                        }}
                    >
                        <option></option>
                        {allMerchants.map((item) => (<option value={item.id}>{`${item.Name}: ${item.Code}`}</option>))}
                    </Form.Control> */}
          <Form.Control.Feedback type="invalid">
            Please provide a value.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      {/* Number of Months Field */}
      <Col xs="12" sm="12" md="12" lg="8" xl="5">
        <Form.Group>
          <label className="requiredelement">Number Of Months</label>
          <Form.Control
            as="select"
            name="PaybackPeriod"
            disabled={ClientID || loading ? true : false}
            value={paybackPeriod ? paybackPeriod : ""}
            onChange={(e) => {
              const value = e.target.value;
              let PPIndex = -1;
              PPIndex = paybackPeriodAgainstMerchantId.findIndex(
                (item) => item.id === value
              );
              if (PPIndex >= 0 && getPaybackPeriod) {
                if (RowIndex === 0) {
                  getPaybackPeriod({
                    ...paybackPeriodAgainstMerchantId[PPIndex],
                    interestOn: interestOn,
                  });
                } else {
                  getPaybackPeriod({
                    ...paybackPeriodAgainstMerchantId[PPIndex],
                  });
                }
              }
            }}
          >
            <option></option>
            {paybackPeriodAgainstMerchantId.map((item) => {
              return <option value={item.id}>{item.NumberOfMonths}</option>;
            })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please provide a value.
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs="12" sm="12" md="12" lg="4" xl="2">
        <Button
          className="btn-fill res-size"
          variant="danger"
          style={{ width: "100%", border: "none", marginBottom: "-9px" }}
          disabled={ClientID ? true : false}
          onClick={onRemove ? onRemove : () => {}}
        >
          <i class="fas fa-trash"></i>
        </Button>
      </Col>
    </Row>
  );
};
