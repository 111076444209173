import { getToken } from "./auth";
import address from "./address";
import axios from "axios";

export default function getClientList() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/clients/getAllClients", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getClientListForIssuance() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/clients/getAllClientsForIssuance", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getActiveClientList() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/clients/getAllActiveClients", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getAllActiveClientList() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/clients/getAllActiveAllClients", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getNextId() {
  return axios.get(address + "/api/public/clients/getNextM_Id");
}

export function getNextDId() {
  const token = getToken();
  return axios.get(address + "/api/clients/getNextD_Id", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getClientData(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/clients/getClientById/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getImageByClientId(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(
    address + "/api/clientProfilePicture/getImageByClientId/" + id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
export function getClientMaxBorrowAmountById(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(
    address + "/api/clients/getClientMaxBorrowAmountById/" + id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function getRemainingPayBacksList() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/clients/remainingPayBacksList", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getRemainingThisYearPayBacksList() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/clients/remainingThisYearPayBacksList", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function getPrepaidID() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.get(address + "/api/clients/getPrepaidID", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function updateClient(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.post(address + "/api/clients/upsertClient", formData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function addClient(formData, typeOfClient) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  return axios.post(
    address + "/api/clients/createClient/" + typeOfClient,
    formData,
    {
      headers,
    }
  );
}

export function deleteClient(id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.delete(address + "/api/clients/deleteClient/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function deleteClientImage(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.delete(
    address + "/api/clientProfilePicture/delete/" + Client_id,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function deleteClientSalarySlips(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.delete(address + "/api/css/delete/" + Client_id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function deleteClientBankStatement(Client_id) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";

  return axios.delete(address + "/api/cbs/delete/" + Client_id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export function addClientImage(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(
    address + "/api/clientProfilePicture/createImage",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    }
  );
}

export function addClientProofOfAddress(formData) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.post(address + "/api/public/cpoa/createFile", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
}

export function checkSedulaExist(idCard) {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/clients/checkSedulaExist/" + idCard, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export function getAllDealers() {
  const token = getToken();
  if (!token) return "Authentication Fail Sign In agian";
  return axios.get(address + "/api/clients/allDealers", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
