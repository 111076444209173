import React, { useEffect, useRef } from "react";
//          Importing CSS Files
import "./clientForm.css";
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import ClientNoboForm from "./ClientNoboForm";
//          Importing Services
import {
  getClientData,
  updateClient,
  addClient,
  getActiveClientList,
  getNextId,
  getImageByClientId,
  addClientImage,
  addClientProofOfAddress,
} from "../services/client";
import _uniqueId from "lodash/uniqueId";
import useOutsideAlerter from "utils/outsideClick";
import { checkSedulaExist } from "services/client";
import ClientPrepaidForm from "./ClientPrepaidForm";
import { toast, ToastContainer } from "react-toastify";
const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
};

function ClientForm() {
  let userRole = JSON.parse(localStorage.getItem("role"));
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [ClientID, setClientID] = React.useState(null);
  const [uniqueID] = React.useState(_uniqueId("prefix-"));
  const [dealers, setDealers] = React.useState([]);
  const [typeOfClient, setTypeOfClient] = React.useState(null);
  const [fuentediEntrada, setFuentediEntrada] = React.useState(null);
  const [workNoOption, setWorkNoOption] = React.useState("+5999");
  const [contactNoOption, setContactNoOption] = React.useState("+5999");
  const [file, setFile] = React.useState(null);
  const [addressFile, setAddressFile] = React.useState(null);
  const [fileId, setFileId] = React.useState(null);
  const [addressFileId, setAddressFileId] = React.useState(null);
  const [fileChanged, setFileChanged] = React.useState(false);
  const [addressFileChanged, setAddressFileChanged] = React.useState(false);

  const [formData, setFormData] = React.useState({
    id: "",
    Date: "2021-01-01",
    Code: "",
    address: "",
    FirstName: "",
    LastName: "",
    idCard: "",
    WorkNo: "",
    ContactNo: "",
    WorksAt: "",
    Email: "",
    FaxNumber: "",
    Status: 1,
    MaxBorrowAmount: "",
    ExpiryDate: "",
    RegistrationDate: getCurrentDate() || "",
    Dealer_id: "",
    SourceOfIncome: "",
    RecievedCreditInPast: false,
    dealerStatus: false,
    dealerCommision: false,
    Remarks: "",
  });
  const wrapperRef = useRef(null);

  // useOutsideAlerter(wrapperRef);
  useEffect(() => {
    const params = queryParams.get("id");
    if (params != null) {
      setClientID(params);
    } else {
      setFileId(uniqueID);
      setAddressFileId(uniqueID);
    }
    getActiveClientList()
      .then((response) => {
        response.data.unshift({});
        setDealers(response.data);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    if (typeOfClient == 0 && ClientID === null) {
      getNextId()
        .then((response) => {
          setFormData({
            ...formData,
            ["id"]: response.data.id,
            ["Code"]: response.data.id,
          });
        })
        .catch((error) => {});
    } else if (typeOfClient == 1 && ClientID === null) {
      setFormData({ ...formData, ["id"]: "D-", ["Code"]: "D-", ["Status"]: 1 });
    } else if (typeOfClient == 3 && ClientID === null) {
      setFormData({
        ...formData,
        ["id"]: uniqueID,
        ["Code"]: "",
        ["Status"]: 1,
      });
    }
  }, [typeOfClient]);
  useEffect(() => {
    if (ClientID == null) return;
    if (ClientID == null) return;
    getClientData(ClientID)
      .then((response) => {
        if (ClientID.charAt(0) == "M") {
          setTypeOfClient(0);
        } else if (ClientID.charAt(0) == "D") {
          setTypeOfClient(1);
        } else if (ClientID.charAt(0) == "N") {
          setTypeOfClient(2);
        } else {
          setTypeOfClient(3);
        }
        let workNoArray = response.data.WorkNo.split(" ");
        let contactNoArray = response.data.ContactNo.split(" ");
        response.data.WorkNo = workNoArray[1];
        setWorkNoOption(workNoArray[0]);
        response.data.ContactNo = contactNoArray[1];
        setContactNoOption(contactNoArray[0]);
        setFormData(response.data);
        if (response.data.WorksAt) {
          setFuentediEntrada(1);
        }
        if (response.data.SourceOfIncome) {
          setFuentediEntrada(2);
        }
      })
      .catch((error) => {});
    getImageByClientId(ClientID)
      .then((resp) => {
        setFileId(resp.data.id);
        setFile(resp.data.avatar);
      })
      .catch((error) => {});
  }, [ClientID]);
  const {
    id,
    Code,
    FirstName,
    LastName,
    idCard,
    WorkNo,
    ContactNo,
    WorksAt,
    Email,
    address,
    FaxNumber,
    Status,
    Bonus,
    MaxBorrowAmount,
    Dealer_id,
    max_gasoline,
    SourceOfIncome,
    RecievedCreditInPast,
    dealerStatus,
    dealerCommision,
    Date,
    ExpiryDate,
    RegistrationDate,
    Remarks,
  } = formData;

  const validateInput = (name, value) => {
    if (name === "Code" || name === "WorksAt") {
      let pattern = new RegExp("^[a-zA-Z 0-9_.-]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "No special characters";
    }
    if (name === "FirstName" || name === "LastName") {
      let pattern = new RegExp("^[a-zA-Z ]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only alphabets and spaces";
    }
    if (
      name === "WorkNo" ||
      name === "FaxNumber" ||
      name === "ContactNo" ||
      name == "idCard"
    ) {
      let pattern = new RegExp("^[0-9 +]*$");
      if (pattern.test(value)) {
        return true;
      }
      return "only numbers or spaces";
    }
    return true;
  };
  const handleFileSubmit = () => {
    const data = new FormData();
    data.append("file", file);
    data.append("id", fileId);
    data.append("Client_id", formData.id);
    return addClientImage(data);
  };
  const handleAddressFileSubmit = () => {
    const data = new FormData();
    data.append("file", addressFile);
    data.append("id", addressFileId);
    data.append("Client_id", formData.id);
    return addClientProofOfAddress(data);
  };
  const handleInputChange = (e) => {
    if (e.target.name == "dealerStatus") {
      setFormData({ ...formData, [e.target.name]: !dealerStatus });
      return;
    }
    if (e.target.name == "dealerCommision") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      return;
    }
    if (e.target.name == "Status") {
      setFormData({ ...formData, [e.target.name]: !Status });
      return;
    }
    if (e.target.name == "RecievedCreditInPast") {
      setFormData({ ...formData, [e.target.name]: !RecievedCreditInPast });
      return;
    }
    if (e.target.name == "Code") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        id: e.target.value,
      });
      return;
    }
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateEmail = (email) => {
    if (email.length < 1) return true;
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(email)) {
      return true;
    }
    return "not a valid email";
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const valid = validateEmail(Email);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (fileChanged == false && file == null) {
      toast.warning("Select Image", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    if (addressFileChanged == false && file == null) {
      toast.warning("Select Proof Of Address", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    let fData = { ...formData };
    fData.WorkNo = workNoOption.trim() + " " + fData.WorkNo;
    fData.ContactNo = contactNoOption.trim() + " " + fData.ContactNo;
    // checkSedulaExist(fData.idCard)
    // .then(res => {
    //     if (res.data.exist == true)
    //       alert("Sedula already Exist")
    // })
    // .catch(err => )

    if (ClientID) {
      updateClient(fData)
        .then((response) => {
          handleFileSubmit()
            .then((response) => {})
            .catch((error) => {});
        })
        .catch((error) => {});
      history.push("/admin/ClientList");
    } else {
      if (typeOfClient == 1) {
        getClientData(fData.id)
          .then((response) => {
            if (response.data) {
              toast.warning("Dealer already Exist", {
                position: toast.POSITION.TOP_RIGHT,
              });
              return;
            } else {
              addClient(fData, typeOfClient)
                .then((response) => {
                  handleFileSubmit()
                    .then((response) => {})
                    .catch((error) => {});
                  handleAddressFileSubmit()
                    .then((response) => {})
                    .catch((error) => {});
                })
                .catch((error) => {});
              history.push("/admin/ClientList");
            }
          })
          .catch((error) => {});
      } else {
        addClient(fData, typeOfClient)
          .then((response) => {
            handleFileSubmit()
              .then((response) => {})
              .catch((error) => {});
            handleAddressFileSubmit()
              .then((response) => {})
              .catch((error) => {});
          })
          .catch((error) => {});
        history.push("/admin/ClientList");
      }
    }
  };
  const handleFileChange = (event) => {
    if (event.target.files.length !== 0) {
      setFile(event.target.files[0]);
      setFileChanged(true);
    }
  };

  const handleAddressFileChange = (event) => {
    if (event.target.files.length !== 0) {
      setAddressFile(event.target.files[0]);
      setAddressFileChanged(true);
    }
  };
  return (
    <>
      {queryParams.get("id") != null ? null : (
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title
                    as="h3"
                    className="text-center m-3 client-heading"
                  >
                    Formulario di Registrashon
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="requiredelement">
                            Type Of Kliente
                          </label>
                          <Form.Control
                            as="select"
                            defaultValue=""
                            name="Dealer_id"
                            value={typeOfClient}
                            onChange={(e) => {
                              setTypeOfClient(e.target.value);
                            }}
                          >
                            <option></option>
                            <option value={0}>Client</option>
                            <option value={2}>New Client</option>
                            <option value={3}>Existing Client</option>
                            <option value={4}>Prepaid Client</option>
                            <option value={1}>Dealer</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {typeOfClient == 0 || typeOfClient == 1 || typeOfClient == 3 ? (
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  {queryParams.get("id") == null ? null : (
                    <Card.Title as="h3" className="text-center m-3">
                      Formulario di Registrashon
                    </Card.Title>
                  )}
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm="12" lg="2">
                        <Form.Group>
                          <label className="requiredelement">Code</label>
                          <Form.Control
                            disabled={
                              typeOfClient == 0 || ClientID ? true : false
                            }
                            required
                            placeholder="123"
                            type="text"
                            name="Code"
                            value={Code}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="6" lg="5">
                        <Form.Group>
                          <label className="requiredelement">Nomber</label>
                          <Form.Control
                            required
                            placeholder="Frank"
                            type="text"
                            value={FirstName}
                            name="FirstName"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="6" lg="5">
                        <Form.Group>
                          <label
                            htmlFor="exampleLastName"
                            className="requiredelement"
                          >
                            Fam
                          </label>
                          <Form.Control
                            required
                            placeholder="Semper"
                            type="text"
                            value={LastName}
                            name="LastName"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label className="requiredelement">Adres</label>
                          <Form.Control
                            required
                            placeholder="Adres"
                            type="text"
                            value={address}
                            name="address"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="6">
                        <Form.Group>
                          <label className="requiredelement">Sedula</label>
                          <Form.Control
                            ref={wrapperRef}
                            required
                            placeholder=""
                            type="text"
                            value={idCard}
                            name="idCard"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                            onSw
                            maxLength={"10"}
                            minLength={"10"}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="6">
                        <Form.Group>
                          <label className="requiredelement">
                            Fecha di Vensementu
                          </label>
                          <Form.Control
                            required
                            placeholder="123"
                            type="date"
                            value={ExpiryDate}
                            name="ExpiryDate"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="6" lg="4">
                        <Form.Group>
                          <label className="requiredelement"> Celullar</label>
                          <Form.Control
                            as="select"
                            defaultValue=""
                            required
                            value={contactNoOption}
                            name="contactNoOption"
                            onChange={(e) => {
                              setContactNoOption(e.target.value);
                            }}
                          >
                            <option value={"+5999"}> +5999</option>
                            <option value={"+599"}> +599</option>
                            <option value={"+297"}> +297</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="6" lg="8">
                        <Form.Group>
                          <label> &nbsp;</label>
                          <Form.Control
                            required
                            placeholder="042"
                            type="text"
                            value={ContactNo}
                            name="ContactNo"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="6" lg="4">
                        <Form.Group>
                          <label> Telefon</label>
                          <Form.Control
                            as="select"
                            defaultValue=""
                            required
                            value={workNoOption}
                            name="workNoOption"
                            onChange={(e) => {
                              setWorkNoOption(e.target.value);
                            }}
                          >
                            <option value={"+5999"}> +5999</option>
                            <option value={"+599"}> +599</option>
                            <option value={"+297"}> +297</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm="12" md="6" lg="8">
                        <Form.Group>
                          <label>&nbsp;</label>
                          <Form.Control
                            placeholder="00-0000-00"
                            type="text"
                            value={WorkNo}
                            name="WorkNo"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12">
                        <Form.Group>
                          <label className="requiredelement">Email</label>
                          <Form.Control
                            placeholder="Email"
                            type="text"
                            value={Email}
                            name="Email"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="12">
                        <Form.Group>
                          <label>fecha di inskripshon</label>
                          <Form.Control
                            required
                            placeholder="123"
                            type="date"
                            value={RegistrationDate}
                            name="RegistrationDate"
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.RegistrationDate
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    {Code && Code === "M-1010" && userRole === 3 ? (
                      <div></div>
                    ) : (
                      <Row>
                        <Col sm="12" md="4">
                          <Form.Group>
                            <label className="requiredelement">
                              Kredito Maksimo
                            </label>
                            <Form.Control
                              required
                              placeholder="Kredito Maksimo"
                              type="number"
                              value={MaxBorrowAmount}
                              name="MaxBorrowAmount"
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm="12" md="4">
                          <Form.Group>
                            <label className="requiredelement">
                              Kredito Gasoline
                            </label>
                            <Form.Control
                              required
                              placeholder="Kredito Gasoline"
                              type="number"
                              value={parseInt(max_gasoline)}
                              name="max_gasoline"
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm="12" md="4">
                          <Form.Group>
                            <label className="requiredelement">Bonus</label>
                            <Form.Control
                              required
                              placeholder="Kredito Maksimo"
                              type="number"
                              value={Bonus}
                              name="Bonus"
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col sm="12" md="12">
                        <Form.Group>
                          <label className="requiredelement">
                            Fuente di Entrada
                          </label>
                          <Form.Control
                            as="select"
                            defaultValue=""
                            value={fuentediEntrada}
                            name="fuentediEntrada"
                            onChange={(e) => {
                              setFuentediEntrada(e.target.value);
                            }}
                          >
                            <option></option>
                            <option value={1}> Trabou</option>
                            <option value={2}> Penshonado</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    {fuentediEntrada == 1 ? (
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label className="requiredelement">
                              Ta Emplea Na
                            </label>
                            <Form.Control
                              required
                              placeholder="Ta traha na"
                              type="text"
                              value={WorksAt}
                              name="WorksAt"
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null}
                    {fuentediEntrada == 2 ? (
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label className="requiredelement">
                              Si bo no ta emplea, kiko ta bo medio di entrada ?
                            </label>
                            <Form.Control
                              as="textarea"
                              required
                              placeholder=""
                              value={SourceOfIncome}
                              name="SourceOfIncome"
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null}
                    {typeOfClient == 1 ? (
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label>Remarks</label>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              placeholder=""
                              name="Remarks"
                              value={Remarks}
                              onChange={(e) => handleInputChange(e)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {typeOfClient == 1 ? (
                      <Row>
                        <Col md="12">
                          <label className="requiredelement">
                            Commissionable?
                          </label>
                          <br />
                          <Form.Check
                            inline
                            label="Si"
                            type="Radio"
                            className="mr-5"
                            name="dealerStatus"
                            onClick={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          <Form.Check
                            inline
                            label="No"
                            type="Radio"
                            className="mr-5"
                            name="dealerStatus"
                            onClick={(e) => {
                              handleInputChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {typeOfClient == 1 && dealerStatus ? (
                      <Row>
                        <Col md="12">
                          <label className="requiredelement">
                            Dealer Commission
                          </label>
                          <br />
                          <Form.Check
                            inline
                            label="5%"
                            type="Radio"
                            className="mr-5"
                            value={5}
                            name="dealerCommision"
                            onClick={(e) => {
                              handleInputChange(e);
                            }}
                          />
                          <Form.Check
                            inline
                            label="6%"
                            type="Radio"
                            className="mr-5"
                            value={6}
                            name="dealerCommision"
                            onClick={(e) => {
                              handleInputChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    <Row>
                      <Col md="12">
                        <label className="requiredelement">
                          A yega di tuma bon den pasado kaba? &nbsp;
                        </label>
                        <br />
                        <Form.Check
                          inline
                          label="Si"
                          type="Radio"
                          className="mr-5"
                          name="RecievedCreditInPast"
                          checked={RecievedCreditInPast}
                          onClick={(e) => {
                            handleInputChange(e);
                          }}
                        />
                        <Form.Check
                          inline
                          label="No"
                          type="Radio"
                          className="mr-5"
                          name="RecievedCreditInPast"
                          checked={!RecievedCreditInPast}
                          onClick={(e) => {
                            handleInputChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                    {RecievedCreditInPast == true &&
                      (typeOfClient == 0 || formData.Code.includes("M") ? (
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label className="requiredelement">
                                Si e Kontesta ta si, serka ken?
                              </label>
                              <Form.Control
                                as="select"
                                defaultValue=""
                                value={Dealer_id}
                                name="Dealer_id"
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                              >
                                {dealers.map((item, index) => {
                                  if (index == 0) {
                                    return (
                                      <option value={item.id}>
                                        {" "}
                                        Dealers :{" "}
                                        {index == 0 ? Dealer_id : item.Code}
                                      </option>
                                    );
                                  }
                                  return (
                                    <option value={item.id}>
                                      {" "}
                                      {item.Code}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please provide a value.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : null)}
                    {
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label className="requiredelement">
                              Porfabor agrega un potret di bo Sedula
                            </label>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              name="profilePicture"
                              className="file-res-size"
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    }
                    {file && (
                      <Row>
                        <Col md="12">
                          <img
                            src={
                              !fileChanged ? file : URL.createObjectURL(file)
                            }
                            className="profilePic"
                          />
                        </Col>
                      </Row>
                    )}
                    {
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <label className="requiredelement">
                              Proof of address
                            </label>
                            <Form.Control
                              type="file"
                              // accept="image/*"
                              name="proofOfAddress"
                              className="file-res-size"
                              onChange={(e) => {
                                handleAddressFileChange(e);
                              }}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a value.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label>Notes</label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder=""
                            name="Remarks"
                            value={Remarks}
                            onChange={(e) => handleInputChange(e)}
                          ></Form.Control>
                          {/* <Form.Control.Feedback type="invalid">Please provide a value.</Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="text-center mt-2">
                      <Col md="12">
                        <div className="button-wrapper">
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                          >
                            Save
                          </Button>
                          <Link to="/admin/ClientList">
                            <Button
                              className="btn-fill res-size"
                              variant="danger"
                              style={{
                                border: "none",
                              }}
                            >
                              Back
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      ) : typeOfClient == 2 ? (
        <ClientNoboForm />
      ) : typeOfClient == 4 ? (
        <ClientPrepaidForm />
      ) : (
        ""
      )}
    </>
  );
}
export default ClientForm;
