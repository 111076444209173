import React, { useEffect } from "react";
//  Importing CSS Files
import "../components/Dashboard.css";
//  Importing Routes
import { useHistory } from "react-router-dom";
//  Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
//  Importing Services

import getAllDealerBulkPayment from "services/dealerBulkPayment";
import { deleteDealerBulkPayment } from "services/dealerBulkPayment";

import moment from "moment";
import { getRole } from "services/auth";

function DealerBulkPaymentList() {
  const [tableData, setTableData] = React.useState(null);
  const history = useHistory();
  // const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState(null);
  const [totalToday, setTotalToday] = React.useState(null);
  var totalTodayTemp = 0;

  function checkDate(date) {
    return moment().isSame(date, "day");
  }

  useEffect(() => {
    if (parseInt(getRole()) == 1) return;
    setFilterTableData([]);
    getAllDealerBulkPayment()
      .then((response) => {
        response.data.sort((a, b) => b.Date.localeCompare(a.Date));
        // response.data.sort((a, b) => new Date(...a.Date.split("T")[0].split('-').reverse()) - new Date(...b.Date.split("T")[0].split('-').reverse()));
        setTableData(response.data);
        if (response.data.length > 0) {
          response.data.forEach((item) => {
            if (checkDate(item.Date)) {
              totalTodayTemp += parseInt(item.Amount);
            }
          });
          setTotalToday(totalTodayTemp);
        }

        get;
      })
      .catch((error) => {});
  }, []);

  function sortHandler(a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  // const sortedArrayData = tableData;
  // sortedArrayData.sort(sortHandler);
  // useEffect(() => {
  //     let tempTable = [];
  //     tableData && tableData.map((item) => {
  //         const Code = item?.Code?.toLowerCase();
  //         const AccountNo = item?.AccountNo?.toLowerCase();
  //         const BankName = item?.BankName?.toLowerCase();
  //         const Name = item?.Name?.toLowerCase();
  //         if (
  //             Code.includes(toSearch.toLowerCase()) ||
  //             AccountNo.includes(toSearch.toLowerCase()) ||
  //             BankName.includes(toSearch.toLowerCase()) ||
  //             Name.includes(toSearch.toLowerCase())
  //         ) {
  //         } else {
  //             tempTable.push(item);
  //         }
  //     });
  //     setFilterTableData(tempTable);
  // }, [toSearch]);

  const deleteRow = (itemToDelete) => {
    deleteDealerBulkPayment(tableData[itemToDelete].id)
      .then((response) => {})
      .catch((error) => {});
    setTableData(tableData.filter((item, index) => index !== itemToDelete));
  };
  return (
    <>
      <Container fluid className="dealerBulkPayment">
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Row>
                  <Col lg="6" sm="6">
                    <Card.Title as="h3" className="heading">
                      Dealer Bulk Payment
                    </Card.Title>
                    <div
                      className="top-btn-wrapper"
                      style={{ marginLeft: "0", marginTop: "10px" }}
                    >
                      <Button
                        className="btn-fill res-size"
                        type="submit"
                        style={{
                          backgroundColor: "#3AAB7B",
                          border: "none",
                        }}
                        onClick={() =>
                          history.push("/admin/dealerBulkPaymentForm")
                        }
                      >
                        ADD
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6" sm="6">
                    <Card className="card-stats totalTodayProfit">
                      <Card.Body>
                        <Row>
                          <Col xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-money-coins text-warning"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                Total Payments Today:
                              </p>
                              <Card.Title as="h4">NAF {totalToday}</Card.Title>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-1">
                {/* <Col md="4">
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            className="mt-4"
                                            placeholder="Search"
                                            onChange={(e) => setToSearch(e.target.value)}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col> */}
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0"> Dealer </th>
                      <th className="border-0"> Amount </th>
                      <th className="border-0"> Date </th>
                      <th className="border-0"> Email </th>
                      <th className="border-0">Remarks</th>
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      tableData.map((item, index) => {
                        if (filterTableData.includes(item)) {
                          return;
                        }
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Control
                                placeholder="Fax"
                                type="checkbox"
                                checked={item.Checked}
                                onChange={() => {
                                  let temp = [...tableData];
                                  temp[index].Checked = !temp[index].Checked;
                                  setTableData(temp);
                                }}
                                style={{ width: "16px" }}
                              ></Form.Control>
                            </td>
                            <td>
                              {" "}
                              {item.Code +
                                ": " +
                                item.FirstName +
                                " " +
                                item.LastName}{" "}
                            </td>
                            <td> {parseFloat(item.Amount).toFixed(2)} </td>
                            <td> {item.Date.split("T")[0]} </td>
                            <td> {item.email} </td>
                            <td> {item.Remarks} </td>
                            <td>
                              <i
                                className="fa fa-edit"
                                style={{ color: "green", cursor: "pointer" }}
                                onClick={() =>
                                  history.push(
                                    "/admin/dealerBulkPaymentForm/?id=" +
                                      item.id
                                  )
                                }
                              />
                              &nbsp; &nbsp;
                              <i
                                className="fa fa-trash red"
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete?"
                                    )
                                  )
                                    deleteRow(index);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DealerBulkPaymentList;
