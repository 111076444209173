import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Components
import { Card, Table, Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
//          Importing Services
import getAllMembershipByClientId from "../services/membershipHistory";
import { deleteMembership } from "../services/membershipHistory";

function MembershipHistory() {
  const role = JSON.parse(localStorage.getItem("role"));
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [membershipHistory, setMembershipHistory] = React.useState([]);
  const [amountThisYear, setAmountThisYear] = React.useState(0);
  const [remAmountThisYear, setRemAmountThisYear] = React.useState(50);
  const queryParams = new URLSearchParams(window.location.search);
  const params = queryParams.get("id");
  useEffect(() => {
    setIsLoading(true);
    getAllMembershipByClientId(params).then((res) => {
      const sortedData = res.data.data.sort((a, b) => {
        // parse the date strings and turn them into Date objects
        const dateA = new Date(a.month);
        const dateB = new Date(b.month);
        
        // compare the dates and return 1, -1, or 0
        return dateB - dateA;
      });
      
      setMembershipHistory(sortedData);
      var amount = 0;
      var remamount = 0;
      res.data.data.forEach((el)=>{
        const date = new Date(el.month);
        const currentYear = new Date().getFullYear();
        if (currentYear == date.getFullYear()) {
          amount = amount + parseFloat(el.amount);
          setAmountThisYear(amount);
          remamount = 50 - amount;
          setRemAmountThisYear(remamount);
        }
      })
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    });
  }, []);

  const deleteRow = ( itemToDelete ) => {
    deleteMembership(itemToDelete).then((response) => {
      location.reload();
    }).catch((error) => {
      location.reload();
    });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card.Title as="h3" className="heading">Membership History</Card.Title>
                  </Grid>
                  <Grid item xs={6}>
                    <Button className="btn-fill res-size" variant="danger" style={{ border: "none", float: "right" }} 
                    
                    onClick={() => {
                      if (role === 4) {
                        history.push("/admin/currentYearDebt");
                      } else {
                        history.push(`/admin/ClientList?id=${params}`);
                      }
                    }}
                   >
                      Back
                    </Button>
                    <Button className="btn-fill res-size mr-2" variant="primary" style={{ border: "none", float: "right" }} onClick={() => { history.push( "/admin/addMembership/?id=" +params ) }}>
                      New
                    </Button>
                  </Grid>
                </Grid>
              </Card.Header>

              <Card.Header>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Card className="card-stats totalTodayProfit">
                      <Card.Body>
                        <Row>
                          <Col xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-money-coins text-warning"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Membership Payments:</p>
                              <Card.Title as="h4">NAF {amountThisYear}</Card.Title>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="card-stats totalTodayProfit">
                      <Card.Body>
                        <Row>
                          <Col xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-money-coins text-warning"></i>
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Remaining Membership Payments:</p>
                              <Card.Title as="h4">NAF {remAmountThisYear}</Card.Title>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>
              </Card.Header>
              
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover mt-3">
                  <thead>
                    <tr>
                      {/* <th className="border-0">Id</th> */}
                      <th className="border-0">Client Id</th>
                      <th className="border-0">Amount</th>
                      <th className="border-0">Payment Type</th>
                      <th className="border-0">Month</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading === false ?
                      membershipHistory.length > 0 ?
                        membershipHistory.map((item, index) => (
                          <tr key={index}>
                            {/* <td>{item.id}</td> */}
                            <td>{item.clientFk}</td>
                            <td>{parseFloat(item.amount).toFixed(2)}</td>
                            <td>{item.paymentType}</td>
                            <td>{item.month}</td>
                            <td>
                              <i
                                className="fa fa-edit"
                                style={{ color: "green" , cursor:"pointer" }}
                                onClick={() =>
                                  history.push(
                                    "/admin/addMembership/?itemId=" + item.id + "/?id=" + item.clientFk
                                  )
                                }
                              />
                              &nbsp; &nbsp;
                              <i
                                className="fa fa-trash red"
                                style={{ color: "red" , cursor:"pointer" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete?"
                                    )
                                  )
                                    deleteRow(item.id);
                                }}
                              />
                            </td>
                          </tr>
                        )) : <tr><td colSpan="7"><h5 style={{ textAlign: "center" }}>No membership history to show</h5></td></tr>
                      : <tr><td colSpan="7"><h5 style={{ textAlign: "center" }}>Loading...</h5></td></tr>}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default MembershipHistory;
