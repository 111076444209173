import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import "./MerchantCreditBarGraph.css";
import { getAllMerchantCreditHistory } from "services/merchant";

const MerchantCreditBarGraph = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const role = window.localStorage.getItem("role");

  useEffect(() => {
    if (role != 0 || role !== 5) return;
    getAllMerchantCreditHistory()
      .then((res) => {
        setData(
          res.data.map((item) => {
            return {
              merchant: item.Name,
              maxCredit: item.maxCredit,
              creditUsed: item.creditUsed,
              interestOn: item.interestOn,
            };
          })
        );
      })
      .catch((err) => {});
  }, []);

  const handleNextClick = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    (role === 0 || role === 3 || role === 5) && (
      <Card>
        <Card.Header>
          <Card.Title as="h4">Merchant Max Credit vs Used Credit</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table className="table-hover" responsive>
            <thead>
              <tr>
                <th>Merchant</th>
                <th style={{ color: "green", fontWeight: "bold" }}>
                  Max Credit
                </th>
                <th style={{ color: "red", fontWeight: "bold" }}>
                  Used Credit
                </th>
                <th>Interest On</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.merchant}</td>
                  <td style={{ color: "green", fontWeight: "bold" }}>
                    {parseFloat(item.maxCredit).toFixed(2)}
                  </td>
                  <td style={{ color: "red", fontWeight: "bold" }}>
                    {parseFloat(item.creditUsed).toFixed(2)}
                  </td>
                  <td>{item.interestOn}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-end gap-3">
            <Button
              className="btn-fill res-size mr-2"
              style={{
                backgroundColor: "#3AAB7B",
                border: "none",
              }}
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              className="btn-fill res-size"
              style={{
                backgroundColor: "#3AAB7B",
                border: "none",
              }}
              onClick={handleNextClick}
              disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
            >
              Next
            </Button>
          </div>
        </Card.Body>
      </Card>
    )
  );
};

export default MerchantCreditBarGraph;
