import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory, Link } from "react-router-dom";
//          Importing Components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
//          Importing Services
import Multiselect from "multiselect-react-dropdown";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import CustomSelect from "utils/CustomSelect";
import { getUserData, setUserRole } from "../services/register";
import { getKassa } from "services/kassa";
import getAllUsers from "services/user";
import { createKassa } from "services/kassa";
import { addKassa } from "services/kassa";
import { subtractKassa } from "services/kassa";
import { getAllKassaUsers } from "services/kassa";
import { toast, ToastContainer } from "react-toastify";
import PdfGenKassa from "./PdfGenKassa";

function addKassaForm() {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [ClientID, setClientID] = React.useState(null);
  const [formData, setFormData] = React.useState({
    id: "",
    Email: "",
    Amount: "",
    DateTime: "",
    Description: "",
  });
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [users, setusers] = React.useState([{}]);
  const [employeeParam, setEmployeeParam] = React.useState(null);
  const [operator, setOperator] = React.useState(null);
  const [newAmount, setNewAmount] = React.useState(0);
  const [validatePdf, setValidatePdf] = React.useState(false);
  const [typeOfTransaction, setTypeOfTransaction] = React.useState("null");
  const [paymentType, setPaymentType] = React.useState("");
  const getAllEmployees = () => {
    getAllKassaUsers()
      .then((response) => {
        let data = response.data.data;
        const formattedData = data.map((item) => {
          return {
            value: item.id,
            label: item.email,
          };
        });
        setusers(formattedData);
      })
      .catch((err) => {});
  };

  const getEmployeeDetails = (id) => {
    getKassa(id)
      .then((response) => {
        const { data } = response.data;
        const formattedData = data.map((item) => {
          return {
            value: item.id,
            label: item.Email,
          };
        });
        setSelectedUsers(formattedData);
        setFormData(data[0]);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    const id = queryParams.get("id");
    const op = queryParams.get("op");
    if (!id) {
      getAllEmployees();
    } else {
      getEmployeeDetails(id);
      setEmployeeParam(id);
      setOperator(op);
    }
  }, []);
  const handleChange = (users) => {
    setSelectedUsers(users);
  };
  const { id, Amount, Email, DateTime, Description } = formData;
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (employeeParam) {
      handleUpdateSubmit();
    } else {
      handleCreateSubmit();
    }
  };
  const handleUpdateSubmit = () => {
    const data = {
      ...formData,
      newAmount,
      paymentType:
        typeOfTransaction == "Insurance" ? paymentType : typeOfTransaction,
    };

    if (operator == "Add")
      addKassa(data)
        .then((resp) => {
          setLoading(false);
          history.push("/admin/kassafloats");
        })
        .catch((err) => {});
    else
      subtractKassa(data)
        .then((resp) => {
          setLoading(false);
          history.push("/admin/kassafloats");
        })
        .catch((err) => {});
  };
  const handleCreateSubmit = () => {
    const userIds = selectedUsers.map((itm) => itm.value);
    const data = {
      Amount: formData.Amount,
      Description: formData.Description,
      userIds,
    };
    createKassa(data)
      .then((resp) => {
        setLoading(false);
        history.push("/admin/kassafloats");
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (!formData || !selectedUsers) {
      setValidatePdf(false);
      return;
    }
    if (employeeParam) {
      if (parseFloat(newAmount) > 0 && selectedUsers.length > 0) {
        setValidatePdf(true);
        return;
      }
    } else {
      if (parseFloat(formData.Amount) > 0 && selectedUsers.length > 0) {
        setValidatePdf(true);
        return;
      }
    }

    setValidatePdf(false);
  }, [formData, selectedUsers, newAmount]);

  const validateInput = (name, value) => {
    if (name == "Amount") {
      var reg = /^\d*\.?\d*$/;
      if (!reg.test(value) && value.length > 0)
        return "Can Only Contain Numbers From 0-9 and decimal";
    } else if (name == "Description") {
      if (value.length > 90) {
        return "Exceeding Limit of 90 characters";
      }
    } else if (name == "newAmount") {
      var reg = /^\d*\.?\d*$/;
      if (!reg.test(value) && value.length > 0)
        return "Can Only Contain Numbers From 0-9 and decimal";
      if (operator == "Subtract") {
        if (parseFloat(value) > parseFloat(Amount)) {
          return "Cannot withdraw more than current balance";
        }
      }
    }
    return true;
  };

  const handleNewAmountChange = (e) => {
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setNewAmount(e.target.value);
  };
  const handleInputChange = (e) => {
    setErrorMessage("");
    const valid = validateInput(e.target.name, e.target.value);
    if (valid != true) {
      toast.warning(valid, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
    return;
  };

  const getAmountAfter = () => {
    if (operator == "Add")
      return parseFloat(formData.Amount) + parseFloat(newAmount);
    return parseFloat(formData.Amount) - parseFloat(newAmount);
  };

  const getAmountTransaction = () => {
    if (employeeParam) {
      if (operator == "Add") {
        return "Credited " + newAmount;
      }
      return "Debited " + newAmount;
    } else {
      return "Credited " + formData.Amount;
    }
  };
  const getkassaTransactionDetails = () => {
    let data = {};
    data = {
      amountTransaction: getAmountTransaction(),
      date: formData.DateTime,
      description: formData.Description,
    };

    if (employeeParam) {
      data = {
        ...data,
        amountAfter: getAmountAfter(),
        amountBefore: formData.Amount,
      };
    } else {
      data = { ...data, amountBefore: 0, amountAfter: formData.Amount };
    }
    return data;
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleTypeOfTransactionChange = (e) => {
    if (!e.target.value) return;
    setTypeOfTransaction(e.target.value);
    setPaymentType("");
    let transactionType = "Debited";
    if (operator == "Add") {
      transactionType = "Credited";
    }
    setFormData({
      ...formData,
      Description: `${transactionType} ${e.target.value} Amount`,
    });
  };
  const animatedComponents = makeAnimated();
  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm="12" md="8">
          <Card className="form-wrapper mt-4">
            <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
              <Card.Title as="h3" className="text-center m-3 heading">
                Kassa Form
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                {employeeParam && (
                  <Row>
                    <Col sm="12" md="12">
                      <Form.Group>
                        <label className="requiredelement">
                          <i
                            className={
                              operator == "Add"
                                ? "fa fa-plus red "
                                : "fa fa-minus red  "
                            }
                            style={{
                              color: operator == "Add" ? "green" : "red",
                              cursor: "pointer",
                            }}
                          />{" "}
                          Balance
                        </label>
                        <Form.Control
                          required
                          placeholder="Enter Balance"
                          type="tel"
                          value={newAmount}
                          name="newAmount"
                          onChange={(e) => handleNewAmountChange(e)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm="12" md="12">
                    <Form.Group>
                      <label className="requiredelement">
                        {" "}
                        {employeeParam ? "Current" : ""} Balance
                      </label>
                      <Form.Control
                        required
                        placeholder="Enter Balance"
                        type="tel"
                        value={Amount}
                        name="Amount"
                        disabled={employeeParam}
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {employeeParam && (
                  <Row>
                    <Col sm="12" md="12">
                      <Form.Group>
                        <label className="requiredelement">
                          {" "}
                          Type Of Transaction
                        </label>
                        <Form.Control
                          as="select"
                          name="typeOfTransaction"
                          value={typeOfTransaction}
                          required
                          onChange={handleTypeOfTransactionChange}
                        >
                          <option value=""> </option>
                          <option value="Cash"> Cash</option>
                          <option value="MCB"> MCB</option>
                          <option value="RBC"> RBC</option>
                          <option value="Insurance"> Insurance</option>
                          <option value="Banco Di Caribe">
                            {" "}
                            Banco Di Caribe
                          </option>
                          <option value="Maestro"> Maestro</option>
                          <option value="CIBC">CIBC</option>
                          <option value="Cash Bulk">Cash Bulk</option>
                          <option value="Orco Bank">Orco Bank</option>
                          <option value="Other"> Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {typeOfTransaction == "Insurance" && (
                  <Row>
                    <Col sm="12" md="12">
                      <Form.Group>
                        <label className="requiredelement">Payment Type</label>
                        <Form.Control
                          as="select"
                          name="paymentType"
                          value={paymentType}
                          required
                          onChange={(e) => {
                            setPaymentType(e.target.value);
                          }}
                        >
                          <option value=""> </option>
                          <option value="Cash"> Cash</option>
                          <option value="MCB"> MCB</option>
                          <option value="RBC"> RBC</option>
                          <option value="Banco Di Caribe">
                            {" "}
                            Banco Di Caribe
                          </option>
                          <option value="Maestro"> Maestro</option>
                          <option value="CIBC">CIBC</option>
                          <option value="Cash Bulk">Cash Bulk</option>
                          <option value="Incasso">Incasso</option>
                          <option value="other"> Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm="12" md="12">
                    <Form.Group>
                      <label className="requiredelement"> Description</label>
                      <Form.Control
                        required
                        placeholder="Enter Comment ... "
                        type="tel"
                        value={Description}
                        name="Description"
                        onChange={(e) => handleInputChange(e)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Form.Group>
                      <label className="requiredelement">Employees</label>
                      <CustomSelect
                        options={users}
                        isMulti
                        isDisabled={employeeParam ? true : false}
                        components={{ Option, MultiValue, animatedComponents }}
                        closeMenuOnSelect={false}
                        onChange={handleChange}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        value={selectedUsers}
                        placeholder="Select Employees"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="text-center mt-3">
                  {errorMessage ? (
                    <Col xs="12" style={{ fontSize: "13px", color: "red" }}>
                      {errorMessage}
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col md="12">
                    <div className="button-wrapper">
                      {validatePdf ? (
                        <PdfGenKassa
                          // paybackPeriodHistory={(e) => {
                          //     paybackPeriodHistory = e
                          // }}
                          userName={{
                            key: "userName",
                            value: window.localStorage.getItem("email"),
                          }}
                          kassaTransaction={getkassaTransactionDetails()}
                        />
                      ) : (
                        ""
                      )}
                      <Button
                        className="btn-fill res-size"
                        type="submit"
                        style={{ backgroundColor: "#3AAB7B", border: "none" }}
                      >
                        {loading ? (
                          <div>
                            {" "}
                            <i class="fa fa-spinner" aria-hidden="true">
                              &nbsp;
                            </i>{" "}
                            Saving...
                          </div>
                        ) : (
                          <div>
                            {employeeParam
                              ? operator == "Add"
                                ? "Add"
                                : "Withdraw"
                              : "Save"}
                          </div>
                        )}
                      </Button>
                      <Link to="/admin/kassafloats">
                        <Button
                          className="btn-fill res-size"
                          variant="danger"
                          style={{ border: "none" }}
                        >
                          Back
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}
export default addKassaForm;
