import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";

import Multiselect from "multiselect-react-dropdown";
import { getAllMerchants } from "services/user";
import { getRemainingPayBackReport } from "services/reports";
import CustomSelect from "utils/CustomSelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
export default function DailyTransactionReport() {
  const [report, setReport] = React.useState(null);
  const [filtered, setFiltered] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  useEffect(() => {
    if (!report) return;
    setFiltered(true);
  }, [report]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    getRemainingPayBackReport(dateFrom, dateTo)
      .then((res) => {
        setReport(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  var currentDate = new Date();
  var endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  var maxDate = endDate.toISOString().split("T")[0];

  return (
    <div>
      <div>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title as="h4" className="text-center m-3 heading">
                    Remaining PayBack Report
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Date From</label>
                          <Form.Control
                            required
                            placeholder="abcd"
                            type="date"
                            name="Title"
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                              setDateFrom(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Date To</label>
                          <Form.Control
                            required
                            placeholder="abcd"
                            type="date"
                            name="Title"
                            max={maxDate}
                            onChange={(e) => {
                              setDateTo(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="text-center justify-content-center mt-2">
                      <Col md="12">
                        <div className="button-wrapper">
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Filtering..." : "Filter"}
                          </Button>
                          {!isLoading && filtered && (
                            <Button
                              className="btn-fill res-size"
                              style={{
                                backgroundColor: "#3AAB7B",
                                border: "none",
                              }}
                              disabled={isDownloading}
                            >
                              <CSVLink
                                data={report}
                                filename={"RemainingPayBack_report.csv"}
                                onClick={() => {
                                  setIsDownloading(true);
                                  setTimeout(
                                    () => setIsDownloading(false),
                                    1000
                                  );
                                }}
                              >
                                {isDownloading ? "Downloading..." : "Download"}
                              </CSVLink>
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
