import React, { useEffect, useRef } from "react";
//  Importing CSS Files
import "../components/Dashboard.css";
//  Importing Routes
import { useHistory } from "react-router-dom";
//  Importing Components
import {
  Button,
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
//  Importing Services
import getMerchantList from "../services/merchant";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  deleteMerchant,
  loadSuperMarket,
  CloseSuperMarket,
} from "../services/merchant";

function MerchantList({ socket }) {
  const role = JSON.parse(localStorage.getItem("role"));
  const [tableData, setTableData] = React.useState([
    {
      Checked: false,
      id: "",
      Code: "",
      Name: "",
      AccountNo: "",
      BankName: "",
      deviceType: "",
      Status: false,
    },
  ]);
  const history = useHistory();
  const [status, setStatus] = React.useState(false);
  const [toSearch, setToSearch] = React.useState("");
  const [filterTableData, setFilterTableData] = React.useState([]);
  const [nearCreditMerchants, setNearCreditMerchants] = React.useState([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [showCloseSuperMarketModal, setShowCloseSuperMarketModal] =
    React.useState(false);
  const catchNearCreditMerchants = (merchants) => {
    const merchantIds = merchants.map((merchant) => merchant.id);
    setNearCreditMerchants([...merchantIds]);
  };

  useEffect(() => {
    const role = window.localStorage.getItem("role");
    if (role == 0 || role == 5) {
      socket.on("NearCreditLimitMerchants", (resp) => {
        catchNearCreditMerchants(resp.data);
      });
    }
    setFilterTableData([]);
    getMerchantList()
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {});
    return () => {
      socket.off("NearCreditLimitMerchants");
    };
  }, []);

  const handleButtonClick = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    handleLoadSuperMarket();
  };

  const handleCloseModal = () => {
    setShowConfirmationModal(false);
  };

  const handleCloseSuperMarket = () => {
    setShowCloseSuperMarketModal(true);
  };

  const handleConfirmSuperMarket = () => {
    setShowCloseSuperMarketModal(false);
    handleCloseSuperMarketFunction();
  };

  const handleCloseSuperMarketModal = () => {
    setShowCloseSuperMarketModal(false);
  };
  function sortHandler(a, b) {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }
  const sortedArrayData = tableData;
  sortedArrayData.sort(sortHandler);
  useEffect(() => {
    let tempTable = [];
    tableData.map((item) => {
      const Code = item?.Code?.toLowerCase();
      const AccountNo = item?.AccountNo?.toLowerCase();
      const BankName = item?.BankName?.toLowerCase();
      const Name = item?.Name?.toLowerCase();
      const DeviceType = item?.deviceType?.toLocaleLowerCase();
      if (DeviceType != undefined) {
        if (
          Code.includes(toSearch.toLowerCase()) ||
          AccountNo.includes(toSearch.toLowerCase()) ||
          BankName.includes(toSearch.toLowerCase()) ||
          Name.includes(toSearch.toLowerCase()) ||
          DeviceType.includes(toSearch.toLowerCase())
        ) {
        } else {
          tempTable.push(item);
        }
      } else {
        if (
          Code.includes(toSearch.toLowerCase()) ||
          AccountNo.includes(toSearch.toLowerCase()) ||
          BankName.includes(toSearch.toLowerCase()) ||
          Name.includes(toSearch.toLowerCase())
        ) {
        } else {
          tempTable.push(item);
        }
      }
    });
    setFilterTableData(tempTable);
  }, [toSearch]);

  const toggleStatus = (index) => {
    let tempTable = [...tableData];
    tempTable[index].Status = !tempTable[index].Status;
    setTableData(tempTable);
  };
  const deleteRow = (itemToDelete) => {
    deleteMerchant(tableData[itemToDelete].id)
      .then((response) => {})
      .catch((error) => {});
    setTableData(tableData.filter((item, index) => index !== itemToDelete));
  };
  const creditLimitSign = (merchantId) => {
    const role = window.localStorage.getItem("role");
    if (role == 0 || role == 5) {
      if (nearCreditMerchants.includes(merchantId)) {
        return (
          <i
            class="fa fa-exclamation-triangle fa-yellow"
            style={{ color: "red" }}
            aria-hidden="true"
          ></i>
        );
      } else {
        return (
          <i
            class="fa fa-check-circle green-color"
            style={{ color: "green" }}
          ></i>
        );
      }
    }
  };

  const handleLoadSuperMarket = () => {
    loadSuperMarket()
      .then((response) => {
        if (response.status === 200) {
          toast.success("SuperMarket Loaded Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Failed to Load SuperMarket!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error("An error occurred while adding.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleCloseSuperMarketFunction = () => {
    CloseSuperMarket()
      .then((response) => {
        if (response.status === 200) {
          toast.success("SuperMarket Closed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Failed to Close SuperMarket!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error("An error occurred while adding.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h3" className="heading">
                  Merchants
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-1">
                <div className="top-btn-wrapper">
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={() => history.push("/admin/MerchantForm")}
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    <i class="fa fa-user" aria-hidden="true"></i>
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    type="submit"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={() => history.push("/admin/MerchantGroupList")}
                  >
                    <i class="fa fa-eye" aria-hidden="true"></i> &nbsp;
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </Button>

                  <Button
                    className="btn-fill res-size"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={handleButtonClick}
                  >
                    <span>
                      Load SuperMarket
                      <i
                        class="fa fa-shopping-cart pl-1"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Button>
                  <Button
                    className="btn-fill res-size"
                    style={{
                      backgroundColor: "#3AAB7B",
                      border: "none",
                    }}
                    onClick={handleCloseSuperMarket}
                  >
                    <span>
                      Close Supermarkets
                      <i
                        class="fa fa-shopping-cart pl-1"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Button>
                  <Modal show={showConfirmationModal} onHide={handleCloseModal}>
                    <Modal.Body>
                      <div className="d-flex align-items-center justify-content-center ">
                        {" "}
                        Are you sure you want to Load?
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        className="btn-fill res-size"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          background: "red",
                          color: "#FFFFFF",
                          border: "none",
                        }}
                        onClick={handleConfirm}
                      >
                        YES
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={showCloseSuperMarketModal}
                    onHide={handleCloseSuperMarketModal}
                  >
                    <Modal.Body>
                      <div className="d-flex align-items-center justify-content-center ">
                        {" "}
                        Are you sure you want to Load?
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        className="btn-fill res-size"
                        onClick={handleCloseSuperMarketModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          background: "red",
                          color: "#FFFFFF",
                          border: "none",
                        }}
                        onClick={handleConfirmSuperMarket}
                      >
                        YES
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <Col md="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="mt-4"
                      placeholder="Search"
                      onChange={(e) => setToSearch(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Table className="table-hover" responsive>
                  <thead>
                    <tr>
                      <th className="border-0"> st </th>
                      <th className="border-0">Code Negoshi</th>
                      <th className="border-0">Nomber Negoshi</th>
                      <th className="border-0">NUMBER DI KUENTA</th>
                      <th className="border-0">NOMBER DI BANKO</th>
                      <th className="border-0">Device Type</th>
                      {role !== 3 && <th className="border-0">Max Credit</th>}

                      {role !== 3 && <th className="border-0">Credit Used</th>}
                      <th className="border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => {
                      if (filterTableData.includes(item)) {
                        return;
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              placeholder="Fax"
                              type="checkbox"
                              checked={item.Checked}
                              onChange={() => {
                                let temp = [...tableData];
                                temp[index].Checked = !temp[index].Checked;
                                setTableData(temp);
                              }}
                              style={{ width: "16px" }}
                            ></Form.Control>
                          </td>
                          <td> {item.Code} </td>
                          <td>
                            {" "}
                            {creditLimitSign(item.id)} {item.Name}{" "}
                          </td>
                          <td> {item.AccountNo} </td>
                          <td> {item.BankName} </td>
                          <td> {item.deviceType} </td>
                          {role !== 3 && <td>{item.maxCredit}</td>}
                          {role !== 3 && <td>{item.creditUsed}</td>}
                          <td>
                            <i
                              className="fa fa-edit"
                              style={{ color: "green", cursor: "pointer" }}
                              onClick={() =>
                                history.push(
                                  "/admin/MerchantForm/?id=" + item.id
                                )
                              }
                            />
                            &nbsp; &nbsp;
                            <i
                              className="fa fa-trash red"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete?"
                                  )
                                )
                                  deleteRow(index);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default MerchantList;
