import React, { useEffect } from "react";
//          Importing CSS Files
import "../components/Dashboard.css";
//          Importing Routes
import { useHistory } from "react-router-dom";
//          Importing Components
import { Button, Form, Card, Table, Container, Row, Col } from "react-bootstrap";
import { Tooltip } from "../components/Tooltip";
//          Importing Services
import checkUser from "../services/auth";
import getClientList from "../services/client";
import { deleteClient, updateClient, deleteClientImage, deleteClientBankStatement, deleteClientSalarySlips } from "../services/client";
import { deleteNfc } from "services/nfc";
import { updateNfc } from "services/nfc";
import { getGiftNfcData } from "services/nfc.js";

function GiftCardList() {
    const [tableData, setTableData] = React.useState([
        {
            status: 0, number: "", id: ""
        },
    ]);
    const queryParams = new URLSearchParams(window.location.search);
    const params = queryParams.get("id") || null;
    const history = useHistory();
    const [toSearch, setToSearch] = React.useState("");
    const [filterTableData, setFilterTableData] = React.useState([]);
   
    useEffect(() => {

        setFilterTableData([]);
        getGiftNfcData().then((response) => {
            setTableData(response.data);
            if (params) {
                setToSearch(params)
            }
        }).catch((error) => {
        });
    }, []);


    // Search Gift Card
    useEffect(() => {
        let tempTable = [];
        tableData.map((item) => {
            const number = item?.number?.toLowerCase();

            if (
                number.includes(toSearch.toLowerCase())) {
            } else {
                tempTable.push(item);
            }
        });
        setFilterTableData(tempTable);
    }, [toSearch]);
    // Change Status
    const toggleStatus = (index) => {
        let tempTable = [...tableData];
        tempTable[index].status = !tempTable[index].status;
        updateNfc({ id: tempTable[index].id, number: tempTable[index].number, status: JSON.parse(tempTable[index].status) }).then((response) => {
            if (response) {
            } else {
                setErrorMessage(`${number} couldnt update the status`);
            }
        }).catch((error) => {
        });
        setTableData(tempTable);
    };
    // Sorting Table

    const deleteRow = (itemToDelete) => {
        deleteNfc(tableData[itemToDelete].id).then((response) => {
        }).catch((error) => {
        });
        setTableData(tableData.filter((item, index) => index !== itemToDelete));
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="card-plain table-plain-bg">
                            <Card.Header>
                                <Card.Title as="h3" className="heading">Prepaid Cards </Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <div className="top-btn-wrapper">
                                    <Button
                                        className="btn-fill res-size" type="submit" style={{ backgroundColor: "#3AAB7B", border: "none" }}
                                        onClick={() => history.push("/admin/issuePrepaidCard")}
                                    >
                                        ISSUE
                                    </Button>
                                </div>
                                <Col md="4">
                                    <Form.Group>
                                        <Form.Control type="text" className="mt-4" placeholder="Search" value={toSearch} onChange={(e) => setToSearch(e.target.value)}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Table className="table-hover" responsive>
                                    <thead>
                                        <tr>
                                            <th className="border-0"> St </th>

                                            <th className="border-0"> NUMBER DI KARCHI </th>
                                            <th className="border-0">Status</th>
                                            <th className="border-0" >Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item, index) => {
                                            if (filterTableData.includes(item)) {
                                                return;
                                            }
                                            return (
                                                <tr
                                                    key={index}
                                                >
                                                    <td>
                                                        {" "}
                                                        <Form.Control
                                                            placeholder="Fax"
                                                            type="checkbox"
                                                            checked={item.Checked}
                                                            onChange={() => {
                                                                let temp = [...tableData];
                                                                temp[index].Checked = !temp[index].Checked;
                                                                setTableData(temp);
                                                            }}
                                                            style={{ width: "16px" }}
                                                        ></Form.Control>
                                                    </td>
                                                    <td>{item.number}</td>
                                                    <td>
                                                        {item.status == 1 ? (
                                                            <Button onClick={() => toggleStatus(index)} style={{ border: "none" }}>
                                                                <i className="fa fa-toggle-on" style={{ color: "green", textAlign: "center", cursor: "pointer" }} />
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={() => toggleStatus(index)} style={{ border: "none" }}>
                                                                <i className="fa fa-ban" style={{ color: "red", textAlign: "center", cursor: "pointer" }} />
                                                            </Button>
                                                        )}
                                                    </td>
                                                    <td className="actions-styling">
                                                        <i
                                                            className="fa fa-trash action-childs" style={{ color: "red", cursor: "pointer" }}
                                                            onClick={() => {
                                                                if (window.confirm("Are you sure you want to delete?"))
                                                                    deleteRow(index);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default GiftCardList;